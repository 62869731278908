import React, { useState, useEffect, useCallback } from "react";
import Wrapper from "../wrapper";
import HomeHero from "./homeHero";
import Stats from "./stats";
import SeriesSlider from "../../ui/seriesSlider";
import VideoList from "./videoList";
import TrendingSpeakers from "./trendingSpeakers";
import HdeBanner from "./hdeBanner";
import { parseCategoryID, sortByReleased } from "../../../utils";
import tracking from "../../../utils/tracking";
import {
  getHomePage,
  getTags,
  searchVideos,
  getLeaderboard,
  getProfile,
  getRecommendedQuizzes,
  getRecommendedVideos,
  getQuizzes,
} from "../../../utils/data";
import { useGlobalContext } from "../../../store/global";
import get from "lodash.get";
import { isIE } from "react-device-detect";
import { useTranslation } from "react-i18next";
import JSMpeg from "@cycjimmy/jsmpeg-player";
import HyattPhotography from "./hyattPhotography";
import HyattClassics from "./hyattClassics";
import HomeQuizzesSwiper from "./HomeQuizzesSwiper";
import HomeCategoriesBlock from "./HomeCategoriesBlock";

const Home = (props) => {
  const { t } = useTranslation();
  const lang = localStorage.getItem("lang") || "english";

  const { setLoading } = useGlobalContext();

  const [pageData, setPageData] = useState({
    heroData: {},
    hdeData: {},
    stats: {},
    trendingVideos: [],
    leaderboard: {},
    categories: [],
    selectedTag: null,
    recentFB: [],
    recentOps: [],
    recentSM: [],
    speakers: [],
    displayedVideos: [],
    discoverDisplay: 0,
    isFetchingVideos: false,
    canDisplayMore: true,
    tags: [],
    user: {},
    quizzes: [],
  });

  useEffect(() => {
    if (!isIE) {
      getPageData();
    }
    if (isIE) {
      getPageDataIE();
    }
  }, []);

  const scrollToTop = useCallback((player) => {
    var domHight =
      window.screen.height ||
      window.innerHeight ||
      document.documentElement.clientHeight;
    var scrollTop =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop;
    var sv;
    var scrollHeight;
    var offsetTop;
    var top;
    var bottom;

    sv = document.getElementById(`sustainability-video`);
    if (sv) {
      scrollHeight = sv.scrollHeight;
      offsetTop = sv.offsetTop;
      top = offsetTop - domHight > 0 ? offsetTop - domHight : 0;
      bottom = scrollHeight + offsetTop;
      if (scrollTop >= top && scrollTop <= bottom) {
        playSustainabilityVideo(player);
      }
    }
  }, []);

  useEffect(() => {
    const player = initSustainabilityVideo();
    window.addEventListener("scroll", () => scrollToTop(player));
  }, [scrollToTop]);

  const initSustainabilityVideo = () => {
    let player = new JSMpeg.VideoElement(
      "#sustainability-video",
      "assets/videos/sustainability-worldofcare.ts",
      {
        loop: true,
        autoplay: false,
        control: false,
        canvas: document.querySelector("#sustainability-canvas"),
        picMode: true,
      },
      {
        audio: false,
      }
    );
    return player;
  };

  const playSustainabilityVideo = (player) => {
    player.play();
  };

  const getPageDataIE = async () => {
    const home = await getHomePage();
    const t = await getTags();
    const fb = await searchVideos({ category: "60596acbfbd25e709cd3cde1" }); // fb
    const ops = await searchVideos({ category: "60596acbfbd25e709cd3cddf" }); // ops
    const sm = await searchVideos({ category: "60596acbfbd25e709cd3cde0" }); // sm
    const lb = await getLeaderboard();
    const p = await getProfile();

    const rv = await getRecommendedVideos();
    const rq = await getRecommendedQuizzes();

    rv.data.forEach((video) => {
      video.categoryShorthand = parseCategoryID(
        video.category
      ).categoryShorthand;
      video.categoryName = parseCategoryID(video.category).categoryName;
    });

    rq.data.forEach((quiz) => {
      quiz.categoryShorthand = parseCategoryID(quiz.category).categoryShorthand;
      quiz.categoryName = parseCategoryID(quiz.category).categoryName;
    });

    const userCountryId = get(user, "country.id", "");
    const leaderBoardRegion = get(leaderboard, "fastestViewers", []).filter(
      (set) => set.country.id === userCountryId
    )[0];

    const homeData = home.data[0];
    const tags = t.data;
    const recentFB = fb.data;
    const recentOps = ops.data;
    const recentSM = sm.data;
    const leaderboard = lb.data;
    const user = p.data;

    setPageData((prevState) => ({
      ...prevState,
      heroData: {
        header: homeData.header[lang],
        text: homeData.description[lang],
        title: homeData.title[lang],
        video: homeData.wistiaHashId,
        recommendedVideos: rv.data,
        recommendedQuizzes: rq.data,
      },
      stats: {
        countries: homeData.statisticCountries,
        hotels: homeData.statisticHotels,
        users: homeData.statisticUsers,
        videos: homeData.statisticVideos,
        episodes: homeData.statisticEpisodes,
        quizzes: homeData.statisticQuizzesPast,
        text: homeData.description[lang],
      },
      hdeData: {
        title: homeData.hdePromoTitle[lang],
        desc: homeData.hdePromoDescription[lang],
        image: homeData.hdePromoImage,
        video: homeData.hdePromoWistia,
      },
      leaderboard,
      leaderBoardRegion,
      tags,
      trendingSeries: homeData.trendingSeries,
      trendingSpeakers: homeData.trendingSpeakers,
      recentFB,
      recentOps,
      recentSM,
      displayedVideos: organizeColumns(recentFB, recentOps, recentSM),
      user,
    }));
  };

  const getPageData = async () => {
    const filters = {
      range: `[0, 0]`,
      filter: { releaseDate: { $lte: new Date() } },
      sort: '["releaseDate","DESC"]',
    };

    setLoading(true);
    const [home, t, fb, ops, sm, lb, p, rv, rq, quizzesData] =
      await Promise.all([
        getHomePage(),
        getTags(),
        searchVideos({ ...filters, category: process.env.REACT_APP_FB }), // fb
        searchVideos({
          ...filters,
          category: process.env.REACT_APP_OPERATIONS,
        }), // ops
        searchVideos({ ...filters, category: process.env.REACT_APP_SM }), // sm
        getLeaderboard(),
        getProfile(),
        getRecommendedVideos(),
        getRecommendedQuizzes(),
        getQuizzes(),
      ]);

    rv.data.forEach((video, index) => {
      video.categoryShorthand = parseCategoryID(
        video.category
      ).categoryShorthand;
      video.categoryName = parseCategoryID(video.category).categoryName;
      video.index = index + 1;
    });

    rq.data.forEach((quiz, index) => {
      quiz.categoryShorthand = parseCategoryID(quiz.category).categoryShorthand;
      quiz.categoryName = parseCategoryID(quiz.category).categoryName;
      quiz.index = rv.data.length + index + 1;
    });

    const quizzes =
      quizzesData?.data?.filter((quiz) => {
        if (["toTake", "inProgress", "failed"].includes(quiz.status)) {
          return true;
        }
        return false;
      }) || [];

    setLoading(false);
    const homeData = home.data[0];
    const tags = t.data;
    const recentFB = fb.data.sort(sortByReleased);
    const recentOps = ops.data.sort(sortByReleased);
    const recentSM = sm.data.sort(sortByReleased);
    const leaderboard = lb.data;
    const user = p.data;
    const userCountryId = get(user, "country.id", "");
    const leaderBoardRegion = get(leaderboard, "fastestViewers", []).filter(
      (set) => get(set, "country.id", null) === userCountryId
    )[0];

    setPageData((prevState) => ({
      ...prevState,
      heroData: {
        header: homeData.header[lang],
        text: homeData.description[lang],
        title: homeData.title[lang],
        video: homeData.wistiaHashId,
        recommendedVideos: rv.data,
        recommendedQuizzes: rq.data,
      },
      stats: {
        countries: homeData.statisticCountries,
        hotels: homeData.statisticHotels,
        users: homeData.statisticUsers,
        series: homeData.statisticSeries,
        episodes: homeData.statisticEpisodes,
        quizzes: homeData.statisticQuizzesPast,
        text: homeData.description[lang],
      },
      hdeData: {
        title: homeData.hdePromoTitle[lang],
        desc: homeData.hdePromoDescription[lang],
        image: homeData.hdePromoImage,
        video: homeData.hdePromoWistia,
      },
      leaderboard,
      leaderBoardRegion,
      tags,
      trendingSeries: homeData.trendingSeries,
      trendingSpeakers: homeData.trendingSpeakers,
      recentFB,
      recentOps,
      recentSM,
      displayedVideos: organizeColumns(recentFB, recentOps, recentSM),
      user,
      quizzes,
    }));
  };

  const organizeColumns = (fb, ops, sm) => {
    const organizedVideos = [];
    let i = 0;
    while (true) {
      if (fb[i]) {
        organizedVideos.push(fb[i]);
      }
      if (ops[i]) {
        organizedVideos.push(ops[i]);
      }
      if (sm[i]) {
        organizedVideos.push(sm[i]);
      }
      if (!fb[i] && !ops[i] && !sm[i]) {
        return organizedVideos;
      }
      i++;
    }
  };

  const pageClicked = (page, value) => {
    tracking.event("home", page, value);
  };

  return (
    <Wrapper>
      <div id="content">
        <HomeHero heroData={pageData.heroData} />
        <Stats key={Math.random()} stats={pageData.stats} />
        {Boolean(pageData?.quizzes?.length) && (
          <section className="series section-block">
            <div className="viewport">
              <div className="inner">
                <HomeQuizzesSwiper quizzes={pageData?.quizzes} />
              </div>
            </div>
          </section>
        )}

        <section className="discover section-block">
          <div className="viewport">
            <div className="inner">
              <img
                className="welovemondays"
                src="/assets/images/welovemondays.gif"
                alt="we love monday"
              />
              {pageData.displayedVideos.length === 0 && (
                <div className="empty">
                  <h2 className="title">{t("No_Result_For_Your_Search")}</h2>
                </div>
              )}
              {pageData.displayedVideos.length > 0 && (
                <VideoList
                  videos={pageData.displayedVideos}
                  limit={pageData.displayedVideos.length}
                  onVideoClicked={(id) => pageClicked("home", "/video", id)}
                />
              )}
            </div>
          </div>
        </section>

        {/* Category Blocks */}
        <section className="discover section-block">
          <div className="viewport">
            <div className="inner">
              <HomeCategoriesBlock
                redirectTo={(path) => props?.history?.push(path)}
              />
            </div>
          </div>
        </section>

        <section className="series section-block">
          <div className="viewport">
            <div className="inner">
              <div className="title-wrapper">
                <h2 className="center-title">
                  {t("Dig_Use_&_Share")}
                  <h2 style={{ whiteSpace: "nowrap", all: "inherit" }}>
                    {t("Anywhere_Anytime")}
                  </h2>
                </h2>
              </div>
              <SeriesSlider.Home
                onSeriesClicked={(id) => pageClicked("/series", id)}
              />
            </div>
          </div>
        </section>

        <HyattClassics />

        <HyattPhotography />

        <section className="focus section-block">
          <div className="viewport">
            <div className="inner">
              <h2 className="center-title">{t("Focus")}</h2>
              <div className="sustainability-container">
                <div
                  id="sustainability-video"
                  onClick={() => props.history.push(`/sustainability`)}
                >
                  <canvas id="sustainability-canvas"></canvas>
                </div>
              </div>
            </div>
          </div>
        </section>
        <TrendingSpeakers
          onSpeakerClicked={(id) => pageClicked("/speaker", id)}
          speakers={pageData.trendingSpeakers}
        />
        <HdeBanner
          title={pageData.hdeData.title}
          video={pageData.hdeData.video}
          image={pageData.hdeData.image}
          desc={pageData.hdeData.desc}
        />
      </div>
    </Wrapper>
  );
};

export default Home;
