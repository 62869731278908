import React, { useState, useEffect } from "react";
import { withRouter } from "react-router";
import {
  getProfile,
  getLikedVideo,
  getUserStatistic,
  getSubmittedQuizzes,
  getQuizzes,
  downloadAllCertificates,
} from "../../../utils/data";
import {
  sortByLastTakeAt
} from "../../../utils";
import get from "lodash.get";
import { useGlobalContext } from "../../../store/global";
import { useTranslation } from "react-i18next";

// Progress bar
import "react-circular-progressbar/dist/styles.css";

// Bar chart
import "react-month-picker/css/month-picker.css";
import QuizItem from "../../ui/QuizItem";
import Badge from "../../ui/Badge";
import { useLocation } from "react-router-dom";

const Profile = (props) => {
  const { t } = useTranslation();
  const location = useLocation();

  const { setLoading, loading } = useGlobalContext();
  const [pageData, setPageData] = useState({
    profileData: {},
    leaderBoardStats: {},
    likedVideos: [],
    scores: {},
    statistics: {},
  });
  const [quizzes, setQuizzes] = useState([]);
  const [quizData, setQuizData] = useState([]);
  const [fnbProgress, setFnbProgress] = useState({});
  const [opeProgress, setOpeProgress] = useState({});
  const [snmProgress, setSnmProgress] = useState({});
  const [monthValue] = useState({
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
  });

  useEffect(() => {
    if (!loading) {
      const urlParams = new URLSearchParams(location?.search);
      if (urlParams.get("showQuizzes") === "true") {
        const anchor = document.getElementById("quiz");
        if (anchor) {
          anchor.scrollIntoView({ behavior: "smooth", block: "center", });
          
        }
      }
    }
  }, [location, loading]);

  useEffect(() => {
    const loadQuizzes = async () => {
      const res = await getSubmittedQuizzes();
      setQuizzes(res?.data || []);
      const quizzesData = await getQuizzes();
      let quizDataSort = quizzesData?.data || [];
      quizDataSort = quizDataSort?.filter(item => item?.status !== "toTake" && item?.status !== "notStarted");
      quizDataSort = quizDataSort.sort(sortByLastTakeAt);
      if(quizDataSort?.length > 0){
        const inProgressQuizzes = quizDataSort?.filter(quiz => quiz?.status === "inProgress");
        const restQuizzes = quizDataSort?.filter(quiz => quiz?.status !== "inProgress");
        quizDataSort = [...(inProgressQuizzes || []), ...(restQuizzes || [])];
      }
      setQuizData(quizDataSort);
    };

    loadQuizzes();
  }, []);

  useEffect(() => {
    setLoading(true);
    const getPageData = async () => {
      const profile = await getProfile();
      const liked = await getLikedVideo();

      getStatistics(profile.data.id, monthValue);

      setPageData((prevState) => ({
        ...prevState,
        profileData: profile.data,
        likedVideos: liked.data,
        scores: getScore(profile.data.quizScores),
      }));

      let submittedQuizzes = await getSubmittedQuizzes({ passed: true });
      submittedQuizzes = submittedQuizzes?.data;
      let [fnbQuizzes, opeQuizzes, snmQuizzes] = await Promise.all([
        getQuizzes({
          category: process.env.REACT_APP_FB,
        }),
        getQuizzes({
          category: process.env.REACT_APP_OPERATIONS,
        }),
        getQuizzes({
          category: process.env.REACT_APP_SM,
        }),
      ]);
      submittedQuizzes.filter(
        (v, i, a) => a.findIndex((v2) => v2.quiz === v.quiz) === i
      );

      var filterQuizzesResults = submittedQuizzes.reduce((unique, o) => {
        if (
          !unique.some(
            (obj) => obj.quiz === o.quiz || obj.quiz?.id === o.quiz?.id
          )
        ) {
          unique.push(o);
        }
        return unique;
      }, []);

      let fnbNum = 0;
      let opeNum = 0;
      let snmNum = 0;
      for (const i in filterQuizzesResults) {
        switch (filterQuizzesResults[i].category?.id) {
          case process.env.REACT_APP_FB:
            fnbNum++;
            break;
          case process.env.REACT_APP_OPERATIONS:
            opeNum++;
            break;
          case process.env.REACT_APP_SM:
            snmNum++;
            break;
          default:
            break;
        }
      }

      let fnbProgressTemp = {
        percentage: ((fnbNum / fnbQuizzes.data.length) * 100).toFixed(0),
        total: fnbQuizzes.data.length,
        done: fnbNum,
      };
      let opeProgressTemp = {
        percentage: ((opeNum / opeQuizzes.data.length) * 100).toFixed(0),
        total: opeQuizzes.data.length,
        done: opeNum,
      };
      let snmProgressTemp = {
        percentage: ((snmNum / snmQuizzes.data.length) * 100).toFixed(0),
        total: snmQuizzes.data.length,
        done: snmNum,
      };

      setFnbProgress(fnbProgressTemp);
      setOpeProgress(opeProgressTemp);
      setSnmProgress(snmProgressTemp);

      setTimeout(() => {
        setLoading(false);
      }, 500);
    };

    getPageData();
  }, []);

  const getScore = (scores = []) => {
    let op = scores.findIndex(
      (e) => e.category.id === process.env.REACT_APP_OPERATIONS
    );
    let fb = scores.findIndex(
      (e) => e.category.id === process.env.REACT_APP_FB
    );
    let sm = scores.findIndex(
      (e) => e.category.id === process.env.REACT_APP_SM
    );

    return {
      op: get(scores, `${op}.score`, 0),
      fb: get(scores, `${fb}.score`, 0),
      sm: get(scores, `${sm}.score`, 0),
    };
  };

  const getStatistics = async (id, monthValue) => {
    let statistics = await getUserStatistic(id, monthValue);
    let statisticsLastMonth = await getUserStatistic(id, {
      year: monthValue.month > 1 ? monthValue.year : monthValue.year - 1,
      month: monthValue.month > 1 ? monthValue.month - 1 : 12,
    });

    setPageData((prevState) => ({
      ...prevState,
      statistics: statistics.data,
      statisticsLastMonth: statisticsLastMonth.data,
    }));

    let tempWeeksData = [];
    for (let i = 0; i < (statistics.data.week5 ? 5 : 4); i++) {
      statistics.data[`week${parseInt(i, 10) + 1}`].name = t(
        `Week_${parseInt(i, 10) + 1}`
      );
      tempWeeksData.push(statistics.data[`week${parseInt(i, 10) + 1}`]);
    }
  };

  const handleDownloadAllCertificates = async () => {
    await downloadAllCertificates();
  }

  return (
    <div>
      <div id="content">
        <section className="profile-info">
          <div className="viewport">
            <div className="inner">
              <div className="info-wrapper">
                <div className="profile-pic">
                  <div className="medias">
                    {get(pageData, "profileData.profilePicture.uri", "") !==
                      "" && (
                      <span
                        style={{
                          backgroundImage: `url(${
                            process.env.REACT_APP_IMG_URL
                          }/storage/user-picture/${get(
                            pageData,
                            "profileData.profilePicture.uri",
                            ""
                          )}`,
                        }}
                      ></span>
                    )}
                    {get(pageData, "profileData.profilePicture.uri", "") ===
                      "" && (
                      <span
                        style={{
                          backgroundImage: `url('/assers/images/profile.png')`,
                        }}
                      ></span>
                    )}
                  </div>
                </div>

                <div className="profile-info">
                  <div className="name">{`${get(
                    pageData,
                    "profileData.name",
                    ""
                  )} ${get(pageData, "profileData.surname", "")}`}</div>
                  {get(pageData, "profileData.city.name", "") !== "" &&
                    get(pageData, "profileData.country.name", "") !== "" &&
                    get(pageData, "profileData.brand.name", "") && (
                      <div className="branch">{`${get(
                        pageData,
                        "profileData.city.name",
                        ""
                      )}, ${get(
                        pageData,
                        "profileData.country.name",
                        ""
                      )} - ${get(
                        pageData,
                        "profileData.brand.name",
                        ""
                      )}`}</div>
                    )}

                  <div className="edit">
                    <button
                      className="tertiary small"
                      onClick={() => props.history.push("/profile/edit")}
                    >
                      <span>{t("EDIT_PROFILE")}</span>
                    </button>
                  </div>
                </div>
              </div>

              <div className="profile-quizzes">
                <div className="quizzes">
                  <div className="quizz-logo-wrapper">
                    <img
                      className="quizz-logo"
                      src="/assets/images/quiz_time_logo.svg"
                      alt=""
                    />
                  </div>

                  <QuizProgressItem
                    uri="/assets/images/logo-fnb.png"
                    className="fb"
                    label="FNB"
                    finished={fnbProgress.done}
                    total={fnbProgress.total}
                    points={get(pageData, "scores.fb", 0)}
                  />

                  <QuizProgressItem
                    uri="/assets/images/logo-ope.png"
                    className="ops"
                    label="OPSHR"
                    finished={opeProgress.done}
                    total={opeProgress.total}
                    points={get(pageData, "scores.op", 0)}
                  />

                  <QuizProgressItem
                    uri="/assets/images/logo-snm.png"
                    className="sm"
                    label="SNM"
                    finished={snmProgress.done}
                    total={snmProgress.total}
                    points={get(pageData, "scores.sm", 0)}
                  />
                </div>
              </div>

              <div id="quiz">
                {Boolean(quizData?.length) && (
                  <div className="profile-quizz-list">
                    <div className="profile-quizz-list__head">
                      <div className="profile-quizz-list__head__title">
                        {t("All_Quizzes")}
                      </div>
                      <div className="profile-quizz-list__head__action">
                        <div onClick={() => handleDownloadAllCertificates()} className="action-button">
                          <div className="action-button__icon">
                            <Badge />
                          </div>
                          <div className="action-button__text">
                            {t("download_all_certificates")}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="profile-quizz-list__content">
                      {quizData?.filter(quiz => quiz?.status !== "notStarted")?.map((e, i) => (
                        <QuizItem quiz={e} status={e?.status} />
                      ))}
                    </div>
                  </div>
                )}
              </div>

              <div className="profile-activity">
                <h2 className="title">{t("MY_ACTIVITY")}</h2>

                <div className="activity-wrapper">
                  {[
                    {
                      value: pageData.statistics.monthlyViews,
                      average: 482,
                      label: t("Videos_Watched"),
                      avgDisplay: (e) =>
                        `${t("Average_of_users_have_watched...")} ${e} ${t(
                          "...videos"
                        )}`,
                    },
                    {
                      value: pageData.statistics.monthlyUniqueQuizTaken,
                      average: 77,
                      label: t("QUIZZES_TAKEN"),
                      avgDisplay: (e) =>
                        `${t("Average_of_users_have_taken...")} ${e} ${t(
                          "...number_of_quizzes"
                        )}`,
                    },
                    {
                      value: pageData.statistics.monthlyUniqueQuizPassed,
                      average: 36,
                      label: t("QUIZZES_PASSED"),
                      avgDisplay: (e) =>
                        `${t("Average_of_users_have_passed...")} ${e} ${t(
                          "...quizzes"
                        )}`,
                    },
                  ].map((e, idx) => (
                    <div key={idx} className="activity-item">
                      <span className="value">{e.value}</span>
                      <span className="label">{e.label}</span>
                      <span className="average">{e.avgDisplay(e.average)}</span>
                    </div>
                  ))}
                </div>
              </div>

              <div className="profile-leaderboard-badges">
                <h2 className="title">{t("Leaderboard_Badges")}</h2>

                <div className="stat-wrapper">
                  {[
                    {
                      icon: "icon-eye",
                      className: "expert",
                      value: "profileData.expertViewerAward",
                      label: t("Expert_Viewer"),
                    },
                    {
                      icon: "icon-thunder",
                      className: "fastest",
                      value: "profileData.fastestViewerAward",
                      label: t("Fastest_Viewer"),
                    },
                    {
                      icon: "icon-message",
                      className: "star",
                      value: "profileData.starContributorAward",
                      label: t("Star_Contributor"),
                    },
                  ].map((e, idx) => (
                    <div key={idx} className="stat-item">
                      <div className={"number-wrapper " + e.className}>
                        <span className={"icon " + e.icon} />
                        <span>X{get(pageData, e.value, 0)}</span>
                      </div>
                      <span className="label">{e.label}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default withRouter(Profile);

const QuizProgressItem = ({
  uri,
  className,
  label,
  finished,
  total,
  points,
}) => {
  const { t } = useTranslation();
  const percent = Math.round((finished * 100) / total);

  return (
    <div className={"quizz-line " + className}>
      <div className="category-wrapper">
        <img className="category-icon" src={uri} alt="" />
        <span className="category-label">{t(label)}</span>
      </div>

      <div className="progress-wrapper">
        <span className="progress-title">{t("PROGRESS")}</span>

        <div className="progress-info">
          <div className="progress-bar-wrapper">
            <div className="progress-bar" style={{ width: `${percent}%` }} />
          </div>

          <div className="progress-numbers-wrapper">
            <span className="progress-numbers">
              <strong>{finished} / </strong>
              {total}
            </span>
            <span className="progress-percent">{percent}%</span>
          </div>
        </div>
      </div>

      <div className="total-points-wrapper">
        <span className="total-points-wrapper">{t("total_points")}</span>
        <span className="total-points">{points} pts</span>
      </div>
    </div>
  );
};
