import React from "react";
import { parseCategoryID } from "../../../utils/";
import get from "lodash.get";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function SeriesHero(props) {
  const { data, seriesFromSearch } = props;
  const { t } = useTranslation();

  const isTop = seriesFromSearch && seriesFromSearch?.[0]?.isTop;
  const isNew =
    seriesFromSearch &&
    seriesFromSearch?.[0]?.tags?.find((tag) => tag?.name === "Newest");

  return (
    <section className="series-header">
      <div className="viewport">
        <div className="inner">
          <div className="top">
            <div className="txt-wrapper">
              <h1>{data.title}</h1>
              <div className="author">
                {!data.speakers ||
                !data.speakers?.length ||
                !data.speakers?.filter((speaker) => speaker !== null)
                  .length ? null : (
                  <span>with </span>
                )}
                {data &&
                  data.speakers &&
                  data.speakers
                    .filter((speaker) => speaker !== null)
                    .map((s, i) =>
                      data.speakers.length > 1 &&
                      i < data.speakers.length - 1 ? (
                        <Link
                          key={s.id}
                          to={`/speaker/${s.id}`}
                          style={{ color: "white", textDecoration: "none" }}
                        >
                          <span className="hover-opacity">
                            {s.name} {s.surname},{" "}
                          </span>
                        </Link>
                      ) : (
                        <Link
                          key={s.id}
                          to={`/speaker/${s.id}`}
                          style={{ color: "white", textDecoration: "none" }}
                        >
                          <span className="hover-opacity">
                            {s.name} {s.surname}
                          </span>
                        </Link>
                      )
                    )}
              </div>
              <div className={`cat`}>
                <Link
                  to={`/category/${
                    parseCategoryID(data.category).categorySlug
                  }`}
                  style={{
                    color: `${parseCategoryID(data.category).categoryColor}`,
                    textDecoration: "none",
                  }}
                >{t(parseCategoryID(data.category)?.categoryTranslationKey)}</Link>
                <span> SERIES</span>
              </div>
              {data.isSustainability && (
                <div className="sustainability-tag">
                  <div className="sustainability-tag-wrapper">
                    <span className="icon-sustainability"></span>
                    <span className="text-sustainability">sustainability</span>
                  </div>
                </div>
              )}
            </div>
            <div className="img-wrapper">
              <div className="gradient"></div>
              <div className="medias">
                {isTop && (
                  <div
                    style={{
                      position: "relative",
                      zIndex: 100,
                      transform: "translate(-1px)",
                    }}
                    className="top-series"
                  >
                    <img
                      src="/assets/icons-svg/top-10-tag.svg"
                      alt="top 10 tag"
                    />
                  </div>
                )}
                {isNew && !isTop && (
                  <div
                    style={{ position: "relative", zIndex: 100 }}
                    className="top-series"
                  >
                    <img
                      src="/assets/icons-svg/newest-tag.svg"
                      alt="top 10 tag"
                    />
                  </div>
                )}

                {seriesFromSearch?.[0]?.tags?.find(
                  (e) => e.id === process.env.REACT_APP_ESSENTIALS
                ) && (
                  <div className="star-bg">
                    <div
                      className={`star-layer${
                        seriesFromSearch?.[0]?.allComplete ? " selected" : ""
                      }`}
                    >
                      <img
                        className="full"
                        src="/assets/icons-svg/star_full.svg"
                        alt=""
                      />
                      <img
                        className="notfull"
                        src="/assets/icons-svg/star_empty.svg"
                        alt=""
                      />
                    </div>
                  </div>
                )}

                {get(data, "seriePicture.uri", "") && (
                  <span
                    style={{
                      borderTopLeftRadius: "8px",
                      backgroundPosition: "top",
                      backgroundImage: `url(${
                        process.env.REACT_APP_IMG_URL
                      }/storage/serie-picture/${get(
                        data,
                        "seriePicture.uri",
                        ""
                      )}?format=Horizontal_North`,
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="description">
            <h2>{data.subtitle}</h2>
            <p>{data.description}</p>
          </div>
        </div>
      </div>
    </section>
  );
}
