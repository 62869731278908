import React from "react";

const PlayButton = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
    >
      <circle
        cx="12"
        cy="12.5"
        r="11.5"
        fill="black"
        fill-opacity="0.8"
        stroke="white"
      />
      <path
        d="M9.82667 8.81857L16.0176 12.3929L9.82667 15.9672L9.82667 8.81857Z"
        stroke="white"
      />
    </svg>
  );
};
export default PlayButton;
