import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import SeriesSlider from "../../ui/seriesSlider";
import CategoryHero from "./categoryHero";
import SearchBar from "../search/searchBar";
import SearchResults from "../search/searchResults";
import queryString from "query-string";
import useSearch from "../../../store/hooks/useSearch";
import {
  getCategories,
  searchSeries,
  getSeriesById,
  getRecommendedQuizzes,
  getMoodBoard,
} from "../../../utils/data";
import {
  getNthItemFromPathname,
  parseCategory,
  parseCategoryID,
  seriesFilter,
} from "../../../utils";
import Banner1 from "../../layout/banner1";
import { useGlobalContext } from "../../../store/global";
import get from "lodash.get";
import EpisodesSlider from "../../ui/episodesSlider";
import QuizItem from "../../ui/QuizItem";
import { useTranslation } from "react-i18next";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";

const Category = (props) => {
  const { setLoading } = useGlobalContext();
  const [category, setCategory] = useState(null);
  const [color, setColor] = useState("");
  const [firstTime, setFirstTime] = useState(true);
  const [searchValue, setSearchValue] = useState(
    queryString.parse(window.location.search).q || ""
  );

  const [searchQ, setSearchQ] = useState(
    queryString.parse(window.location.search).q || ""
  );
  const [searchTags, setSearchTags] = useState(
    queryString.parse(window.location.search).tags || ""
  );
  const [searchFilter, setSearchFilter] = useState(
    queryString.parse(window.location.search).filter || ""
  );

  const [latestSeries, setLatestSeries] = useState([]);
  const [recommendedQuizzes, setRecommendedQuizzes] = useState([]);

  const [searchSort, setSearchSort] = useState("");
  const [sortKey, setSortKey] = useState("latest");

  const [selectedMoodTags, setSelectedMoodTags] = useState(new Set());
  const [moodBoardPage, setMoodBoardPage] = useState(null);
  const [fetchingNextMoodPage, setFetchingNextMoodPage] = useState(false);
  const [moodBoard, setMoodBoard] = useState([]);
  const [moodRandomSeed, setMoodRandomSeed] = useState();

  const onFetchNextMoodPage = async () => {
    if (fetchingNextMoodPage) return;
    setFetchingNextMoodPage(true);
    const newPage = moodBoardPage + 1;
    const res = await getMoodBoard(newPage, Array.from(selectedMoodTags), moodRandomSeed);
    const data = res?.data || [];
    setMoodBoard([...(moodBoard || []), ...(data || [])]);
    setMoodBoardPage(!!res?.data && res?.data?.length < 15 ? null : newPage);
    setFetchingNextMoodPage(false);
  };

  useEffect(() => {
    const fetchNewMoodBoard = async () => {
      setFetchingNextMoodPage(true);
      setMoodBoard([]);
      const newPage = 0;
      const res = await getMoodBoard(newPage, Array.from(selectedMoodTags));
      const data = res?.data || [];
      const seed = res?.headers?.["random-seed"];
      console.log(seed);
      setMoodRandomSeed(seed);
      setMoodBoard(data || []);
      setMoodBoardPage(!!res?.data && res?.data?.length < 15 ? null : newPage);
      setFetchingNextMoodPage(false);
    };

    fetchNewMoodBoard();
  }, [selectedMoodTags]);

  const { t } = useTranslation();

  const { episodes, series, quizzes } = useSearch(
    searchQ,
    searchTags,
    searchFilter,
    category,
    !!category,
    searchFilter === "speaker",
    searchSort,
    false // isSustainability = false
  );

  const handleFilter = (q, tags, filter, sort = "") => {
    setSearchQ(q);
    setSearchTags(tags);
    setSearchFilter(filter);
    setSearchSort(sort);

    if (
      sort == '["releaseDate","DESC"]' ||
      sort == '["createdAt","DESC"]' ||
      sort == ""
    ) {
      setSortKey("latest");
    } else if (
      sort == '["releaseDate","ASC"]' ||
      sort == '["createdAt","ASC"]'
    ) {
      setSortKey("earliest");
    } else if (sort == '["morningViewCount","DESC"]') {
      setSortKey("mostViewed");
    }
  };

  useEffect(() => {
    if (firstTime) {
      setFirstTime(false);
    } else {
      setSearchQ("");
      setSearchValue("");
      setSearchTags("");
      // setSearchFilter('')

      if (queryString.parse(window.location.search).filter) {
        setSearchFilter(queryString.parse(window.location.search).filter);
      } else {
        setSearchFilter("");
      }
    }

    const getPageData = async () => {
      setLoading(true);
      const categories = await getCategories();
      console.log(
        "categories",
        categories,
        parseCategory(getNthItemFromPathname(props.location.pathname, 2))
      );
      const catId = categories.data.filter(
        (c) =>
          c.id ===
          parseCategory(getNthItemFromPathname(props.location.pathname, 2))
            .catgegoryId
      );
      const catColor = parseCategory(
        getNthItemFromPathname(props.location.pathname, 2)
      ).categoryColor;
      if (catId.length === 0) {
        props.history.replace("/notfound");
        return;
      }
      const catid = catId[0].id;

      const range = "[0, 2]";
      let tempLatestSeries = await searchSeries({
        category: catid,
        range,
        sort: `["createdAt", "DESC"]`
      });

      let promises = [];
      tempLatestSeries.data.map((serie) => {
        promises.push(getSeriesById(serie.id));
      });
      let [serieDetail0, serieDetail1, serieDetail2] = await Promise.all(
        promises
      );
      tempLatestSeries.data = [];
      tempLatestSeries.data.push(serieDetail0.data);
      tempLatestSeries.data.push(serieDetail1.data);
      tempLatestSeries.data.push(serieDetail2.data);

      const rq = await getRecommendedQuizzes({ category: catid });
      rq.data.map((q) => {
        q.category = {
          id: q.category,
        };
      });

      setLoading(false);
      setCategory(catid);
      setColor(catColor);
      setLatestSeries(tempLatestSeries);
      setRecommendedQuizzes(rq.data);
    };

    getPageData();
  }, [props.location.pathname, category]);

  const handleContinueToWatch = (serie) => {
    let link = `/series/${serie?.id}`;
    if (serie.videos.length === 0) {
      return link;
    }
    let serieCompleted = true;
    for (const v of serie.videos) {
      if (v.video?.id && v.video?.userComplete === false) {
        link = `/video?id=${v.video?.id}`;
        serieCompleted = false;
        break;
      }
    }
    if (serieCompleted && serie.videos[0]?.video?.id) {
      link = `/video?id=${serie.videos[0]?.video.id}`;
    }
    return link;
  };

  return (
    <div id="content">
      <div className="back-button" onClick={() => props.history.goBack()}>
        <span className="icon-arrowup2"></span> {t("Back")}
      </div>
      <CategoryHero />

      <SearchBar
        searchValue={searchValue}
        onSearchChange={(e) => setSearchValue(e)}
        searchQ={searchQ}
        onQChange={(e) => handleFilter(e, searchTags, searchFilter)}
        searchTags={searchTags}
        onTagChange={(e) => handleFilter(searchQ, e, searchFilter)}
        searchFilter={searchFilter}
        onFilterChange={(e) => handleFilter(searchValue, "", e)}
        sortKey={sortKey}
        onSortChange={(e) =>
          handleFilter("", searchTags, searchFilter, e.value)
        }
        selectedMoodTags={selectedMoodTags}
        onSelectedMoonTagChange={setSelectedMoodTags}
        isCategory={true}
        color={color}
        filterCategory={category}
      />

      {(quizzes.isLoading && !quizzes.list.length) ||
      (episodes.isLoading && !episodes.list.length) ||
      (series.isLoading && !series.list.length) ? null : (
        <>
          {/*{(searchFilter === '' || searchFilter === 'quiz') && (quizzes.list.length !== 0) ? (*/}
          {searchFilter === "quiz" && quizzes.list.length !== 0 ? (
            <>
              {searchQ == "" &&
                searchTags == "" &&
                searchSort == "" &&
                recommendedQuizzes.length > 0 && (
                  <section className="series-videos in-search-page">
                    <div className="viewport">
                      <div className="inner">
                        <h2 className="title">{t("Recommended_For_You")}</h2>
                        <div className="video-list">
                          {recommendedQuizzes.map((v, i) =>
                            i < recommendedQuizzes.length ? (
                              <QuizItem key={v.id} quiz={v} />
                            ) : null
                          )}
                        </div>
                      </div>
                    </div>
                  </section>
                )}
              <SearchResults.Quiz
                videos={quizzes.list}
                limit={quizzes.list.length}
                t={t}
              />
              {!quizzes.endReach ? (
                <div className="load-more">
                  <button
                    className="tertiary small"
                    onClick={quizzes.fetchMore}
                  >
                    <span>
                      {quizzes.isLoading ? t("Loading") : t("Discover_More")}
                    </span>
                  </button>
                </div>
              ) : null}
            </>
          ) : null}

          {/*{(searchFilter === '' ||*/}
          {/*  searchFilter === 'episodes' ||*/}
          {(searchFilter === "episodes" || searchFilter === "speaker") &&
          episodes.list.length !== 0 ? (
            <>
              <SearchResults
                videos={episodes.list}
                limit={episodes.list.length}
              />
              {!episodes.endReach ? (
                <div className="load-more">
                  <button
                    className="tertiary small"
                    onClick={episodes.fetchMore}
                  >
                    <span>
                      {episodes.isLoading ? t("Loading") : t("Discover_More")}
                    </span>
                  </button>
                </div>
              ) : null}
            </>
          ) : null}

          {(searchFilter === "" || searchFilter === "series") &&
          series.list.length !== 0 ? (
            <section className="series in-search-page">
              <div className="viewport">
                {searchQ == "" &&
                  searchTags == "" &&
                  latestSeries.data.length !== 0 && (
                    <div className="inner">
                      <h2 className="title">{t("Latest_Series")}</h2>

                      <div className="latest-list">
                        {latestSeries.data.map((s, i) => (
                          <div key={i} className="latest-serie">
                            <div className="latest-serie-info">
                              <div className="media-wrapper">
                                {get(s, "seriePicture.uri", "") && (
                                  <div
                                    className="media"
                                    style={{
                                      backgroundImage: `url(${
                                        process.env.REACT_APP_IMG_URL
                                      }/storage/serie-picture/${get(
                                        s,
                                        "seriePicture.uri",
                                        ""
                                      )}?format=Horizontal_Center`,
                                    }}
                                  />
                                )}
                              </div>
                              <div className="text-wrapper">
                                <div className="category-wrapper">
                                  <div
                                    className={`cat ${
                                      parseCategoryID(s.category)
                                        .categoryShorthand
                                    }`}
                                  >
                                    {t(parseCategoryID(s.category)?.categoryTranslationKey)}
                                  </div>
                                  {s.isSustainability && (
                                    <div className="sustainability-tag-wrapper">
                                      <span className="icon-sustainability"></span>
                                      <span className="text-sustainability">
                                        sustainability
                                      </span>
                                    </div>
                                  )}
                                </div>
                                <div className="title">{s.title}</div>
                                {s?.speakers?.length > 0 && (
                                  <div className="author">
                                    <span>{t("With")}: </span>
                                    <span>
                                      {s.speakers
                                        .filter((spkr) => spkr !== null)
                                        .map(
                                          (spkr, i) =>
                                            `${spkr.name} ${spkr.surname}`
                                        )
                                        .join(", ")}
                                    </span>
                                  </div>
                                )}
                                <div className="description">
                                  {s.description}
                                </div>
                                <div className="links">
                                  <Link
                                    className="link"
                                    to={`/series/${s?.id}`}
                                  >
                                    {t("View_Details")}
                                  </Link>
                                  <Link
                                    className="button"
                                    to={handleContinueToWatch(s)}
                                  >
                                    <span className="icon-playbuttonbold"></span>
                                    {t("Watch")}
                                  </Link>
                                </div>
                              </div>
                            </div>
                            <div className="latest-serie-episodes">
                              <div className="info">
                                <h2 className="videos">
                                  {` ${s.videos.filter(seriesFilter).length} ${
                                    s.videos.filter(seriesFilter).length > 1
                                      ? t("XEpisodes")
                                      : t("XEpisode")
                                  }`}
                                </h2>
                              </div>
                              <EpisodesSlider serie={s} />
                            </div>
                            {s.quiz && s.quiz != null && (
                              <div className="latest-serie-quiz">
                                <div className="text-wrapper">
                                  <img
                                    className="icon"
                                    src="/assets/icons-svg/quiz-time-logo-round.svg"
                                    alt="quiz-time"
                                  ></img>
                                  <div className="text">
                                    {t("Ready_For_A_Quiz")}
                                  </div>
                                </div>
                                <Link
                                  className="button"
                                  to={`/quiz?id=${s?.quiz?.id}`}
                                >
                                  {t("Start")}
                                </Link>
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                <div className="inner">
                  <h2 className="title">{t("All_Series")}</h2>
                  <SeriesSlider.List series={series.list} t={t} />
                  {!series.endReach ? (
                    <div className="load-more">
                      <button
                        className="tertiary small"
                        onClick={series.fetchMore}
                      >
                        <span>
                          {series.isLoading ? t("Loading") : t("Discover_More")}
                        </span>
                      </button>
                    </div>
                  ) : null}
                </div>
              </div>
            </section>
          ) : null}

          {searchFilter === "mood" && (
            <section className="series in-search-page">
              <div className="viewport">
                <div className="inner">
                  <ResponsiveMasonry
                    columnsCountBreakPoints={{ 750: 2, 900: 3 }}
                  >
                    <Masonry gutter="16px">
                      {moodBoard?.map((e, i) => (
                        <MasonryChild
                          key={
                            e.photographyPictureWatermark?.uri
                          }
                          url={`${
                            process.env.REACT_APP_IMG_URL
                          }/storage/photography-picture/${e.photographyPictureWatermark?.uri}`}
                          urlWatermark={`${
                            process.env.REACT_APP_API_URL
                          }/storage/photography-picture/${e.photographyPictureWatermark?.uri}`}
                          // tag={e.tag[0]?.name}
                        />
                      ))}
                    </Masonry>
                  </ResponsiveMasonry>

                  {moodBoardPage !== null && (
                    <div className="load-more">
                      <button
                        disabled={fetchingNextMoodPage}
                        className="tertiary small"
                        onClick={onFetchNextMoodPage}
                      >
                        <span>
                          {fetchingNextMoodPage
                            ? t("Loading")
                            : t("Discover_More")}
                        </span>
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </section>
          )}

          {quizzes.list.length === 0 &&
          !quizzes.isLoading &&
          episodes.list.length === 0 &&
          !episodes.isLoading &&
          series.list.length === 0 &&
          !series.isLoading ? (
            <div className="empty">
              <h2 className="title">{t("No_Result_For_Your_Search")}</h2>
            </div>
          ) : null}
        </>
      )}
      <Banner1 />
    </div>
  );
};

export default withRouter(Category);

const MasonryChild = ({ url, urlWatermark, tag }) => {
  const { openImageModal } = useGlobalContext();

  return (
    <>
      <div
        className="masonry-child"
        onClick={() => openImageModal({ url: urlWatermark })}
      >
        <div className="wrapper-img">
          <img src={url} alt="" />
        </div>
      </div>
    </>
  );
};
