import React from "react";

const Badge = ({ color = "#fff" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M8 11C5.24 11 3 8.83337 3 6.16671C3 3.50004 5.24 1.33337 8 1.33337C10.76 1.33337 13 3.50004 13 6.16671C13 8.83337 10.76 11 8 11ZM8 2.33337C5.79333 2.33337 4 4.05337 4 6.16671C4 8.28004 5.79333 10 8 10C10.2067 10 12 8.28004 12 6.16671C12 4.05337 10.2067 2.33337 8 2.33337Z"
        fill={color}
      />
      <path
        d="M10.4127 15.6666C10.226 15.6666 10.0393 15.6199 9.84601 15.5333L8.05268 14.6866C8.03268 14.6799 7.95935 14.6799 7.93268 14.6866L6.15269 15.5266C5.75936 15.7133 5.34603 15.7066 5.02603 15.4999C4.69269 15.2866 4.49936 14.8933 4.50603 14.4266L4.51268 9.50661C4.51268 9.23328 4.72601 8.99328 5.01268 9.00661C5.28601 9.00661 5.51268 9.23328 5.51268 9.50661L5.50603 14.4266C5.50603 14.5733 5.54602 14.6466 5.56602 14.6533C5.57936 14.6599 5.63936 14.6666 5.73269 14.6199L7.51935 13.7733C7.80602 13.6399 8.19936 13.6399 8.48603 13.7733L10.2794 14.6199C10.3727 14.6666 10.4327 14.6599 10.446 14.6533C10.466 14.6399 10.506 14.5666 10.506 14.4266V9.3866C10.506 9.11326 10.7327 8.8866 11.006 8.8866C11.2794 8.8866 11.506 9.11326 11.506 9.3866V14.4266C11.506 14.8999 11.3127 15.2866 10.9794 15.4999C10.806 15.6133 10.6127 15.6666 10.4127 15.6666Z"
        fill={color}
      />
    </svg>
  );
};

export default Badge;
