import React, { useState } from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { requestResetPassword } from "../../../utils/data";
import { useGlobalContext } from "../../../store/global";

const ForgotPassword = (props) => {
  const [showErr, setShowErr] = useState(false);
  const { openModal } = useGlobalContext();

  const validateEmail = async () => {
    const email = document.getElementById("email");
    if (email.value.match(/^.+@.+\..+$/)) {
      const res = await requestResetPassword(email.value);
      if(res?.data?.success){
        openModal("emailSent");
        props.history.push("/auth");
      }else{
        setShowErr(true);
      }
    } else {
      setShowErr(true);
    }
  };

  const removeErr = () => {
    setShowErr(false);
  };

  return (
    <div id="page">
      <div className="signin">
        <div className="logo-wrapper">
          <div className="hyatt icon-HYATT"></div>
          <div className="ideas icon-IDEAS"></div>
        </div>

        <div className="form-wrapper">
          <div className="form-inner">
            <div className="form-title">
              <h2>Forgot your password?</h2>
              <p>
                Please enter your email address to
                <br />
                request a password reset
              </p>
            </div>
            <div className="form-item">
              <label htmlFor="" type="email">
                Hyatt Email
              </label>
              <input id="email" type="text" onChange={removeErr} />
            </div>
            <div className="form-err" style={{ opacity: `${showErr ? 1 : 0}` }}>
              Please enter a valid Hyatt email address
            </div>
            <div className="form-actions">
              <button className="big primary" onClick={validateEmail}>
                <span>Reset Password</span>
              </button>
            </div>
          </div>

          <Link to="/auth" className="back">
            Back
          </Link>
        </div>
      </div>
    </div>
  );
};

export default withRouter(ForgotPassword);
