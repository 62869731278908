const korean = {
  // Menu
  Home: "홈",
  FNB: "식음료",
  FNB_Blank: "식음료",
  OPSHR: "오퍼레이션 | 인사",
  OPSHR_Blank: "오퍼레이션 | 인사",
  SNM: "세일즈 & 마케팅",
  SNM_Blank: "세일즈 & 마케팅",
  Search: "검색",
  Search_Place_Holder: "시리즈, 퀴즈, 연사",
  Series: "시리즈",
  Quizzes: "퀴즈",
  Speakers: "연사",
  Speaker: "스피커",
  Leaderboard: "순위 현황",
  My_Profile: "내 프로필",
  My_Lists: "내 목록",
  My_Quizzes: "내 퀴즈",
  Log_Out: "로그아웃",
  VIEW_ALL_IN_DIRECTORY: "모든 목록 보기",

  // Home page
  WELCOME_BACK: "돌아온 것을 환영합니다!",
  RECOMMENDED_FOR_YOU: "추천 퀴즈",
  Episodes: "에피소드",
  My_Episodes: "내 퀴즈",
  My_Quizzes_Title: "내 퀴즈",
  INSPIRATION: "영감",
  DIRECTION: "방향성",
  EXCELLENCE: "우수성",
  APPLICATION: "적용",
  SOLUTION: "해결",
  Home_Page_Content:
    "I.D.E.A.S 2023은 무엇인가요? 매주 월요일에 5분 분량의 다양한 비디오로 50명 이상의 리더들로부터 배우고 영감을 얻으세요. 언제든지, 어디서든, 나만의 페이스로 스트리밍 가능합니다. 객실, 레스토랑, 바 관리를 위한 퀴즈가 포함된 교육형 엔터테인먼트 툴. 판매 기술, 디지털 및 커뮤니케이션의 모범 사례. 이벤트 판매, 계획 및 운영을 더 잘할 수 있는 솔루션. 더 나은 리더가 되어 우리 사람들을 돌보세요. Hyatt 아시아 태평양 지역 호텔의 모든 리더십 위원회 및 팀을 위한 프로그램입니다.",
  Countries: "국가",
  Markets: "마켓",
  Hotels: "호텔",
  Users: "사용자",
  We_Love_Mondays: "우리는 ❤️ 월요일",
  Viewed: "시청한 영상",
  Views: "조회수",
  Load_More: "더 보기",
  Explore: "탐색",
  Trending_Series: "인기 시리즈",
  Dig_Use_Share_Anywhere_Anytime:
    "언제 어디서든 탐구하고, 활용하고, 공유하세요",
  With: "연사",
  Videos: "동영상",
  Trending_Speakers: "인기 연사",
  Discover_Series: "시리즈 살펴보기",
  Have_Suggestions_For_Improvements: "개선 사항이 있나요?",
  Give_Us_Your_Ideas: "아이디어를 제공해주세요",
  Terms_And_Conditions: "이용 약관",
  Focus: "포커스",

  // EXPLORE ALL
  EXPLORE_THE_LIBRARY: "웹사이트 탐색하기",
  Search_By_Theme_Topi_Or_Speaker: "테마, 주제 또는 연사별로 검색",
  SERIES: "시리즈",
  EPISODES: "에피소드",
  QUIZZES: "퀴즈",
  QUIZ: "퀴즈",
  Watch_The_Series: "시리즈 시청하기",
  START: "시작하기",
  RETAKE: "다시 풀기",
  REVIEW: "오답 검토",
  CONTINUE: "계속하기",
  CERTIFICATE: "수료증",
  passed: "성공",
  failed: "실패",
  inProgress: "진행 중",
  notStarted: "시작하지 않음",
  Discover_More: "더 보기",
  All: "전체",
  Loading: "로딩 중...",

  // CATEGORY
  Search_For_Series: "시리즈 검색",
  Latest_Series: "최신 시리즈",
  All_Series: "전체 시리즈",
  View_Details: "자세히 보기",
  Watch: "시청하기",
  XEpisodes: "에피소드",
  XEpisode: " 에피소드",
  Ready_For_A_Quiz: "더 많은 점수를 얻기 위해 퀴즈에 도전할 준비가 되셨나요?",
  Start: "시작하기",
  Sort_By: "정렬 기준",
  Default: "기본",
  Latest: "최신순",
  Earliest: "과거순",
  Most_Viewed: "조회순",
  All_Episodes: "전체 에피소드",
  Search_For_Quizzes: "퀴즈 검색",
  Recommended_For_You: "추천 퀴즈",
  All_Quizzes: "모든 퀴즈",
  Re_take: "다시 하기",
  Passed: "성공",
  Failed: "실패",

  // EPISODE PAGE
  Related_Series: "관련 시리즈",
  Share_Video: "영상 공유",
  Watch_Later: "나중에 볼 영상",
  XView: " 보기",
  XViews: "조회수",
  Released_On: "게시일: {{date}}",
  Discussion: "토론",
  XComments: " 댓글",
  XComment: " 댓글",
  Reply: "답글 달기",
  Enter_Your_Comment_Here: "여기에 댓글을 입력하세요",
  Cancel: "취소",
  Submit: "제출하기",
  Back_To_Top: "위로 가기",

  // LEADERBOARD
  Weekly_Leaderboard: "주간 순위",
  Expert_Viewer: "전문 시청자",
  Biggest_Viewed: "지난 주 가장 많은 에피소드 시청",
  Fastest_Viewer: "가장 빠른 시청자",
  Woke_Up_Earilest: "일찍 일어나 가장 먼저 시청",
  Star_Contributor: "최고 기여자",
  Posted_Greatest: "가장 많은 사랑과 관심을 받은 댓글 작성자",

  // PROFILE PAGE
  EDIT_PROFILE: "프로필 수정",
  SHOW_HISTORY: "전체 역사 보기",
  MY_QUIZ_HISTORY: "내 퀴즈 기록",
  RE_TAKE: "다시 하기",
  BADGES: "배지",
  PERFORMANCE: "성능",
  Pts: "점",
  PROGRESS: "진행율",
  MY_ACTIVITY: "내 활동",
  MonthAndYear: "월, 년",
  VIDEOS: "동영상",
  QUIZZES_TAKEN: "참여한 퀴즈",
  QUIZZES_PASSED: "통과한 퀴즈",
  COMMENTS: "댓글",
  Videos_Watched: "시청한 영상",
  Quizzes_Taken: "참여한 퀴즈",
  Comments: "댓글",
  Week_1: "1주차",
  Week_2: "2주차",
  Week_3: "3주차",
  Week_4: "4주차",
  Week_5: "5주차",

  // NOTIFICATION
  Notification: "알림",
  Discover: "확인하기",
  Take_The_Quiz: "퀴즈 풀기",

  // MY LISTS
  WATCH_LATER: "나중에 볼 영상",
  XVideos: " 개 영상",
  XVideo: "비디오",
  VIEWED: "시청한 영상",
  Yesterday: "어제",
  Clear: "기록 삭제",
  Watc_Again: "다시 보기",
  LIKED: "좋아요",

  // OTHER USER PROFILE
  Back: "뒤로",
  Badges: "배지",
  LIKED_VIDEOS: "좋아요 표시한 영상",
  No_Result_For_Your_Search: "검색 결과가 없습니다",

  // QUIZ INTRODUCTION
  XQuestions: "개 질문",
  NEXT: "다음",
  PREVIOUS: "이전",

  // QUIZ RESULT
  Your_Score_Is: "당신의 점수는...",
  Retake: "다시 풀기",
  XPoints: "점",
  Xpoints: "점",
  Dont_Worry:
    "걱정하지 마세요. 무엇이 잘못되었는지 살펴보고 질문을 이해하여 정답을 맞춰 보세요.",
  Help_Me_Pass: "오답 보기",
  Almost_There: "조금만 더 힘내세요!",
  Help_Description:
    "<1> 이미 {{correctCount}}개의 정답을 맞추셨습니다. </1> <2>개의 정답을 맞추셨습니다. 아래는 놓친 질문들입니다:</2>",
  Find_The_Correct_Answer:
    "<1>EPISODE{{relatedEpisode}}</1>편에서 정답을 찾아보세요",
  Learn_From_Series: "시리즈 다시 보기",
  Quizz_Welcome:
    "<p>{{title}} 시리즈의 핵심 내용을 기억하시나요?</p> <p>인터랙티브 퀴즈로 학습한 내용을 확인하고 100점 중 80점 이상을 받아보세요.</p><p>처음에 실패하더라도 성공할 때까지 다시 도전할 수 있으니 걱정하지 마세요.</p>",
  Xquestions: "개 질문",

  // Lost
  Explore_All: "모두 탐색",
  No_Result_Found: "결과가 없습니다.",
  Take_The_Quizz: "퀴즈 풀기",

  // Home page
  Play_Episode: "내 에피소드",
  Start_Quiz: "퀴즈 시작",
  Visit_Now: "지금 방문하세요",
  Certificates: "수료증",

  // Explore All
  Filter_By: "필터",
  In_Watch_List: "시청 목록에 추가됨",
  Remove_From_Watchlist: "시청 목록에서 제거",
  Link_Copied: "영상 링크가 복사되었습니다, 이제 동료들과 공유하세요!",
  Xmins: "분",

  // Profile page
  Comments_Posted: "게시한 댓글",
  January: "일월",
  February: "이월",
  March: "삼월",
  April: "사월",
  May: "오월",
  June: "육월",
  July: "칠월",
  August: "팔월",
  September: "구월",
  October: "십월",
  November: "십일월",
  December: "십이월",
  Jan: "일월",
  Feb: "이월",
  Mar: "삼월",
  Apr: "사월",
  Jun: "육월",
  Jul: "칠월",
  Aug: "팔월",
  Sep: "구월",
  Oct: "십월",
  Nov: "십일월",
  Dec: "십이월",
  DOWNLOAD_ALL_CERTIFICATES: "수료증 전체 다운로드",
  Today: "오늘",
  A_Week_Ago: "1주일 전",
  A_Month_Ago: "1개월 전",

  // Quizz page
  There_Are_More: "이 질문에는 더 많은 정답이 있습니다. 다시 확인해보세요.",

  // 404 page
  Not_Be_Found: "요청한 리소스를 찾을 수 없습니다.",
  Check_URL: "URL을 확인하고 다시 시도하세요.",

  // Sustainability page
  sustainability: "지속 가능성",
  sustainability_solgan: "음식물 폐기물 줄이기, 플라스틱 제거, 환경 보호",
  sustainability_description:
    "건강한 환경은 모두가 최고의 성과를 낼 수 있도록 중요합니다",

  //Update
  Hotel_Statistics: "호텔 통계",
  Directory: "목록",
  "Welcome_to_I.D.E.A.S":
    "I.D.E.A.S 2024에 오신 것을 환영합니다! 월요일마다 5분짜리 마스터 클래스 비디오로 60명 이상의 호텔리어 리더들과 함께 학습을 강화하세요. 이동 중에도 나만의 속도에 맞춰 유연하게 학습할 수 있습니다. 상호작용 퀴즈와 인증서를 통해 객실, 레스토랑, 바 및 이벤트 관리 기술을 향상시키세요. 판매 기술, 디지털 전략 및 커뮤니케이션 모범 사례를 배우고, 이벤트를 판매, 계획 및 운영하는 혁신적인 방법을 배워보세요. 모든 Hyatt 아시아 태평양 지역 호텔의 리더십 위원회 및 팀을 위해 맞춤화된 프로그램입니다.",
  HYATT: "HYATT",
  PHOTOGRAPHY: "사진",
  "Dig_Use_&_Share": "언제 어디서든",
  Anywhere_Anytime: "탐구하고, 활용하고, 공유하세요",
  MoodBoard: "무드보드",
  "GLOBAL_HYATT_F&B_PHOTOGRAPHY": "HYATT GLOBAL 이벤트 및 F&B 라이브러리",
  Leaderboard_Badges: "순위 배지",
  Show_Full_History: "전체 역사 보기",
  "Average_of_users_have_watched...": "사용자가 본 평균",
  "...videos": "동영상",
  "Average_of_users_have_taken...": "사용자가 푼 평균 퀴즈 수",
  "...number_of_quizzes": "퀴즈",
  "Average_of_users_have_passed...": "사용자가 합격한 평균 퀴즈 수",
  "...quizzes": "퀴즈",
  Image_upload: "이미지 업로드",
  Enter_your_comment_here: "여기에 댓글을 입력하세요",
  Series_summary: "시리즈 요약",
  Download: "다운로드",
  Completions: "완료",
  view: "보기",
  views: "조회수",
  point: "점수",
  points: "점",
  Recipe: "레시피",
  Recipes: "레시피",
  Search_for_recipes: "레시피 검색",
  "Our_mission_is_to...":
    "저희의 미션은 각 호텔의 요리 이야기를 조명하는 매혹적인 이미지들의 방대한 컬렉션을 큐레이션하는 것입니다. 이곳에서 여러분은 Hyatt의 식음료 제공에서 혁신적인 정신과 공명하며, 식음료 우수성를 보여주는 기회를 제공하고, 자신의 걸작을 창작하는 데 영감을 얻고 격려를 받을 수 있습니다.",
  "All_of_the_images...":
    "우리의 컬렉션에 있는 모든 이미지는 Hyatt가 제작하고, 저작권은 Hyatt에 있습니다. 이 이미지는 전 세계 모든 Hyatt 호텔에서 독점적으로 사용할 수 있습니다. 고해상도 버전을 이용하려면 Brand Manager 플랫폼에 로그인하거나 여기를 클릭하여 접근해 주세요.",
  here: "여기",
  "...platform_or_by_clicking": "",
  HYATT_GLOBAL: "하얏트 글로벌",
  "EVENTS+F&B": "이벤트 + 식음료 사진 컬렉션",
  LIBRARY: "사진 갤러리",
  No_result_for_your_search: "검색 결과가 없습니다",
  "like.star": "최고예요",
  "like.like": "좋아요",
  "like.heart": "하트",
  "like.idea": "유익해요",

  Well_done: "잘 했어요",
  quiz_invite:
    "{{name}}시리즈의 영상을 모두 시청하셨습니다. 이제 퀴즈를 통해 학습한 내용을 확인해 보세요.",

  Taken_on: "응시일",
  Top_10: "Top 10 영상",
  See_all: "모두 보기",

  FNB_FULL_NAME: "식음료",
  OPSHR_FULL_NAME: "오퍼레이션 | 인사",
  SNM_FULL_NAME: "세일즈 & 마케팅",

  Empty_series_result: "검색된 시리즈가 없습니다",
  New_tag: "최신",

  Other_series_by_this_speaker: "이 발표자의 시리즈",

  REACTED: "반응",
  You_Reacted: "당신은 좋아요를 눌렀습니다",
  You_plus_x_others: "당신과 {{count}}명이 좋아요를 눌렀습니다",

  quiz_description_line1:
    "이 인터랙티브 퀴즈로 학습한 내용을 확인하고 100점 중 80점 이상을 받아보세요.",
  quiz_description_line2:
    "처음에 실패하더라도 성공할 때까지 다시 도전할 수 있으니 걱정하지 마세요.",

  XSeries: "시리즈",
  XSerie: "시리즈",

  total_points: "총점",
  download_all_certificates: "수료증 전체 다운로드",

  comment_liked: "새 좋아요",
  comment_replied: "새 댓글",
  award_obtained: "축하합니다",
  new_quiz: "새 퀴즈가 올라왔어요!",
  new_leaderboard: "리더보드",

  image_comment_hint: "사진 댓글은 30MB 미만이어야 합니다",

  Edit: "편집",
  Save: "저장",
  Delete: "삭제",

  Started_at: "시작 시간",
  React: "좋아요",

  Thank_you_for_your_comment: "댓글을 남겨주셔서 감사합니다",
  Your_comment_has_been_added: "댓글이 추가되었습니다",
  Back_to_IDEAS: "I.D.E.A.S.로 돌아가기",

  Please_confirm: "확인해주세요",
  Are_you_sure_you_want_to_delete_your_comment: "정말로 댓글을 삭제하시겠습니까?",

  Comment_removed: "댓글이 삭제되었습니다",
  Your_comment_has_been_removed: "귀하의 댓글이 삭제되었습니다",

  Thank_you: "감사합니다",
  Your_comment_changes_have_been_saved: "댓글 변경 사항이 저장되었습니다",

  Our_champions: "하얏트 챔피언",
  Total_users: "전체 사용자",

  Quiz: "퀴즈",
  Video_views: "영상 조회수",

  Edit_your_profile: "프로필 수정",
  Upload_image: "이미지 업로드",
  Upload_image_hint: "(20MB 제한)",
  Name: "이름",
  Surname: "성",
  Country_Region: "국가/지역",
  City: "도시",
  Brand: "브랜드",
  Hotel: "호텔",

  Search_a_series: "시리즈 검색",
};

export default korean;
