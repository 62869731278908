import React from "react";

export default ({color = "#ffffff"}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.52145 7.02145C5.71671 6.82618 6.03329 6.82618 6.22855 7.02145L8.5 9.29289L10.7714 7.02145C10.9667 6.82618 11.2833 6.82618 11.4786 7.02145C11.6738 7.21671 11.6738 7.53329 11.4786 7.72855L8.85355 10.3536C8.65829 10.5488 8.34171 10.5488 8.14645 10.3536L5.52145 7.72855C5.32618 7.53329 5.32618 7.21671 5.52145 7.02145Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.5 2.5C8.77614 2.5 9 2.72386 9 3V10C9 10.2761 8.77614 10.5 8.5 10.5C8.22386 10.5 8 10.2761 8 10V3C8 2.72386 8.22386 2.5 8.5 2.5Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 9.5C3.27614 9.5 3.5 9.72386 3.5 10V13.5H13.5V10C13.5 9.72386 13.7239 9.5 14 9.5C14.2761 9.5 14.5 9.72386 14.5 10V13.5C14.5 13.7652 14.3946 14.0196 14.2071 14.2071C14.0196 14.3946 13.7652 14.5 13.5 14.5H3.5C3.23478 14.5 2.98043 14.3946 2.79289 14.2071C2.60536 14.0196 2.5 13.7652 2.5 13.5V10C2.5 9.72386 2.72386 9.5 3 9.5Z"
        fill={color}
      />
    </svg>
  );
};
