import React, { useState, useEffect } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Legend,
  LabelList
} from 'recharts';
import { useTranslation } from 'react-i18next';
import './styles.scss';
import moment from 'moment';
import useCalendar from './useCalendar';
import { getHotelStatistic, getStatisticCSV } from '../../../utils/data';
import { Link } from 'react-router-dom';
import trophy from './trophy.png'

const formatter = (e) => new Intl.NumberFormat('en-US').format(e);

const useStats = (start, end) => {
    const [savedDates, setSavedDates] = useState({ start, end });
    const { t } = useTranslation();
    const [data, setData] = useState();
    const [isLoading, setIsLoading] = useState(false);

    const global = [
        { value: data?.allUsersCount, label: t('Total_users') },
        { value: data?.allViewsCount, label: t('Videos_Watched') },
        { value: data?.allQuizzesTaken, label: t('QUIZZES_TAKEN') },
        { value: data?.allQuizzesPassed, label: t('QUIZZES_PASSED') },
    ]

    const category = [
        {
            categoryLabel: 'F&B',
            categoryTranslationKey: 'FNB',
            className: 'fb',
            data: [
                {
                    value: data?.views?.find(e => e.category.id === process.env.REACT_APP_FB)?.totalAmount || 0,
                    label: t('Videos_Watched'),
                },
                {
                    value: data?.quizzesTaken?.find(e => e.category.id === process.env.REACT_APP_FB)?.totalAmount || 0,
                    label: t('Quizzes_Taken'),
                },
                {
                    value: data?.quizzesPassed?.find(e => e.category.id === process.env.REACT_APP_FB)?.totalAmount || 0,
                    label: t('QUIZZES_PASSED'),
                },
            ]
        },
        {
            categoryLabel: 'OPS|HR',
            categoryTranslationKey: 'OPSHR',
            className: 'ops',
            data: [
                {
                    value: data?.views?.find(e => e.category.id === process.env.REACT_APP_OPERATIONS)?.totalAmount || 0,
                    label: t('Videos_Watched'),
                },
                {
                    value: data?.quizzesTaken?.find(e => e.category.id === process.env.REACT_APP_OPERATIONS)?.totalAmount || 0,
                    label: t('Quizzes_Taken'),
                },
                {
                    value: data?.quizzesPassed?.find(e => e.category.id === process.env.REACT_APP_OPERATIONS)?.totalAmount || 0,
                    label: t('QUIZZES_PASSED'),
                },
            ]
        },
        {
            categoryLabel: 'S&M',
            categoryTranslationKey: 'SNM',
            className: 'sm',
            data: [
                {
                    value: data?.views?.find(e => e.category.id === process.env.REACT_APP_SM)?.totalAmount || 0,
                    label: t('Videos_Watched') || 0,
                },
                {
                    value: data?.quizzesTaken?.find(e => e.category.id === process.env.REACT_APP_SM)?.totalAmount || 0,
                    label: t('Quizzes_Taken'),
                },
                {
                    value: data?.quizzesPassed?.find(e => e.category.id === process.env.REACT_APP_SM)?.totalAmount || 0,
                    label:t('QUIZZES_PASSED'), 
                },
            ]
        }
    ]

    const weeks = []

    let i = 1
    let week = data?.[`week${i}`]
    while (!!week) {
        let views = 0
        for (const q of week.views) {
            views += q.totalAmount
        }
        let quizzesTaken = 0
        for (const q of week.quizzesTaken) {
            quizzesTaken += q.totalAmount
        }
        let comments = 0
        for (const q of week.comments) {
            comments += q.totalAmount
        }

        weeks.push({
            name: `Week ${i}`,
            views,
            quizzesTaken,
            comments,
        })
        i++
        week = data?.[`week${i}`]
    }

    const leaderboards = [
        {
            type: t('Quizzes'),
            classNames: 'quizzes',
            users: data?.champions.quizPassedByUsers?.slice(0, 5).map((e) => ({
                id: e._id?._id,
                name: `${e._id.name} ${e._id.surname}`,
                score: `${e.totalAmount} ${e.totalAmount > 1 ? t('passed') : t('passed')}`,
                uri: `url(${process.env.REACT_APP_API_URL}/storage/user-picture/${e._id?.profilePicture?.uri})`
            })) || [],
        },
        {
            type: t('Video_views'),
            classNames: 'videos',
            users: data?.champions.videoViewedByUsers?.slice(0, 5).map((e) => ({
                id: e._id?._id,
                name: `${e._id.name} ${e._id.surname}`,
                score: `${e.totalAmount} ${e.totalAmount > 1 ? t('views') : t('view')}`,
                uri: `url(${process.env.REACT_APP_API_URL}/storage/user-picture/${e._id?.profilePicture?.uri})`
            })) || [],
        },
        {
            type: t('COMMENTS'),
            classNames: 'comments',
            users: data?.champions.videoCommentedByUsers?.slice(0, 5).map((e) => ({
                id: e._id?._id,
                name: `${e._id.name} ${e._id.surname}`,
                score: `${e.totalAmount} ${e.totalAmount > 1 ? t('points') : t('point')}`,
                uri: `url(${process.env.REACT_APP_API_URL}/storage/user-picture/${e._id?.profilePicture?.uri})`
            })) || [],
        },
    ];



    const getStats = async () => {
        const res = await getHotelStatistic({ start, end });
        setData(res?.data);
        setIsLoading(false);
    }

    const search = () => {
        if (moment(savedDates.start).isSame(start, 'date') &&
            moment(savedDates.end).isSame(end, 'date')) return;

        setSavedDates({ start, end });

        setIsLoading(true);
        getStats();
    }
    
    useEffect(() => {
        setIsLoading(true);
        getStats();
    }, [])

    return {
        weeks,
        global,
        category,
        isLoading,
        leaderboards,
        name: data?.name,
        series: data?.serieVideos,
        search,
        hotelId: data?.id,
    }
}

const Statistics = () => {
    const { t } = useTranslation();
    const [dates, setDates] = useState({
        start: new Date(new Date().setDate(new Date().getDate() - (4 * 7))),
        end: new Date(),
    });
    const stats = useStats(dates.start, dates.end);
    const [seriesQ, setSeriesQ] = useState('');
    const [chartMargin, setChartMargin] = useState({ top: 40, right: 40, left: 40, bottom: 40 })

    const autoResize = () => {
        if (window.innerWidth < 768 ){
            setChartMargin({ top: 102, right: 16, left: 0, bottom: 48 });
        } else {
            setChartMargin({ top: 86, right: 40, left: 0, bottom: 40 });
        }
    }

    useEffect(() => {
        window.addEventListener('resize', autoResize)
        autoResize();
    }, [])

    return (
        <div className="hotel-statistics">
            <div className="header">
                <h1>{stats.name}</h1>
            </div>

            <div className='content'>
                {!stats.isLoading &&
                    <section className='global-stats'>
                        {stats.global.map((e, idx) =>
                            <div key={idx} className='item-stats-wrapper'>
                                <div className='value'>{formatter(e.value)}</div>
                                <div className='label'>{e.label}</div>
                            </div>
                        )}
                    </section>
                }
                        
                <section className='actions'>
                    <Calendar
                        value={dates}
                        onChange={setDates}
                        onClose={() => stats.search()}
                    />

                    <button
                        disabled={stats.isLoading}
                        onClick={() =>
                            getStatisticCSV(
                                moment(dates.start).format('YYYY-MM-DD'),
                                moment(dates.end).format('YYYY-MM-DD'),
                                stats.hotelId,
                                stats.series,
                            )}
                        className='download-btn'
                    >{t('Download')}</button>
                </section>

                {stats.isLoading ?
                    <section className='loading'>
                        <span>{t('Loading')}</span>
                    </section>
                :
                    <>
                        <section className='categories-stats'>
                            {stats.category?.map((e, idx) =>
                                <div key={idx} className={'category-wrapper ' + e.className}>
                                    <span className='category-label'>{t(e.categoryTranslationKey)}</span>
                                    <div className='category-line-stats-wrapper'>
                                        {e.data.map((f, jdx) =>
                                            <div key={`${idx}-${jdx}`} className='category-item-stats-wrapper'>
                                                <div className='value'>{formatter(f.value)}</div>
                                                <div className='label'>{f.label}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                        </section>

                        {/* <section className='chart'>
                            <ResponsiveContainer
                                width="100%"
                                height={360}
                            >
                                <BarChart
                                data={stats.weeks}
                                margin={chartMargin}
                                maxBarSize={25}
                                >
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip cursor={false} content={<CustomTooltip />} />
                                <Legend verticalAlign="top" align="right" iconType="circle" />
                                <Bar
                                    dataKey="views"
                                    name={t('Videos_Watched')}
                                    fill="#53C1FF"
                                >
                                    <LabelList dataKey="views" position="top" />
                                </Bar>
                                <Bar
                                    dataKey="quizzesTaken"
                                    name={t('Quizzes_Taken')}
                                    fill="#FFEA32"
                                >
                                    <LabelList dataKey="quizzesTaken" position="top" />
                                </Bar>
                                <Bar
                                    dataKey="comments"
                                    name={t('Comments_Posted')}
                                    fill="#9A92FF"
                                >
                                    <LabelList dataKey="comments" position="top" />
                                </Bar>
                                </BarChart>
                            </ResponsiveContainer>
                        </section> */}

                        <section className='list-series'>
                            <div className='title'>
                                <span>{t('Series')}</span>
                                <input
                                    className='search-bar'
                                    placeholder={t("Search_a_series")}
                                    value={seriesQ}
                                    onChange={(e) => setSeriesQ(e.target.value)}
                                />
                            </div>

                            <div className='series-wrapper'>
                                {stats.series?.filter((e) => {
                                    if (!seriesQ) return true;
                                    const titleMatch = e.serie.title.toLowerCase().includes(seriesQ.toLowerCase());
                                    if (titleMatch) return true;
                                    const subtitleMatch = e.serie.subtitle.toLowerCase().includes(seriesQ.toLowerCase());
                                    if (subtitleMatch) return true;
                                    const descriptionMatch = e.serie.description.toLowerCase().includes(seriesQ.toLowerCase());
                                    if (descriptionMatch) return true;
                                    return false;
                                
                                }).slice(0, 5).map((e, idx) => {

                                    let cat = 'fb'
                                    switch (e.serie.category) {
                                        case process.env.REACT_APP_FB:
                                            cat = 'fb';
                                            break;
                                        case process.env.REACT_APP_OPERATIONS:
                                            cat = 'ops';
                                            break;
                                        case process.env.REACT_APP_SM:
                                            cat = 'sm';
                                            break;
                                    
                                        default:
                                            break;
                                    }

                                    return (
                                        <div key={idx} className={`series-item${e.order === 1 ? ' first' : ''}`}>
                                            <div
                                                className='cover'
                                                style={{
                                                    backgroundImage: `url(${process.env.REACT_APP_IMG_URL}/storage/serie-picture/${e.serie.seriePicture.uri}`,
                                                }}
                                            >
                                                <div className='tag'>
                                                    <span>{`#${e.order > 100 ? '100+' : e.order}`}</span>
                                                    {e.order === 1 && <img className='trophy' src={trophy} alt="" />}
                                                </div>
                                            </div>

                                            <div className='info-wrapper'>
                                                <span className={'category ' + cat}>
                                                    {cat === 'fb' ?
                                                        'F&B' : e.category === 'sm' ? 'S&M' : 'OPS|HR'}
                                                </span>
                                                <span className='title'>{e.serie.title}</span>
                                                <span className='subtitle'>{e.serie.subtitle}</span>
                                            </div>

                                            <div className='watches'>
                                                <span className='value'>{formatter(e.view)}</span>
                                                <span className='label'>Watches</span>
                                            </div>
                                            
                                            <div className='completions'>
                                                <span className='value'>{formatter(e.complete)}</span>
                                                <span className='label'>{t('Completions')}</span>
                                            </div>
                                        </div>                                
                                    )
                                }

                                )}
                            </div>
                        </section>

                        <section className='leaderboards'>
                            <span className='title'>{t("Our_champions")}</span>
                            <div className='leaderboards-wrapper'>
                                {stats.leaderboards.map((e, idx) =>
                                    <div key={idx} className={'leaderboard ' + e.classNames}>
                                        <span className='title'>{e.type}</span>
                                        <div className='users-list'>
                                            {e.users.map((f, jdx) =>
                                                <Link key={`${idx}-${jdx}`} to={`/profile/${f.id}`} className='user-item-wrapper'>
                                                    <div className='user-index'>
                                                        <span>{`#${jdx + 1}`}</span>
                                                    </div>
                                                    <div className='info-wrapper'>
                                                        <div className='avatar' style={{ backgroundImage: f.uri }} />
                                                        <div className='infos'>
                                                            <span className='name'>{f.name}</span>
                                                            <span className='score'>{f.score}</span>
                                                        </div>
                                                    </div>
                                                </Link>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </section>
                    </>}
            </div>
        </div>
    );
}

export default Statistics;

const CustomTooltip = ({ active, payload, label }) => {
    const { t } = useTranslation();

    if (active && payload && payload.length) {
        return (
        <div className="custom-tooltip">
            <p className="label">{label}</p>
            <p className="label blue">{`${t('Videos_Watched')} : ${payload[0].value}`}</p>
            <p className="label yellow">{`${t('Quizzes_Taken')} : ${payload[1].value}`}</p>
            <p className="label purple">{`${t('Comments_Posted')} : ${payload[2].value}`}</p>
        </div>
        );
    }

    return null;
};

const Calendar = ({ onChange, value, onClose }) => {
    const [isOpen, setIsOpen] = useState(false);
    const {
        goPrevMonth,
        goNextMonth,
        currentMonth,
        currentYear,
        currentMonthDays,
        goToCurrentMonth,
    } = useCalendar({
        selected: [value.start, value.end],
    });


    const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

    // const handleDateCLick = (day, month, year) => {
    //     let copySelected = [...dates]

    //     const indexMatchingDate = dates.findIndex(
    //         (date) =>
    //             date.getDate() === day &&
    //             date.getMonth() === month &&
    //             date.getFullYear() === year
    //     )

    //     if (indexMatchingDate === -1) {
    //         copySelected.push(new Date(year, month, day))
    //     } else {
    //         copySelected.splice(indexMatchingDate, 1)
    //     }

    //     setDates(copySelected)
    // }


    const onDayClick = (day, month, year) => {

        const selectedDate = new Date(year, month, day)
        if (!moment(value.start).isSame(value.end, 'day')) {
            onChange({ start: selectedDate, end: selectedDate })
            return 
        }

        if (!value.start) {
            onChange({ start: selectedDate, end: undefined })
        } else if (!value.end) {
            onChange({ start: value.start, end: selectedDate })
        } else if (
            value.start.getDate() === day &&
            value.start.getMonth() === month &&
            value.start.getFullYear() === year
        ) {
            onChange({ start: value.start, end: selectedDate })
        } else if (
            value.end.getDate() === day &&
            value.end.getMonth() === month &&
            value.end.getFullYear() === year
        ) {
            onChange({ start: selectedDate, end: value.end })
        } else if (new Date(value.start) > selectedDate) {
            onChange({ start: selectedDate, end: value.start })
        } else {
            onChange({ start: value.start, end: selectedDate })
        }
    };

    return (
        <>
            <div className='dates' onClick={() => setIsOpen(true)}>
                <span>
                    {
                        moment(value.start).isSame(value.end) ?
                            moment(value.start).format('Do MMMM, YYYY') :
                                `${moment(value.start).format('Do MMMM, YYYY')} - ${moment(value.end).format('Do MMMM, YYYY')}`
                    }
                </span>
            </div>

            <div
                className={`calendar-layer${isOpen ? ' visible': ''}`}
                onClick={() => {
                    onClose()
                    setIsOpen(false)
                }}
            />
            <div className={`calendar-window${isOpen ? ' visible': ''}`}>
                <div className='controls'>
                    <div className='back' onClick={goPrevMonth}>
                        <span className='icon-chevronleft' />
                    </div>
                    <div className='month' onClick={goToCurrentMonth}>
                        <span>
                            {`${months[currentMonth]}, ${currentYear}`}
                        </span>
                    </div>
                    <div className='next' onClick={goNextMonth}>
                        <span className='icon-chevronright' />
                    </div>
                </div>
                <div className='separator' />
                <div className='grid'>
                    {[
                        ...days.map((day) =>
                            <span key={`label-${day}`} className='label-day'>{day}</span>
                        ),
                        ...currentMonthDays.prev.map((day, index) =>
                            <div key={`prev-${day}-${index}-${currentMonth}`} />
                        ),
                        ...currentMonthDays.current.map((day, index) => {
                            const cMonth = currentMonth + 1 < 10 ? `0${currentMonth + 1}` : currentMonth + 1
                            const cDay = day.date < 10 ? `0${day.date}` : day.date

                            const current = moment(`${currentYear}-${cMonth}-${cDay}`)
                            const isAfter = moment().diff(current) < 0

                            return (
                                <div
                                    key={`current-${day.date}-${index}-${currentMonth}`}
                                    className={
                                        `day${
                                            day.start ? ' start' : ''
                                        }${
                                            day.included ? ' included' : ''
                                        }${
                                            day.end ? ' end' : ''
                                        }${isAfter ? ' disabled' : ''}`}
                                >
                                    <div className='button' onClick={() => !isAfter && onDayClick(day.date, currentMonth, currentYear)}>
                                        <span>{day.date}</span>
                                    </div>
                                    <div
                                        className={
                                            `layer ${
                                                !!day.start && !day.end ?
                                                    'start' : !day.start && !!day.end ?
                                                        'end' : ''
                                            }`
                                        }
                                    />
                                </div>
                            )
                        }),
                        ...currentMonthDays.next.map((day, index) =>
                            <div key={`next-${day}-${index}-${currentMonth}`} />
                        ),
                    ]}
                </div>
            </div>
        </>
    )
}