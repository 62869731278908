import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import get from "lodash.get";
import { useTranslation } from "react-i18next";

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";

import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";

export default function HomeHero(props) {
  const { heroData } = props;
  const [currentWistiaHashId, setCurrentWistiaHashId] = useState(null);
  const [currentType, setCurrentType] = useState("video");
  const [current, setCurrent] = useState({});
  const [videos, setVideos] = useState([]);
  const [quizzes, setQuizzes] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0); // For mobile version
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", updateInnerWidth);

    return () => window.removeEventListener("resize", updateInnerWidth);
  }, [])

  const updateInnerWidth = () => {
    setInnerWidth(window.innerWidth);
  }

  const [debounceCurrent, setDebounceCurrent] = useState({
    currentWistiaHashId: null,
    currentType: "video",
    current: {},
  })

  const { t } = useTranslation();

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebounceCurrent({
        currentWistiaHashId,
        currentType,
        current: {...(current || {})}
      })
    }, 1000)

    return () => clearTimeout(timer)
  },[currentWistiaHashId, currentType, current])

  useEffect(() => {
    if (currentWistiaHashId == null) {
      setCurrentWistiaHashId(heroData.video);
    }
    setVideos(heroData.recommendedVideos);
    setQuizzes(heroData.recommendedQuizzes);
  }, [currentWistiaHashId, heroData]);

  const [, setSwiper] = useState(null);
  SwiperCore.use([Navigation]);
  const initSwiper = (s) => {
    setSwiper(s);
  };

  return (
    <section className="home-header">
      <div className="viewport">
        <div className="inner">
          <div className="player-wrapper desktop">
            {debounceCurrent?.currentType === "video" ? (
              debounceCurrent?.currentWistiaHashId === heroData.video ? (
                <>
                  <div className="cover"></div>
                  {innerWidth >= 1024 && (
                    <iframe
                      title="hero_embed"
                      src={`//fast.wistia.net/embed/iframe/${debounceCurrent?.currentWistiaHashId}?autoplay=true&playbar=false&playButton=false&settingsControl=false&volumeControl=false&smallPlayButton=false&fullscreenButton=false&silentAutoPlay=allow&smallPlayButton=false`}
                      className="video-home"
                      name="hero_embed"
                    ></iframe>
                  )}
                </>
              ) : (
                <Link to={`/video?id=${debounceCurrent?.current?.id}`}>
                  {get(debounceCurrent?.current, "videoThumbnail.uri", "") !== "" && (
                    <div
                      className="video-home"
                      style={{
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        backgroundImage:
                          get(debounceCurrent?.current, "videoThumbnail.uri", "") !== ""
                            ? `url(${
                                process.env.REACT_APP_IMG_URL
                              }/storage/video-thumbnail/${get(
                                debounceCurrent?.current,
                                "videoThumbnail.uri",
                                ""
                              )})`
                            : 'url("/assets/images/video-1.jpg")',
                      }}
                    ></div>
                  )}
                  {get(debounceCurrent?.current, "videoThumbnail.uri", "") === "" && (
                    <iframe
                      title="hero_embed_video"
                      src={`//fast.wistia.net/embed/iframe/${debounceCurrent?.currentWistiaHashId}?autoplay=false&playbar=false&playButton=false&settingsControl=false&volumeControl=false&smallPlayButton=false&fullscreenButton=false&silentAutoPlay=allow&smallPlayButton=false`}
                      className="video-home"
                      name="hero_embed"
                    ></iframe>
                  )}
                  <div className="cover"></div>
                  <div className="play-button">
                    <div className="icon">
                      <span className="icon-playbuttonbold"></span>
                    </div>
                    <div className="text">{t("Play_Episode")}</div>
                  </div>
                </Link>
              )
            ) : (
              <Link to={`/quiz?id=${debounceCurrent?.current?.id}`}>
                {get(debounceCurrent?.current, "thumbnail.uri", "") !== "" && (
                  <div
                    className="video-home"
                    style={{
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      backgroundImage:
                        get(debounceCurrent?.current, "thumbnail.uri", "") !== ""
                          ? `url(${
                              process.env.REACT_APP_IMG_URL
                            }/storage/quiz-thumbnail/${get(
                              debounceCurrent?.current,
                              "thumbnail.uri",
                              ""
                            )})`
                          : 'url("/assets/images/video-1.jpg")',
                    }}
                  ></div>
                )}
                <div className="cover"></div>
                <div className="play-button quiz">
                  <img
                    alt=""
                    className="icon-quiz"
                    src="/assets/icons-svg/quiz-time-logo-round.svg"
                  ></img>
                  <div className="text">{t("Start_Quiz")}</div>
                </div>
              </Link>
            )}
          </div>
          <Swiper
            className="player-wrapper mobile"
            onSwiper={(swiper) => initSwiper(swiper)}
            spaceBetween={8}
            navigation={{
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            }}
            onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)}
          >
            <SwiperSlide key="welcome-video">
              <div className="media-container">
                {/* <div className="cover"></div> */}
                {innerWidth < 1024 && activeIndex === 0 && (
                  <iframe
                    title="hero_embed_mobile"
                    src={`//fast.wistia.net/embed/iframe/${heroData.video}?autoplay=true&playbar=false&playButton=false&settingsControl=false&volumeControl=false&smallPlayButton=false&fullscreenButton=false&silentAutoPlay=allow&smallPlayButton=false`}
                    className="video-home"
                    name="hero_embed"
                  ></iframe>
                )}
              </div>
            </SwiperSlide>
            {videos?.map((v, i) => (
              <SwiperSlide key={v.id}>
                <Link
                  to={`/video?id=${v.id}`}
                  style={{ color: "#d2d2d2", textDecoration: "none" }}
                >
                  <div className="media-container">
                    {get(v, "videoThumbnail.uri", "") !== "" && (
                      <div
                        className="video-home"
                        style={{
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                          backgroundImage:
                            get(v, "videoThumbnail.uri", "") !== ""
                              ? `url(${
                                  process.env.REACT_APP_IMG_URL
                                }/storage/video-thumbnail/${get(
                                  v,
                                  "videoThumbnail.uri",
                                  ""
                                )})`
                              : 'url("/assets/images/video-1.jpg")',
                        }}
                      ></div>
                    )}
                    {get(v, "videoThumbnail.uri", "") === "" && (
                      <iframe
                        title="hero_embed_video"
                        src={`//fast.wistia.net/embed/iframe/${v.wistiaHashId}?autoplay=false&playbar=false&playButton=false&settingsControl=false&volumeControl=false&smallPlayButton=false&fullscreenButton=false&silentAutoPlay=allow&smallPlayButton=false`}
                        className="video-home"
                        name="hero_embed"
                      ></iframe>
                    )}
                    <div className="cover"></div>
                    <div className="play-button">
                      <div className="icon">
                        <span className="icon-playbuttonbold"></span>
                      </div>
                      <div className="text">{t("Play_Episode")}</div>
                    </div>
                  </div>
                </Link>
              </SwiperSlide>
            ))}
            {quizzes?.map((v, i) => (
              <SwiperSlide key={v.id}>
                <Link
                  to={`/quiz?id=${v.id}`}
                  style={{ color: "#d2d2d2", textDecoration: "none" }}
                >
                  <div className="media-container">
                    {get(v, "thumbnail.uri", "") !== "" && (
                      <div
                        className="video-home"
                        style={{
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                          backgroundImage:
                            get(v, "thumbnail.uri", "") !== ""
                              ? `url(${
                                  process.env.REACT_APP_IMG_URL
                                }/storage/quiz-thumbnail/${get(
                                  v,
                                  "thumbnail.uri",
                                  ""
                                )})`
                              : 'url("/assets/images/video-1.jpg")',
                        }}
                      ></div>
                    )}
                    <div className="cover"></div>
                    <div className="play-button quiz">
                      <img
                        alt=""
                        className="icon-quiz"
                        src="/assets/images/quiztime.png"
                      ></img>
                      <div className="text">{t("Start_Quiz")}</div>
                    </div>
                  </div>
                </Link>
              </SwiperSlide>
            ))}
            <div className="swiper-button-prev swiper-button-disabled"></div>
            <div className="swiper-button-next"></div>
          </Swiper>

          {/* Options for desktop */}
          <div className="option-wrapper">
            <div
              className={
                "welcome desktop" +
                (currentWistiaHashId === heroData.video ? " active" : "")
              }
              onClick={(e) => {
                setCurrentWistiaHashId(heroData.video);
                setCurrent({});
              }}
              onMouseEnter={(e) => {
                setCurrentWistiaHashId(heroData.video);
                setCurrent({});
              }}
            >
              <img alt="" src={"/assets/images/welcome-back-active.png"}></img>
            </div>
            <div
              className="welcome mobile"
              onClick={(e) => {
                setCurrentWistiaHashId(heroData.video);
                setCurrent({});
                setActiveIndex(0);
              }}
            >
              <img
                alt=""
                src={
                  "/assets/images/welcome-back-mini-" +
                  (activeIndex === 0 ? "active.png" : "inactive.png")
                }
              ></img>
            </div>
            <div
              onMouseLeave={() => {
                setCurrentWistiaHashId(heroData.video);
                setCurrent({});
                setCurrentType("video");
              }}
              className="recommendation desktop"
            >
              <div className="categories">
                <h3>{t("My_Episodes")}</h3>

                {videos?.map((v) => (
                  <Link
                    className={
                      "category" +
                      (current === v ? " active " + v.categoryShorthand : "")
                    }
                    to={`/video?id=${v.id}`}
                  >
                    <div
                      className="category-wrapper"
                      onMouseEnter={(e) => {
                        setCurrentType("video");
                        setCurrent(v);
                        setCurrentWistiaHashId(v.wistiaHashId);
                      }}
                    >
                      <div className={"category__name " + v.categoryShorthand}>
                        {v.categoryName}
                      </div>
                      {v.isSustainability && (
                        <div className="sustainability-tag-wrapper">
                          <span className="icon-sustainability"></span>
                          <span className="text-sustainability">
                            sustainability
                          </span>
                        </div>
                      )}
                    </div>
                    <div
                      className={
                        "title" +
                        (current === v ? " " + v.categoryShorthand : "")
                      }
                    >
                      {v.title}
                    </div>
                  </Link>
                ))}
              </div>
              <div className="categories-divider" />
              <div className="categories">
                <h3>{t("My_Quizzes_Title")}</h3>

                {quizzes?.map((q) => (
                  <Link
                    className={
                      "category" +
                      (current === q ? " active " + q.categoryShorthand : "")
                    }
                    to={`/quiz?id=${q.id}`}
                  >
                    <div
                      onMouseEnter={(e) => {
                        setCurrent(q);
                        setCurrentType("quiz");
                        setCurrentWistiaHashId("");
                      }}
                    >
                      <div className={"category__name " + q.categoryShorthand}>
                        {q.categoryName}
                      </div>
                      <div
                        className={
                          "title" +
                          (current === q ? " " + q.categoryShorthand : "")
                        }
                      >
                        {q.title}
                      </div>
                    </div>
                  </Link>
                ))}
              </div>
            </div>
            <div className="recommendation mobile">
              <div className="categories">
                {videos?.map((v) => (
                  <Link
                    className={
                      "category" +
                      (activeIndex === v.index
                        ? " active " + v.categoryShorthand
                        : "")
                    }
                    to={`/video?id=${v.id}`}
                  ></Link>
                ))}
              </div>
              <div className="categories">
                {quizzes?.map((q) => (
                  <Link
                    className={
                      "category" +
                      (activeIndex === q.index
                        ? " active " + q.categoryShorthand
                        : "")
                    }
                    to={`/quiz?id=${q.id}`}
                  ></Link>
                ))}
              </div>
            </div>
          </div>

          {/* Options for mobile */}
          <div className="option-wrapper mobile">
            <div className="welcome">
              {activeIndex === 0 && (
                <img
                  alt=""
                  src="/assets/images/welcome-back-text-active.png"
                ></img>
              )}

              {activeIndex !== 0 &&
                videos?.map((v) => (
                  <div
                    className={"category " + v.categoryShorthand}
                    style={{
                      display: activeIndex === v.index ? "block" : "none",
                    }}
                  >
                    <div className="category__name">{v.categoryName}</div>
                    <div className="title">{v.title}</div>
                    {v.isSustainability && (
                      <div className="sustainability-tag-wrapper">
                        <span className="icon-sustainability"></span>
                        <span className="text-sustainability">
                          sustainability
                        </span>
                      </div>
                    )}
                  </div>
                ))}
              {activeIndex !== 0 &&
                quizzes?.map((q) => (
                  <div
                    className={"category " + q.categoryShorthand}
                    style={{
                      display: activeIndex === q.index ? "block" : "none",
                    }}
                  >
                    <div className="category__name">{q.categoryName}</div>
                    <div className="title">{q.title}</div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
