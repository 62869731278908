import React from "react";
const CheckMark = ({ checked }) => {
  if (checked) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <mask
          id="mask0_2578_3283"
          style={{
            maskType: "alpha"
          }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="24"
          height="24"
        >
          <rect width="24" height="24" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_2578_3283)">
          <path
            d="M10 16.4L6 12.4L7.4 11L10 13.6L16.6 7L18 8.4L10 16.4Z"
            fill="#FFEA32"
          />
        </g>
      </svg>
    );
  } else {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <mask
          id="mask0_2601_3291"
          style={{
            maskType: "alpha"
          }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="24"
          height="24"
        >
          <rect width="24" height="24" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_2601_3291)">
          <path
            d="M12 18C10.3358 18 8.91983 17.416 7.752 16.248C6.584 15.0802 6 13.6642 6 12C6 10.3358 6.584 8.91983 7.752 7.752C8.91983 6.584 10.3358 6 12 6C13.6642 6 15.0802 6.584 16.248 7.752C17.416 8.91983 18 10.3358 18 12C18 13.6642 17.416 15.0802 16.248 16.248C15.0802 17.416 13.6642 18 12 18ZM12 17C13.3833 17 14.5625 16.5125 15.5375 15.5375C16.5125 14.5625 17 13.3833 17 12C17 10.6167 16.5125 9.4375 15.5375 8.4625C14.5625 7.4875 13.3833 7 12 7C10.6167 7 9.4375 7.4875 8.4625 8.4625C7.4875 9.4375 7 10.6167 7 12C7 13.3833 7.4875 14.5625 8.4625 15.5375C9.4375 16.5125 10.6167 17 12 17Z"
            fill="white"
          />
        </g>
      </svg>
    );
  }
};

export default CheckMark;