export default {
  // Menu
  Home: "หน้าหลัก",
  FNB: "อาหารและเครื่องดื่ม",
  FNB_Blank: "อาหารและเครื่องดื่ม",
  OPSHR: "ฝ่ายดำเนินการ | ฝ่ายบุคคลากร",
  OPSHR_Blank: "ฝ่ายดำเนินการ | ฝ่ายบุคคลากร",
  SNM: "ฝ่ายการขายและการตลาด",
  SNM_Blank: "ฝ่ายการขายและการตลาด",
  Search: "ค้นหา",
  Search_Place_Holder: "ซีรีส์, แบบทดสอบ, วิทยากร",
  Series: "ซีรีส์",
  Quizzes: "ควizzes",
  Speakers: "วิทยากร",
  Speaker: "วิทยากร",
  Leaderboard: "กระดานผู้นำ",
  My_Profile: "โปรไฟล์ของฉัน",
  My_Lists: "รายการของฉัน",
  My_Quizzes: "แบบทดสอบ ของฉัน",
  Log_Out: "ออกจากระบบ",
  VIEW_ALL_IN_DIRECTORY: "ดูทั้งหมดในไดเรกทอรี",

  // Home page
  WELCOME_BACK: "ยินดีต้อนรับกลับมากอีกครั้ง!",
  RECOMMENDED_FOR_YOU: "แนะนำสำหรับคุณ",
  Episodes: "ตอน",
  My_Episodes: "แบบทดสอบของฉัน",
  My_Quizzes_Title: "แบบทดสอบ ของฉัน",
  INSPIRATION: "แรงบันดาลใจ",
  DIRECTION: "ทิศทาง",
  EXCELLENCE: "ความเป็นเลิศ",
  APPLICATION: "การนำไปใช้",
  SOLUTION: "วิธีแก้ปัญหา",
  Home_Page_Content:
    "I.D.E.A.S 2023 คืออะไร? เรียนรู้และได้รับแรงบันดาลใจจากผู้นำกว่า 50 คนกับวิดีโอสั้นๆ ที่ง่ายต่อการรับชมในทุกๆ วันจันทร์ มีการสตรีมทุกที่ทุกเวลา ตามจังหวะของคุณเอง เครื่องมือ Edutainment (ความบันเทิงที่เรียนรู้) พร้อมกับแบบทดสอบเชิงโต้ตอบเพื่อช่วยคุณบริหารห้องพัก ร้านอาหาร และบาร์ การขายเทคนิค แนวทางดิจิทัลและการสื่อสาร การแก้ปัญหาในการขาย วางแผน และดำเนินงานกิจกรรม ให้กลายเป็นผู้นำที่ดีกว่าและดูแลทีมของเรา มอบให้กับคณะกรรมการผู้นำของโรงแรม Hyatt Asia Pacific และทีมงานที่เกี่ยวข้อง",
  Countries: "ประเทศ",
  Markets: "ตลาด",
  Hotels: "โรงแรม",
  Users: "ผู้ใช้",
  We_Love_Mondays: "เรารัก❤️วันจันทร์",
  Viewed: "ดูแล้ว",
  Views: "จำนวนการดู",
  Load_More: "โหลดเพิ่มเติม",
  Explore: "สำรวจ",
  Trending_Series: "ซีรีส์ยอดนิยม",
  Dig_Use_Share_Anywhere_Anytime: "ค้นหา, ใช้งาน และแบ่งปัน\nทุกที่ ทุกเวลา",
  With: "กับ",
  Videos: "วิดีโอ",
  Trending_Speakers: "วิทยากรที่กำลังมาแรง",
  Discover_Series: "ค้นหาซีรีส์",
  Have_Suggestions_For_Improvements: "มีข้อเสนอแนะสำหรับการปรับปรุงหรือไม่?",
  Give_Us_Your_Ideas: "บอกไอเดียของคุณกับเรา",
  Terms_And_Conditions: "ข้อตกลงและเงื่อนไข",
  Focus: "โฟกัส",
  Filter_By: "กรองโดย",

  // EXPLORE ALL
  EXPLORE_THE_LIBRARY: "สำรวจคลังความรู้",
  Search_By_Theme_Topi_Or_Speaker: "ค้นหาตามธีม, หัวข้อ หรือ วิทยากร",
  SERIES: "ซีรีส์",
  EPISODES: "ตอน",
  QUIZZES: "แบบทดสอบ",
  QUIZ: "แบบทดสอบ",
  Watch_The_Series: "ดูซีรีส์",
  START: "เริ่ม",
  RETAKE: "ทำใหม่",
  REVIEW: "ทบทวน",
  CONTINUE: "ดำเนินการต่อ",
  CERTIFICATE: "ใบประกาศนียบัตร",
  passed: "ผ่าน",
  failed: "ไม่ผ่าน",
  inProgress: "กำลังดำเนินการ",
  notStarted: "ยังไม่ได้เริ่ม",
  Discover_More: "ค้นหาเพิ่มเติม",
  All: "ทั้งหมด",
  Loading: "กำลังโหลด...",
  Search_For_Series: "ค้นหาซีรีส์",
  Latest_Series: "ซีรีส์ล่าสุด",
  All_Series: "ซีรีส์ทั้งหมด",
  View_Details: "ดูรายละเอียด",
  Watch: "ดู",
  XEpisodes: " ตอน",
  XEpisode: " ตอนต่างๆ",
  Ready_For_A_Quiz: "พร้อมสำหรับควizzes เพื่อเพิ่มคะแนนหรือไม่?",
  Start: "เริ่ม",
  Sort_By: "เรียงลำดับตาม",
  Default: "เริ่มต้น",
  Latest: "ล่าสุด",
  Earliest: "เก่าสุด",
  Most_Viewed: "ดูมากที่สุด",
  All_Episodes: "ตอนทั้งหมด",
  Search_For_Quizzes: "ค้นหาแบบสอบถาม",
  Recommended_For_You: "แนะนำสำหรับคุณ",
  All_Quizzes: "แบบทดสอบทั้งหมด",
  Passed: "ผ่าน",
  Failed: "ไม่ผ่าน",
  Re_take: "ทำใหม่",
  // EPISODE PAGE
  Related_Series: "ซีรี่ส์ที่เกี่ยวข้อง",
  Share_Video: "แชร์วิดีโอ",
  Watch_Later: "ดูทีหลัง",
  XView: " การดู",
  XViews: " การดู",
  Released_On: "ปล่อยเมื่อ {{date}}",
  Discussion: "การสนทนา",
  XComments: "แบบทดสอบที่ผ่าน",
  XComment: " ความคิดเห็น",
  Reply: "ตอบกลับ",
  Enter_Your_Comment_Here: "กรอกความคิดเห็นของคุณที่นี่",
  Cancel: "ยกเลิก",
  Submit: "ส่ง",
  Back_To_Top: "กลับไปที่ด้านบน",
  Back: "กลับ",

  // LEADERBOARD
  Weekly_Leaderboard: "กระดานผู้นำประจำสัปดาห์",
  Expert_Viewer: "ผู้เชี่ยวชาญที่รับชม",
  Biggest_Viewed: "ดูตอนมากที่สุดในสัปดาห์ที่ผ่านมา",
  Fastest_Viewer: "ผู้ชมที่เร็วที่สุด",
  Woke_Up_Earilest: "ตื่นเช้าเพื่อเป็นคนแรกที่ได้ดูในประเทศของคุณ",
  Star_Contributor: "ผู้ร่วมสนับสนุนดาว",
  Posted_Greatest: "โพสต์ความคิดเห็นและได้รับความรักและความสนใจมากที่สุด",

  // PROFILE PAGE
  EDIT_PROFILE: "แก้ไขโปรไฟล์",
  SHOW_HISTORY: "แสดงประวัติทั้งหมด",
  MY_QUIZ_HISTORY: "ประวัติแบบทดสอบของฉัน",
  RE_TAKE: "ทำใหม่",
  BADGES: "เหรียญตรา",
  PERFORMANCE: "ประสิทธิภาพ",
  Pts: "คะแนน",
  PROGRESS: "ความคืบหน้า",
  MY_ACTIVITY: "กิจกรรมของฉัน",
  VIDEOS: "วิดีโอ",
  QUIZZES_TAKEN: "แบบทดสอบที่ทำ",
  QUIZZES_PASSED: "แบบทดสอบที่ผ่าน",
  COMMENTS: "แบบทดสอบที่ผ่าน",
  Videos_Watched: "วิดีโอที่ดูแล้ว",
  Quizzes_Taken: "แบบทดสอบที่ทำแล้ว",
  Comments: "แบบทดสอบที่ผ่านแล้ว",
  Week_1: "สัปดาห์ที่ 1",
  Week_2: "สัปดาห์ที่ 2",
  Week_3: "สัปดาห์ที่ 3",
  Week_4: "สัปดาห์ที่ 4",
  Week_5: "สัปดาห์ที่ 5",

  // NOTIFICATION
  Notification: "การแจ้งเตือน",
  Discover: "ค้นพบ",
  Take_The_Quiz: "ทำแบบทดสอบ",

  // MY LISTS
  WATCH_LATER: "ดูทีหลัง",
  XVideo: "วิดีโอ",
  XVideos: "วิดีโอ",
  VIEWED: "ดูแล้ว",
  Yesterday: "เมื่อวาน",
  Clear: "ลบ",
  Watc_Again: "ดูอีกครั้ง",
  LIKED: "ชอบ",
  LIKED_VIDEOS: "วิดีโอที่ถูกใจ",
  No_Result_For_Your_Search: "ไม่พบผลลัพธ์จากการค้นหาของคุณ",

  // QUIZ RESULT
  Your_Score_Is: "คะแนนของคุณคือ…",
  Retake: "ทำใหม่",
  Dont_Worry:
    "อย่ากังวล มาดูกันว่ามีอะไรผิดพลาดและเข้าใจคำถามเพื่อหาคำตอบที่ถูกต้อง",
  Help_Me_Pass: "ช่วยฉันผ่าน",
  Almost_There: "เกือบถึงแล้ว!",
  Help_Description: `<1>คุณตอบถูก {{correctCount}} ข้อแล้ว</1> <2>ด้านล่างคือลำดับคำถามที่คุณพลาด:</2>`,
  Find_The_Correct_Answer:
    "ค้นหาคำตอบที่ถูกต้องในตอน <1>{{relatedEpisode}}</1>",
  Learn_From_Series: "เรียนรู้จากซีรีส์",
  Quizz_Welcome: `<p>คุณจำสิ่งที่สำคัญที่สุดเกี่ยวกับซีรีส์ {{title}} ได้ไหม?</p> <p>ทดสอบความรู้ของคุณด้วยแบบทดสอบเชิงโต้ตอบนี้ และทำคะแนนให้ได้ 80/100 หรือมากกว่า...</p><p>ออย่ากังวลหากคุณทำไม่ผ่านครั้งแรก คุณสามารถทำใหม่จนกว่าจะผ่าน</p>`,
  XQuestions: "คำถาม",
  // Lost

  // Menu
  Explore_All: "สำรวจทั้งหมด",
  No_Result_Found: "ไม่พบผลลัพธ์.",
  Take_The_Quizz: "ทำแบบทดสอบ",

  // Home page
  Play_Episode: "เล่นตอนนี้",
  Start_Quiz: "เริ่มแบบทดสอบ",
  Visit_Now: "เยี่ยมชมตอนนี้",
  Certificates: "ใบประกาศนียบัตรรับรอง",

  // Profile page
  Comments_Posted: "ความคิดเห็นที่โพสต์",
  January: "ม.ค.",
  February: "ก.พ.",
  March: "มี.ค.",
  April: "เม.ย.",
  May: "พ.ค.",
  June: "มิ.ย.",
  July: "ก.ค.",
  August: "ส.ค.",
  September: "ก.ย.",
  October: "ต.ค.",
  November: "พ.ย.",
  December: "ธ.ค.",
  DOWNLOAD_ALL_CERTIFICATES: "ดาวน์โหลดใบประกาศนียบัตรทั้งหมด",
  // My List page
  Today: "วันนี้",
  A_Week_Ago: "หนึ่งสัปดาห์ที่แล้ว",
  A_Month_Ago: "หนึ่งเดือนที่แล้ว",

  // Quizz page
  There_Are_More: "ยังมีคำตอบที่ถูกต้องอีกมุมมอง ค้นหาดูใหม่.",
  Not_Be_Found: "ไม่พบทรัพยากรที่ร้องขอ",
  Check_URL: "กรุณาตรวจสอบ URL และลองอีกครั้ง",

  // Sustainability page
  sustainability: "ความยั่งยืน",
  sustainability_solgan:
    "การลดขยะอาหาร, การกำจัดพลาสติก, การดำเนินการด้านสิ่งแวดล้อม",
  sustainability_description:
    "สภาพแวดล้อมที่ดีมีความสำคัญ <br/> สำหรับทุกคนในการทำให้ดีที่สุด",

  // Update
  Hotel_Statistics: "สถิติของโรงแรม",
  Directory: "ไดเร็กทอรี",
  "Welcome_to_I.D.E.A.S":
    "ยินดีต้อนรับสู่ I.D.E.A.S 2024! เสริมทักษะของคุณด้วยวิดีโอคลาส 5 นาทีจากผู้นำในวงการโรงแรมมากกว่า 60 คน ที่เผยแพร่ทุกวันจันทร์ เรียนรู้ได้ตามสะดวกทุกที่ทุกเวลา ในจังหวะของคุณเอง แพลตฟอร์มที่น่าสนใจของเรามีแบบทดสอบเชิงโต้ตอบพร้อมการรับรองที่ได้รับการรับรองและข้อมูลเชิงลึกที่จะช่วยเพิ่มทักษะการจัดการห้องพัก ร้านอาหาร บาร์ และกิจกรรมของคุณ เรียนรู้เทคนิคการขายโรงแรมที่มีประสิทธิภาพ กลยุทธ์ดิจิทัล และแนวทางการสื่อสารที่ดีที่สุด เรียนรู้วิธีการขาย การวางแผน และการดำเนินการกิจกรรมอย่างมีประสิทธิภาพ เติบโตเป็นผู้นำที่ดีขึ้นและดูแลทีมของคุณ โดยออกแบบมาสำหรับคณะกรรมการผู้นำและทีมงานของโรงแรม Hyatt Asia Pacific ทุกแห่ง",
  HYATT: "HYATT",
  PHOTOGRAPHY: "การถ่ายภาพ",
  "Dig_Use_&_Share": "ค้นหา, ใช้งาน และแบ่งปัน",
  Anywhere_Anytime: "ทุกที่ ทุกเวลา",
  MoodBoard: "ไอเดียบอร์ด",
  "GLOBAL_HYATT_F&B_PHOTOGRAPHY": "HYATT GLOBAL EVENTS + F&B LIBRARY",
  Leaderboard_Badges: "ป้ายกระดานผู้นำ",
  Show_Full_History: "แสดงประวัติทั้งหมด",
  "Average_of_users_have_watched...": "เฉลี่ยของผู้ใช้ที่ได้ดู",
  "...videos": "วิดีโอ",
  "Average_of_users_have_taken...": "เฉลี่ยของผู้ใช้ที่ได้ทำ",
  "...number_of_quizzes": "แบบทดสอบ",
  "Average_of_users_have_passed...": "เฉลี่ยของผู้ใช้ที่ผ่าน",
  "...quizzes": "แบบทดสอบ",
  Image_upload: "อัปโหลดภาพ",
  Enter_your_comment_here: "กรอกความคิดเห็นของคุณที่นี่",
  Series_summary: "สรุปซีรีส์",
  Download: "ดาวน์โหลด",
  Completions: "การทำสำเร็จ",
  view: "ดู",
  views: "จำนวนการดู",
  point: "คะแนน",
  points: "คะแนน",
  Recipe: "สูตรอาหาร",
  Recipes: "สูตรอาหาร",
  Search_for_recipes: "ค้นหาสูตรอาหาร",
  "Our_mission_is_to...":
    "ภารกิจของเราคือการคัดสรรคอลเลกชันภาพที่น่าตื่นตาตื่นใจและหลากหลาย ซึ่งจะเล่าเรื่องราวทางการทำอาหารในแต่ละโรงแรม ที่นี่คุณจะพบแรงบันดาลใจและการสนับสนุนในการสร้างสรรค์ผลงานของตัวเอง พร้อมทั้งสะท้อนถึงจิตวิญญาณแห่งนวัตกรรมในบริการอาหารและเครื่องดื่มของ ไฮแอท และแสดงให้เห็นถึงความเป็นเลิศด้านอาหารและเครื่องดื่มของคุณไปพร้อมกัน",
  "All_of_the_images...":
    "ภาพทั้งหมดในคอลเลกชันของไฮแอทพร้อมลิขสิทธิ์ของ ภาพเหล่านี้สามารถใช้งานได้เฉพาะสำหรับโรงแรมไฮแอท ทุกแห่งทั่วโลก เพื่อเข้าถึงเวอร์ชันความละเอียดสูง กรุณาเข้าสู่ระบบผ่าน Brand Manager แพลตฟอร์ม หรือคลิกที่นี่",
  here: "ที่นี่",
  "...platform_or_by_clicking": "",
  HYATT_GLOBAL: "ไฮแอท โกลบอล",
  "EVENTS+F&B": "กิจกรรม + แกลอรี่ภา",
  LIBRARY: "พงานจัดเลี้ยง",
  No_result_for_your_search: "ไม่พบผลลัพธ์จากการค้นหาของคุณ",

  "like.star": "ว้าว",
  "like.like": "ยกนิ้วให้",
  "like.heart": "รัก",
  "like.idea": "เฉียบแหลม",

  Well_done: "เยี่ยมมาก",
  quiz_invite:
    "คุณได้ดูวิดีโอทั้งหมดของ {{name}} แล้ว คุณอยากทำแบบทดสอบตอนนี้เพื่อทดสอบความรู้ของคุณไหม?",

  Taken_on: "ทำเมื่อ",
  Top_10: "10 อันดับแรก",
  See_all: "ดูทั้งหมด",

  FNB_FULL_NAME: "อาหารและเครื่องดื่ม",
  OPSHR_FULL_NAME: "ฝ่ายดำเนินการ | ฝ่ายบุคคลากร",
  SNM_FULL_NAME: "ฝ่ายการขายและการตลาด",

  Empty_series_result: "ไม่พบซีรีส์",
  New_tag: "ใหม่ล่าสุด",

  Other_series_by_this_speaker: "ซีรีส์โดยผู้พูดท่านนี้",

  REACTED: "แสดงความเห็น",
  You_Reacted: "คุณถูกใจแล้ว",
  You_plus_x_others: "คุณและ {{count}} คนอื่นๆ",

  quiz_description_line1:
    "ทดสอบความรู้ของคุณด้วยแบบทดสอบเชิงโต้ตอบนี้ และทำคะแนนให้ได้ 80/100 หรือมากกว่า...",
  quiz_description_line2:
    "อย่ากังวลหากคุณทำไม่ผ่านครั้งแรก คุณสามารถทำใหม่จนกว่าจะผ่าน",

  XSeries: "ซีรีส์",
  XSerie: "ซีรีส์",

  total_points: "คะแนนรวม",
  download_all_certificates: "ดาวน์โหลดใบประกาศนียบัตรทั้งหมด",

  comment_liked: "ถูกใจใหม่",
  comment_replied: "ตอบกลับใหม่",
  award_obtained: "ขอแสดงความยินดี",
  new_quiz: "แบบทดสอบใหม่ออนไลน์!",
  new_leaderboard: "กระดานผู้นำ",

  image_comment_hint: "ความคิดเห็นที่มีรูปภาพต้องมีขนาดไม่เกิน 30MB",

  Edit: "แก้ไข",
  Save: "บันทึก",
  Delete: "ลบ",

  Started_at: "เริ่มต้นที่",
  React: "ถูกใจ",

  Thank_you_for_your_comment: "ขอบคุณสำหรับความคิดเห็นของคุณ",
  Your_comment_has_been_added: "ความคิดเห็นของคุณได้ถูกเพิ่มแล้ว",
  Back_to_IDEAS: "กลับสู่ I.D.E.A.S.",

  Please_confirm: "กรุณายืนยัน",
  Are_you_sure_you_want_to_delete_your_comment: "คุณแน่ใจหรือไม่ว่าต้องการลบความคิดเห็นของคุณ?",

  Comment_removed: "ความคิดเห็นถูกลบ",
  Your_comment_has_been_removed: "ความคิดเห็นของคุณถูกลบ",

  Thank_you: "ขอบคุณ",
  Your_comment_changes_have_been_saved: "การเปลี่ยนแปลงความคิดเห็นของคุณได้รับการบันทึกแล้ว",

  Our_champions: "แชมป์ของเรา",
  Total_users: "ผู้ใช้ทั้งหมด",

  Quiz: "แบบทดสอบ",
  Video_views: "การดูวิดีโอ",

  Edit_your_profile: "แก้ไขโปรไฟล์ของคุณ",
  Upload_image: "อัปโหลดภาพ",
  Upload_image_hint: "(ขีดจำกัด 20MB)",
  Name: "ชื่อ",
  Surname: "นามสกุล",
  Country_Region: "ประเทศ/ภูมิภาค",
  City: "เมือง",
  Brand: "แบรนด์",
  Hotel: "โรงแรม",

  Search_a_series: "ค้นหาซีรีส์",
};
