import React, { useEffect } from 'react';
import { sendVideoView, sendVideoHalfViewed } from '../../../utils/data';
import { parseCategoryID } from '../../../utils';
import { withRouter } from 'react-router';
import { finishSeries } from "../../../utils/animation";

const VideoEmbed = (props) => {
  const { id, apiId, category, series, quiz, onPopup } = props;

  useEffect(() => {
    if (id) {
      let currentOrder = 0;
      const videoFromSeries = series.filter((sv) => sv.video.id === apiId)[0];
      if (videoFromSeries) {
        currentOrder = videoFromSeries.order;
      }
      if (!window._wq) return
      window._wq.push({
        id,
        options: {
          endVideoBehavior: 'default',
          playerColor: parseCategoryID(category.id).categoryColor,
        },
        onHasData: function (video) {
          video.play();
          video.volume(1);
          video.bind('crosstime', 5, function () {
            sendVideoView(apiId);
          });
          video.bind('crosstime', video.data.media.duration / 2, function () {
            sendVideoHalfViewed(apiId);
          });
          video.bind('end', function () {
            let nextOrder = null;
            let nextPosition = null;
            let completed = true;
            for (const i in series) {
              if (series[i].order > currentOrder && (nextOrder === null || series[i].order < nextOrder)) {
                nextOrder = series[i].order;
                nextPosition = i;
              }
              if(!series[i]?.video?.userComplete && series[i].order !== currentOrder){
                completed = false;
              }
            }

            if(completed && quiz){
              finishSeries();
              onPopup(quiz);
              return;
            }

            if (nextPosition) {
              props.history.push(`/video?id=${series[nextPosition].video.id}`);
            }else {
              props.history.push(`/video?id=${series[0].video.id}`);
            }
          });
        },
      });
    }
    return () => {
      if (window.Wistia) {
        window.Wistia.trigger('ev1destroystart')
        window.Wistia._destructors.destroyAsyncEmbeds();
        window.Wistia._destructors.destroyEmbedShepherd();
        window.Wistia._destructors.destroyEmbedLinks();
        window.Wistia._destructors.destroyFullscreenTriggers();
        window.Wistia._destructors.destroyEventLoop();
        window.Wistia._destructors.destroyWLog();
        window.Wistia._destructors.destroyMux();
        window.Wistia.trigger('ev1destroyend')
        window.Wistia.initializeOnce();
        window.Wistia.initializeEveryTime();
      }
    }
  }, [apiId]);

  return (
    <div className="video-wrapper">
      {id && <div key={apiId} className={`wistia_embed wistia_async_${id} video-home`} />}
    </div>
  );
};

export default withRouter(VideoEmbed);
