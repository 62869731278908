import React, { useEffect, useState } from "react";
import CheckMark from "../CheckMark";
import { Link } from "react-router-dom";
import moment from "moment";

import "./index.scss";
import { useTranslation } from "react-i18next";
import Download from "../Download";
import { downloadCertificate } from "../../../utils/data";

const SeriesDropDown = ({ serie, index, total, order, category }) => {
  const [expand, setExpand] = useState(false);
  const [complateRate, setCompleteRate] = useState(0);
  const { t } = useTranslation();

  useEffect(() => {
    if (serie?.videos?.length) {
      const rate =
        (serie?.videos?.filter((video) => video?.video?.userComplete)?.length /
          serie?.videos?.length) *
        100;
      setCompleteRate(rate);
    } else {
      setCompleteRate(100);
    }
  }, [serie]);

  const handleDownloadCertificate = async (id) => {
    const res = await downloadCertificate(id);
  };

  return (
    <div key={serie.id} className={`item${expand ? " active" : ""}`}>
      <div
        className={`title${category ? ` ${category}` : ""}`}
        onClick={() => setExpand((val) => !val)}
      >
        <div className="process-bar">
          <div
            className="process"
            style={{
              width: `${complateRate}%`,
            }}
          />
        </div>
        <div className="info-wrapper">
          <div className="info">
            <div className="name">{`${
              order === "earliest" ? index + 1 : total - index
            }. ${serie.title}`}</div>
            <div className="total">{`${serie.videos?.length} ${
              serie.videos?.length > 1 ? t("XEpisodes") : t("XEpisode")
            }`}</div>
          </div>
          <div className="icon">
            <span className="icon-chevronright2" />
          </div>
        </div>
      </div>
      <div className="episodes">
        {serie.videos
          ?.sort((a, b) => a.order - b.order)
          .map((episode, jndex) => (
            <Link
              key={`${index}-${jndex}`}
              to={`/video?id=${episode.video.id}`}
              className={`episode${
                episode.video.userComplete ? " watched" : ""
              }`}
            >
              <EpisodeItem episode={episode} index={jndex + 1} />
            </Link>
          ))}
        {Boolean(serie?.quiz) && serie?.allComplete && (
          <div className="quiz">
            <div className={`tag ${serie?.quizStatus}`}>
              {serie?.quizStatus === "passed" && (
                <img
                  className="icon"
                  src="/assets/images/passed.png"
                  alt="passed-badge"
                />
              )}
              {t(serie?.quizStatus)}
            </div>
            <div className="quiz-info">
              <div className="logo">
                <img
                  src="/assets/icons-svg/quiz-time-logo-round.svg"
                  alt="logo"
                />
              </div>
              <div className="quiz-title" title={`${serie?.title} - ${t("QUIZ")}`}>
                {serie?.title} - {t("QUIZ")}
              </div>
            </div>
            <div className="button-group">
              {["failed"].includes(serie?.quizStatus) && (
                <Link
                  to={`/quiz?id=${serie?.quiz}&status=review`}
                  className="button"
                >
                  {t("REVIEW")}
                </Link>
              )}
              {["failed"].includes(serie?.quizStatus) && (
                <Link
                  to={`/quiz?id=${serie?.quiz}&statu=retake`}
                  className={`button ${
                    serie?.quizStatus === "failed" ? "primary" : ""
                  }`}
                >
                  {t("RETAKE")}
                </Link>
              )}
              {["inProgress"].includes(serie?.quizStatus) && (
                <Link
                  to={`/quiz?id=${serie?.quiz}&status=continueQuiz`}
                  className="button primary"
                >
                  {t("CONTINUE")}
                </Link>
              )}
              {["passed"].includes(serie?.quizStatus) && (
                <div
                  onClick={() => handleDownloadCertificate(serie?.quiz)}
                  className="button primary"
                >
                  <Download />
                  {t("CERTIFICATE")}
                </div>
              )}
              {["notStarted", "toTake"].includes(serie?.quizStatus) && (
                <Link to={`/quiz?id=${serie?.quiz}`} className="button primary">
                  {t("START")}
                </Link>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SeriesDropDown;

const EpisodeItem = ({ episode, index }) => {
  const { t } = useTranslation();
  const parseTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.round(time - minutes * 60, 2);
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };
  return (
    <div
      className={`episode-item-wrapper${
        episode?.video?.userComplete ? " watched" : ""
      }`}
    >
      <div className="episode-item">
        <div className="episode-item__checkmark">
          <CheckMark checked={episode?.video?.userComplete} />
        </div>
        <div className="episode-item__description">{`${index}. ${episode?.video?.description}`}</div>
        <div className="episode-item__duration">
          {parseTime(episode.video.duration)}
        </div>
        <div className="episode-item__released">
          {t("Released_On", {
            date: moment(episode.video.releaseDate).format("MMM DD, YYYY"),
          })}
        </div>
      </div>
      <div className="released-mobile">
        {t("Released_On", {
          date: moment(episode.video.releaseDate).format("MMM DD, YYYY"),
        })}
      </div>
    </div>
  );
};
