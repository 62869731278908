import React, { useState, useEffect, useMemo } from "react";
import Wrapper from "../wrapper";
import SearchBar from "./searchBar";
import "./styles.scss";
import { searchSeries } from "../../../utils/data";
import { useTranslation } from "react-i18next";
import SeriesDropDown from "../../ui/SeriesDropDown/SeriesDropDown";

const PAGE_SIZE = 30;

export function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedValue(value);
    }, delay ?? 500);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  return debouncedValue;
}

const Directory = () => {
  const { t } = useTranslation();
  const [q, setQ] = useState("");
  const debouncedQ = useDebounce(q, 500);
  const [selectedCategory, setSelectedCategory] = useState("fb");
  const [showOrder, setShowOrder] = useState(false);
  const [order, setOrder] = useState("latest");
  const [series, setSeries] = useState();
  const [totalSeries, setTotalSeries] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);

  const CATS = useMemo(() => {
    return {
      fb: {
        id: process.env.REACT_APP_FB,
        label: "FOOD & BEVERAGE",
        src: "/assets/images/logo-fnb.png",
        translationKey: "FNB_FULL_NAME",
      },
      ops: {
        id: process.env.REACT_APP_OPERATIONS,
        label: (
          <>
            OPERATIONS &{" "}
            <span style={{ whiteSpace: "nowrap" }}>HUMAN RESOURCE</span>
          </>
        ),
        src: "/assets/images/logo-ope.png",
        translationKey: "OPSHR_FULL_NAME",
      },
      sm: {
        id: process.env.REACT_APP_SM,
        label: "SALES & MARKETING",
        src: "/assets/images/logo-snm.png",
        translationKey: "SNM_FULL_NAME",
      },
    };
  }, []);

  const onCategoryClick = (category) => {
    setSelectedCategory(category);
  };

  const onOrderClick = (order) => {
    setOrder((e) => (e === order ? undefined : order));
    setShowOrder(false);
  };

  const getNextPage = async () => {
    setIsLoading(true);
    const nexPage = page + 1;
    const res = await searchSeries({
      q: debouncedQ,
      range: `[${nexPage * PAGE_SIZE},${
        nexPage * PAGE_SIZE + (PAGE_SIZE - 1)
      }]`,
      category: CATS[selectedCategory].id,
      sort: `["createdAt", "${order === "earliest" ? "ASC" : "DESC"}"]`,
    });
    setSeries((e) => e.concat(res?.data));
    setTotalSeries(
      parseInt(res?.headers?.["content-range"]?.split(" ")[1]?.split("/")[1])
    );
    setPage((e) => (res.data.length < PAGE_SIZE ? null : nexPage));
    setIsLoading(false);
  };

  useEffect(() => {
    const launchSearch = async () => {
      const res = await searchSeries({
        q: debouncedQ,
        range: `[0,${PAGE_SIZE - 1}]`,
        category: CATS[selectedCategory].id,
        sort: `["createdAt", "${order === "earliest" ? "ASC" : "DESC"}"]`,
      });
      setSeries(res?.data);
      setTotalSeries(
        parseInt(res?.headers?.["content-range"]?.split(" ")[1]?.split("/")[1])
      );
      if (res.data.length < PAGE_SIZE) {
        setPage(null);
      }
      setIsLoading(false);
    };

    setIsLoading(true);
    setSeries(undefined);
    launchSearch();
    setPage(0);
  }, [debouncedQ, selectedCategory, order, CATS]);

  const isEmpty = !isLoading && !series?.length;

  return (
    <Wrapper>
      <div id="content">
        <section className={`directory ${selectedCategory}`}>
          <div className="viewport">
            <div className="inner">
              <SearchBar
                searchValue={q}
                onSearchChange={setQ}
                onClick={() => {}}
              />

              <div className="tab-wrapper">
                {Object.keys(CATS).map((cat, index) => (
                  <div key={cat.id} onClick={() => onCategoryClick(cat)}>
                    <img src={CATS[cat].src} alt="" />
                    <span>{t(CATS[cat].translationKey)}</span>
                  </div>
                ))}
              </div>

              <div className="summary">

                {!isLoading && (
                  <div className="order">
                    <span className="label">
                      <span className="icon-sorting" />
                      {t("Sort_By")}
                    </span>

                    <span
                      className="value"
                      onClick={() => setShowOrder((e) => !e)}
                    >
                      {!order
                        ? "Default"
                        : t({ latest: "Latest", earliest: "Earliest" }[order])}

                      <div className={`overlay${showOrder ? " open" : ""}`} />

                      <div
                        // ref={ref}
                        className={`popup${showOrder ? " open" : ""}`}
                      >
                        <div
                          className={order === "latest" ? "active" : ""}
                          onClick={() => onOrderClick("latest")}
                        >
                          <span className="icon-check" />
                          <span>{t("Latest")}</span>
                        </div>
                        <div
                          className={order === "earliest" ? "active" : ""}
                          onClick={() => onOrderClick("earliest")}
                        >
                          <span className="icon-check" />
                          <span>{t("Earliest")}</span>
                        </div>
                      </div>
                    </span>
                  </div>
                )}
              </div>

              <div>
                {series?.map((serie, index) => (
                  <SeriesDropDown
                    category={selectedCategory}
                    serie={serie}
                    index={index}
                    total={totalSeries}
                    order={order}
                  />
                ))}
              </div>

              {isEmpty && (
                <div className="empty">
                  <span style={{ fontSize: 17 }}>{t("Empty_series_result")}</span>
                </div>
              )}

              {page !== null && !isEmpty && (
                <div className="btn-wrapper">
                  <button
                    disabled={isLoading}
                    className="tertiary small"
                    onClick={getNextPage}
                  >
                    <span>{isLoading ? t("Loading") : t("Discover_More")}</span>
                  </button>
                </div>
              )}
            </div>
          </div>
        </section>
      </div>
    </Wrapper>
  );
};

export default Directory;