import React from 'react';
import { useGlobalContext } from '../../../store/global';
import { useTranslation } from 'react-i18next';

export default function Thanks() {
  const { closeModal, modalData } = useGlobalContext();
  const { t } = useTranslation();


  return (
      <div className="popup-inner delete-comment" style={{ maxWidth: '650px' }}>
        <div className="popup-title">
            <h2>{t("Please_confirm")}</h2>
            <div className="close icon-x" onClick={closeModal}></div>
        </div>

        <div className="popup-content thanks-content">
          <p>{t("Are_you_sure_you_want_to_delete_your_comment")}</p>
          <div className="actions">
            <div className="confirm" onClick={closeModal}>{t("Cancel")}</div>
            <div className="delete" onClick={() => {
              modalData()
              closeModal()
            }}>{t("Delete")}</div>
          </div>   
        </div>
      </div>
  );
}
