import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import { parseCategoryID } from "../../utils/index";
import get from "lodash.get";
import { downloadCertificate, getQuizById } from "../../utils/data";
import { useTranslation } from "react-i18next";
import Download from "./Download";
import moment from "moment";

function QuizItem(props) {
  const { quiz, status } = props;
  const { t } = useTranslation();
  const quizStatus = status || quiz?.status;
  const [lastTakeOn, setLastTakeOn] = useState();
  const [lastHighestScoreTakeTime, setLastHighestScoreTakeTime] = useState();
  const [heighestScore, setHeighestScore] = useState();

  useEffect(() => {
    if (quiz?.id) {
      setLastHighestScoreTakeTime(moment(quiz?.lastTakeAt)?.format("YYYY.MM.DD"));
      getQuizById(quiz?.id).then((res) => {
        if (res?.data?.previousResults?.[0]?.updatedAt) {
          setLastTakeOn(
            moment(res?.data?.previousResults?.[0]?.updatedAt).format(
              "YYYY.MM.DD"
            )
          );
        }
        if (res?.data?.previousResults?.length) {
          let temp = 0;
          // eslint-disable-next-line no-unused-expressions
          res?.data?.previousResults?.forEach((result) => {
            if (result.score > temp) {
              temp = result.score;
            }
          });
          setHeighestScore(temp);
        }
      });
    }
  }, [quiz]);

  const handleDownloadCertificate = async (id) => {
    await downloadCertificate(id);
  };

  const toQuiz = () => {
    props.history.push(`/quiz?id=${quiz?.id}`);
  }

  return (
    <div className="quiz-item">
      <div className="inner">
        <div onClick={() => toQuiz()} className="player-wrapper">
          <div className="cover"></div>
          {get(quiz, "thumbnail.uri", "") !== "" && (
            <div
              className="quiz-wrapper"
              style={{
                backgroundImage:
                  get(quiz, "thumbnail.uri", "") !== ""
                    ? `url(${
                        process.env.REACT_APP_IMG_URL
                      }/storage/quiz-thumbnail/${get(
                        quiz,
                        "thumbnail.uri",
                        ""
                      )})`
                    : 'url("/assets/images/video-1.jpg")',
              }}
            ></div>
          )}
          {quizStatus !== "notStarted" && quizStatus !== "toTake" && (
            <div className={`quiz-badge ${quizStatus}`}>
              {quizStatus === "passed" && (
                <img
                  className="icon"
                  src="/assets/images/passed.png"
                  alt="pass-icon"
                />
              )}
              {t(quizStatus)}
            </div>
          )}
          {/* <div className="quiz-item-quiz-time-logo">
            <img className="quiz-logo" src="/assets/images/quiz_time_logo.svg" alt="quiz-time-logo"/>
          </div> */}
        </div>
        <div className="infos">
          <div className="category-wrapper">
            <div className="category-wrapper-left">
              <div
                className={`cat ${
                  parseCategoryID(get(quiz, "category", "")).categoryShorthand
                }`}
              >
                {t(parseCategoryID(get(quiz, "category", ""))?.categoryTranslationKey)}
              </div>
              {props.isSustainability && (
                <div className="sustainability-tag-wrapper">
                  <span className="icon-sustainability"></span>
                  <span className="text-sustainability">sustainability</span>
                </div>
              )}
            </div>
            {"inProgress" === quizStatus && (
              <div className="category-wrapper-right">
                {t("Started_at")}: {lastHighestScoreTakeTime}
              </div>
            )}
            {["failed", "passed"]?.includes(quizStatus) &&
              lastTakeOn && (
                <div className="category-wrapper-right">
                  {t("Taken_on")}: {lastHighestScoreTakeTime}
                </div>
              )}
          </div>
          <Link to={"/quiz?id=" + quiz?.id}>
            <div className="quiz-title">
              <div className="quiz-title-text">{quiz?.title}</div>
              {["failed", "passed"]?.includes(quizStatus) && heighestScore !== undefined && heighestScore !== null && (
                <div className="quiz-title-score">{heighestScore}/100</div>
              )}
            </div>
          </Link>
          <div className="quiz-buttons">
            <div className="quiz-buttons-series">
              {quiz.serie && (
                <Link
                onClick={(e) => {
                  e.stopPropagation();
                }}
                  className="quiz-button series-button"
                  to={`/series/${quiz?.serie}`}
                >
                  <span className="icon-playbuttonbold"></span>
                  <span className="series-text">{t("Watch_The_Series")}</span>
                </Link>
              )}
            </div>

            <div className="quiz-buttons-actions">
              {["failed"].includes(quizStatus) && (
                <Link
                onClick={(e) => {
                  e.stopPropagation();
                }}
                  to={`/quiz?id=${quiz?.id}&status=review`}
                  className="button"
                >
                  {t("REVIEW")}
                </Link>
              )}
              {["failed"].includes(quizStatus) && (
                <Link
                onClick={(e) => {
                  e.stopPropagation();
                }}
                  to={`/quiz?id=${quiz?.id}&status=retake`}
                  className={`button ${
                    quizStatus === "failed" ? "primary" : ""
                  }`}
                >
                  {t("RETAKE")}
                </Link>
              )}
              {["inProgress"].includes(quizStatus) && (
                <Link
                onClick={(e) => {
                  e.stopPropagation();
                }}
                  to={`/quiz?id=${quiz?.id}&status=continue`}
                  className="button primary"
                >
                  {t("CONTINUE")}
                </Link>
              )}
              {["passed"].includes(quizStatus) && (
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDownloadCertificate(quiz?.id)}}
                  className="button primary"
                >
                  <Download />
                  {t("CERTIFICATE")}
                </div>
              )}
              {["notStarted", "toTake"].includes(quizStatus) && (
                <Link onClick={(e) => {
                  e.stopPropagation();
                }} to={`/quiz?id=${quiz?.id}`} className="button primary">
                  {t("START")}
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(QuizItem);
