import React from 'react';
import { withRouter } from 'react-router';
import { getNthItemFromPathname } from '../../../utils/index';
import { useTranslation } from 'react-i18next';

const CategoryHero = (props) => {
  const {t} = useTranslation();
  const path = getNthItemFromPathname(props.location.pathname, 2);

  let categoryShorthand;
  let categoryTranslationKey = '';

  switch (path) {
    case 'operations':
      categoryShorthand = 'ope';
      categoryTranslationKey = 'OPSHR';
      break;
    case 'sm':
      categoryShorthand = 'snm';
      categoryTranslationKey = 'SNM';
      break;
    case 'fb':
      categoryShorthand = 'fnb';
      categoryTranslationKey = 'FNB';
      break;
    default:
      props.history.replace('/notfound');
      break;
  }
  return (
    <section className="category-header">
      <div className="viewport">
        <div className="inner">
          <div className="top">
            <div className="txt-wrapper">
              <div className={`logo ${categoryShorthand}`}></div>
              <h1>{t(categoryTranslationKey)}</h1>
            </div>
            <div className="img-wrapper">
              <div className="gradient"></div>
              <div className="medias">
                <span
                  style={{
                    backgroundImage: `url('/assets/images/cats-${categoryShorthand}.jpg')`,
                  }}
                ></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default withRouter(CategoryHero);
