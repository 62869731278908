import React from 'react';
import { useGlobalContext } from '../../../store/global';
import { useTransition } from 'react';

export default function Thanks() {
  const { closeModal } = useGlobalContext();
  const { t } = useTransition();

  return (
    <div className="popup-inner thanks" style={{ maxWidth: '650px' }}>
      <div className="popup-title">
        <h2>{t("Thank_you")}</h2>
        <div className="close icon-x" onClick={closeModal}></div>
      </div>

      <div className="popup-content thanks-content">
        <p>{t("Your_comment_changes_have_been_saved")}</p>
        {/* <p>Thank you.</p> */}
        <div className="popup-action">
          <button className="primary big" onClick={closeModal}>
            {t("Back_to_IDEAS")}
          </button>
        </div>
      </div>
    </div>
  );
}
