import React from 'react';
import VideoItem from '../../ui/VideoItem';
import moment from 'moment';
import { seriesFilter } from '../../../utils';
import { useTranslation } from 'react-i18next';

export default function SeriesVideos(props) {
  const { videos, quiz, ascOrder = false } = props;
  const { t } = useTranslation();
  if(ascOrder){
    videos.sort((a, b) => moment(a.video.releaseDate).unix() - moment(b.video.releaseDate).unix());
  }else{
    videos.sort((a, b) => moment(b.video.releaseDate).unix() - moment(a.video.releaseDate).unix());
  }

  return (
    <section className="series-videos">
      <div className="viewport">
        <div className="inner">
          {videos && videos.length > 0 && (
            <div className="vid-count">{`(${videos.filter(seriesFilter).length} ${videos.filter(seriesFilter).length > 1 ? t("XVideos") : t("XVideo")})`}</div>
          )}
          <div className="video-list">
            {quiz && <VideoItem.Quiz quiz={quiz}/>}
            {videos.filter(seriesFilter).map((v, i) => (
              <VideoItem key={v.video.id} video={v.video} />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}
