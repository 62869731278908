import React, { useState, useEffect } from "react";
import get from "lodash.get";
import shuffle from "lodash.shuffle";
import {
  downloadCertificate,
  postQuizAnswer,
  postQuizAnswerAutoSave,
} from "../../../utils/data";
import CountUp from "react-countup";
import QuizzEpisodesSlider from "../../ui/QuizzEpisodesSlider";
import Download from "../../ui/Download";
import { Link } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";

const QuizzEmbed = ({
  quiz,
  prevQuiz,
  category,
  quizVisitStatus,
  seriesData,
  seriesVideos,
  hideGoBack,
}) => {
  const { t } = useTranslation();
  const [responses, setResponses] = useState([]);
  const [step, setStep] = useState(0);
  const [score, setScore] = useState(0);
  const [showCongrats, setShowCongrats] = useState(false);
  const [started, setStated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const maxStep = responses?.length;
  const [failedQuizzes, setFailedQuizzes] = useState([]);
  const [showHelpMePass, setShowHelpMePass] = useState(false);
  const [correctCount, setCorrectCount] = useState(0);
  const [shuffleResponse, setShuffleResponse] = useState([]);

  const cantGoNext =
    responses?.[step]?.answers?.findIndex((e) => e.selected) === -1;

  useEffect(() => {
    if (!quiz) return;

    setStep(0);
    setScore(0);
    setShowCongrats(false);
    setStated(false);
    setIsLoading(false);

    if (prevQuiz && prevQuiz.length) {
      let orderedQuizzes = [...prevQuiz];
      orderedQuizzes.sort(function (a, b) {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
      let faileds = orderedQuizzes.filter((e) => e.passed === false);
      setFailedQuizzes(faileds);
      orderedQuizzes = orderedQuizzes.filter((e) => e.passed === true);
      switch (quizVisitStatus) {
        case "review": {
          toReview(faileds?.[0]?.questions);
          return;
        }
        case "continue": {
          continueQuiz();
          return;
        }
        case "retake": {
          restartQuiz();
          return;
        }
        default: {
          setResponses(get(quiz, "questions", []));
          setShuffleResponse(shuffleAnswers(get(quiz, "questions", [])));
          return;
        }
      }
    }
    setResponses(get(quiz, "questions", []));
    setShuffleResponse(shuffleAnswers(get(quiz, "questions", [])));
  }, [quiz, prevQuiz, category, quizVisitStatus]);

  const shuffleAnswers = (questions) => {
    const shuffleAnswers = (questions || [])?.map((q) => ({
      ...q,
      answers: shuffle(
        q?.answers?.map((a, realIndex) => ({
          ...a,
          realIndex,
        }))
      ),
    }));
    return shuffleAnswers;
  };

  const handleNext = () => {
    if (cantGoNext) return;

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });

    if (step === maxStep - 1) {
      if (!showCongrats) {
        setIsLoading(true);
        postQuizAnswer(quiz.id, responses)
          .then((res) => {
            setIsLoading(false);
            setScore(res.data.score);
            setShowCongrats(true);
          })
          .catch(() => setIsLoading(false));
      } else {
        setShowCongrats(true);
      }
    } else {
      postQuizAnswerAutoSave(quiz.id, responses).then(() => {
        setStep(step + 1);
      });
    }
  };

  const checkCorrect = (indexQ) => {
    const CAns = get(responses, `${step}.answers.${indexQ}`, {});

    const categoryShorthand = category?.categoryShorthand;

    let res = CAns.selected ? "selected " + categoryShorthand : "";

    if (showCongrats) {
      if (CAns.selected && CAns.rightAnswer) {
        return "green " + res;
      }

      if (CAns.rightAnswer && !CAns.selected) {
        return "green " + res;
      }

      if (!CAns.selected && CAns.rightAnswer) {
        return "red " + res;
      }

      if (CAns.selected && !CAns.rightAnswer) {
        return "red " + res;
      }
    }

    return res;
  };

  const toReview = (response) => {
    setResponses(response);
    setShuffleResponse(shuffleAnswers(response));
    setStep(response?.length > 1 ? response?.length - 1 : 0);
    setStated(true);
    getCorrectCount(response);
    setShowHelpMePass(true);
  };

  const handleAnswer = async (index) => {
    if (showCongrats) return;
    let cpyRes = [...responses];
    if (cpyRes[step].answers[index].selected) {
      cpyRes[step].answers[index].selected = false;
    } else {
      cpyRes[step].answers[index].selected = true;
    }
    setResponses(cpyRes);
  };

  const IconConfirm = () => {
    for (const ans of get(responses, `${step}.answers`, [])) {
      if (
        (!ans.selected && ans.rightAnswer) ||
        (ans.selected && !ans.rightAnswer)
      ) {
        return (
          <img
            className="quizz-wrong"
            src={`/assets/images/quizz-wrong.png`}
            alt=""
          />
        );
      }
    }
    return (
      <img
        className="quizz-right"
        src={`/assets/images/quizz-right.png`}
        alt=""
      />
    );
  };

  const continueQuiz = () => {
    const temp = prevQuiz?.[0]?.questions;
    let currentStep = 0;
    temp.forEach((q, index) => {
      let questionDone = false;
      q.answers.forEach((a) => {
        if (a.selected) questionDone = true;
      });
      if (questionDone) {
        currentStep = index;
      }
    });
    setResponses(temp);
    setShuffleResponse(shuffleAnswers(temp));
    window.scrollTo({
      top: 0,
      left: 0,
    });
    setStep(currentStep === temp.length - 1 ? currentStep : currentStep + 1);
    setStated(true);
    setShowCongrats(false);
    setShowHelpMePass(false);
    hideGoBack();
  };

  const restartQuiz = () => {
    let tempResp = get(quiz, "questions", [])?.map((resp) => ({
      ...resp,
      answers: resp.answers?.map((ans) => {
        const empty = { ...ans };
        delete empty.selected;
        return empty;
      }),
    }));

    setResponses(tempResp);
    setShuffleResponse(shuffleAnswers(tempResp));
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    setStep(0);
    setStated(true);
    setShowCongrats(false);
    setShowHelpMePass(false);
    hideGoBack();
  };

  const startQuiz = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    setStated(true);
    hideGoBack();
  };

  const getCorrectCount = (responses) => {
    let count = 0;
    let isCorrect = true;
    if (responses && Array.isArray(responses)) {
      responses.forEach((response) => {
        isCorrect = true;
        for (const ans of get(response, `answers`, [])) {
          if (
            (!ans.selected && ans.rightAnswer) ||
            (ans.selected && !ans.rightAnswer)
          ) {
            isCorrect = false;
            break;
          }
        }
        if (isCorrect) count++;
      });
    }
    setCorrectCount(count);
  };

  const checkIsCorrect = (response) => {
    let isCorrect = true;
    for (const ans of get(response, `answers`, [])) {
      if (
        (!ans.selected && ans.rightAnswer) ||
        (ans.selected && !ans.rightAnswer)
      ) {
        isCorrect = false;
        break;
      }
    }
    return isCorrect;
  };

  const handleDownloadCertificate = async () => {
    try {
      const res = await downloadCertificate(quiz?.id);
      if (res) {
        console.log(res);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const helpMePass = () => {
    getCorrectCount(responses);
    setShowHelpMePass(true);
  };

  return (
    <>
      <div className="quizz">
        {!started ? (
          <div className={category?.categorySlug + " quizz-header"}>
            <div className="quizz-mask"></div>
            <div className="home-header-ideas for-quizz">
              <p className="home-header-ideas__word">{t("INSPIRATION")}</p>
              <p className="home-header-ideas__word">{t("DIRECTION")}</p>
              <p className="home-header-ideas__word">{t("EXCELLENCE")}</p>
              <p className="home-header-ideas__word">{t("APPLICATION")}</p>
              <p className="home-header-ideas__word">{t("SOLUTION")}</p>
            </div>
            <div className={`quizz-time big ${started ? "" : "pulsing"}`}>
              <img src="/assets/images/quiz_time_logo.svg" alt="quiz time" />
            </div>
          </div>
        ) : (
          <div
            className={`quizz-inprogress-header ${category?.categoryShorthand}`}
          >
            <div className="progress-bar">
              {get(quiz, "questions", [])?.map((question, index) => (
                <span
                  className={`progress-item ${
                    step >= index ? category?.categoryShorthand : ""
                  } `}
                  style={{ width: (1 / maxStep) * 100 + "%" }}
                ></span>
              ))}
            </div>
            <div className="quiz-inprogress">
              <div className={`category ${category?.categoryShorthand}`}>
                <img
                  className="quiztime-pic"
                  src="/assets/icons-svg/quiz-time-logo-round.svg"
                  alt="quiz time"
                />
                <div className="category-text">{category?.categoryName}</div>
              </div>
              <div className="infos">
                <div className="title">{get(quiz, "title", [])}</div>
                {!showCongrats && (
                  <div className="step">
                    {step + 1}/{maxStep}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}

        <div className="quizz-content">
          <div>
            {!started ? (
              <Welcome
                title={quiz?.title}
                category={category}
                maxStep={maxStep}
                status={quiz?.status}
                start={startQuiz}
                restartQuiz={restartQuiz}
                continueQuiz={continueQuiz}
                toReview={() => toReview(failedQuizzes?.[0]?.questions)}
                handleDownloadCertificate={handleDownloadCertificate}
                t={t}
              />
            ) : showHelpMePass ? (
              <HelpMePass
                responses={responses}
                category={category}
                restartQuiz={restartQuiz}
                seriesData={seriesData}
                correctCount={correctCount}
                checkIsCorrect={checkIsCorrect}
                t={t}
              />
            ) : showCongrats && step === maxStep - 1 ? (
              <EndQuiz
                score={score}
                category={category}
                isPassed={score >= 80}
                restartQuiz={restartQuiz}
                failedQuizzes={failedQuizzes}
                helpMePass={helpMePass}
                t={t}
                goBack={() => {
                  window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: "smooth",
                  });
                  setStep(0);
                  setShowCongrats(false);
                }}
              />
            ) : (
              <>
                {showCongrats && (
                  <div className="quizz-results-header">
                    <IconConfirm />
                    <span>{get(responses, `${step}.relatedEpisode`, "")}</span>
                  </div>
                )}
                <div className="mobile-quizz-header">
                  <div className="quizz-category">
                    <span
                      className={`cat ${
                        category ? category.categoryShorthand : ""
                      }`}
                    >
                      {category ? category.categoryName : ""}
                    </span>
                  </div>
                  <div className="quizz-title">
                    <span>{quiz?.title}</span>
                  </div>
                </div>

                <div className={"questions"}>
                  {get(responses, `${step}.image.uri`, null) && (
                    <div className="quizz-image">
                      <img
                        src={`${
                          process.env.REACT_APP_IMG_URL
                        }/storage/quiz-image/${get(
                          responses,
                          `${step}.image.uri`,
                          null
                        )}`}
                        alt=""
                      />
                    </div>
                  )}
                  <div>
                    <div className="question-title">
                      {get(responses, `${step}.question`, "")}
                    </div>
                    {get(responses, `${step}.hint`, "") && (
                      <div className="question-hint">
                        ({get(responses, `${step}.hint`, "")})
                      </div>
                    )}
                    {get(shuffleResponse, `${step}.answers`, [])?.map(
                      (option) => (
                        <div
                          onClick={() => handleAnswer(option?.realIndex)}
                          className={`question-answer ${checkCorrect(
                            option?.realIndex
                          )}`}
                        >
                          {option.answer}
                        </div>
                      )
                    )}
                    <div className="actions">
                      {step !== 0 && (
                        <div
                          className="prev"
                          onClick={
                            step > 0
                              ? () => {
                                  window.scrollTo({
                                    top: 0,
                                    left: 0,
                                    behavior: "smooth",
                                  });
                                  setStep(step - 1);
                                }
                              : null
                          }
                        >
                          {t("PREVIOUS")}
                        </div>
                      )}
                      <div
                        className={`${cantGoNext ? "disabled" : "next"}`}
                        onClick={handleNext}
                      >
                        {step === maxStep - 1
                          ? showCongrats
                            ? t("Results")
                            : isLoading
                            ? t("Sending") + " ..."
                            : t("Submit")
                          : t("NEXT")}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      {(!started && seriesVideos?.length) > 0 && (
        <div className="series quizz-serie-episodes">
          <span className="title">{t("Related_Series")}</span>
          <div className="serie-infos">
            <span className="serie-title">{seriesData?.title}</span>
            <span className="serie-videos-count">
              {seriesVideos?.length || 0} {t("XVideos")}
            </span>
          </div>
          <QuizzEpisodesSlider serie={seriesVideos} />
        </div>
      )}
    </>
  );
};

export default QuizzEmbed;

const Welcome = ({
  title,
  start,
  category,
  maxStep,
  status,
  restartQuiz,
  continueQuiz,
  handleDownloadCertificate,
  toReview,
  t,
}) => {
  return (
    <div className="welcome">
      <div className="quizz-category">
        <span className={`cat ${category ? category.categoryShorthand : ""}`}>
          {category ? category.categoryName : ""}
        </span>
      </div>
      <div className="quizz-title">
        <span>{title}</span>
      </div>

      <div className="medal">
        {status === "passed" && (
          <span className="passed-medal">
            <img src="/assets/icons-svg/medal.svg" alt="medal"></img>
            <span>{t("Passed")}</span>
          </span>
        )}
        {status === "failed" && (
          <span className="failed-medal">
            <span>{t("Failed")}</span>
          </span>
        )}
        {status === "inProgress" && (
          <span className="progress-medal">
            <span>{t("inProgress")}</span>
          </span>
        )}
      </div>

      <div className="quizz-tip">
        <Trans i18nKey="Quizz_Welcome" title={title}>
          <p>
            Do you remember the most important things about {{ title }} series?
          </p>
          <p>
            Test your knowledge with this interactive quiz and get a score of
            80/100 points or above.
          </p>
          <p>
            Don’t worry if you fail the first time, you can do it again until
            you pass.
          </p>
        </Trans>
      </div>

      <div className="questions-count">
        <p>
          <strong>{maxStep}</strong> {t("Xquestions")}
        </p>
      </div>

      <div className="quiz-buttons-actions">
        {["failed"].includes(status) && (
          <div onClick={() => toReview()} className="button">
            {t("REVIEW")}
          </div>
        )}
        {["failed", "inProgress", "passed"].includes(status) && (
          <div
            onClick={() => restartQuiz()}
            className={`button ${status === "failed" ? "primary" : ""}`}
          >
            {t("RETAKE")}
          </div>
        )}
        {["inProgress"].includes(status) && (
          <div onClick={() => continueQuiz()} className="button primary">
            {t("CONTINUE")}
          </div>
        )}
        {["passed"].includes(status) && (
          <div
            onClick={() => handleDownloadCertificate()}
            className="button primary"
          >
            <Download />
            {t("CERTIFICATE")}
          </div>
        )}
        {["notStarted", "toTake"].includes(status) && (
          <div onClick={() => start()} className="button primary">
            {t("START")}
          </div>
        )}
      </div>
    </div>
  );
};

const EndQuiz = ({
  score,
  isPassed,
  goBack,
  restartQuiz,
  category,
  failedQuizzes,
  helpMePass,
  t,
}) => {
  return (
    <div className={category?.categorySlug + " quizz-results"}>
      {isPassed ? (
        <>
          <img
            className="quizz-pass"
            src="/assets/images/you-pass.png"
            alt="you pass"
          />
          <img
            className="quizz-congrats"
            src="/assets/images/result-congrats.gif"
            alt="congrats"
          />
          <div className="quizz-score green">
            <CountUp end={score} duration={3} start={0} delay={0} />
            {t("Xpoints")}
          </div>
        </>
      ) : (
        <>
          <img
            className="quizz-congrats"
            src="/assets/images/result-failed.gif"
            alt="failed"
          />
          <div className="quizz-score">
            <CountUp
              end={score}
              duration={3}
              start={0}
              delay={0}
              // onStart={() => setActive(false)}
            />
            {t("Xpoints")}
          </div>

          {!isPassed && (
            <div className="helpme-passed-tip">
              <p>{t("Dont_Worry")}</p>
            </div>
          )}
        </>
      )}

      <div className="actions">
        {!isPassed ? (
          <>
            <div className="actions-btn-wrapper">
              <div className="helpme-passed-btn" onClick={helpMePass}>
                {t("Help_Me_Pass")}
              </div>
            </div>
            <div className="actions-btn-wrapper">
              <div className="retake-btn" onClick={restartQuiz}>
                {t("RETAKE")}
              </div>
            </div>
          </>
        ) : (
          <div className="next" onClick={restartQuiz}>
            {t("RETAKE")}
          </div>
        )}
      </div>
    </div>
  );
};

const HelpMePass = ({
  responses,
  category,
  restartQuiz,
  seriesData,
  t,
  correctCount,
  checkIsCorrect,
}) => {
  const isMissed = (response) => {
    // only multiply choice can be missed (right answer count >= 2)
    let rightAnswerCount = 0;
    let selectedAnswerCount = 0;
    let missed = false;

    response.answers.forEach((answer) => {
      if (answer.selected === true) {
        selectedAnswerCount++;
      }
      if (answer.rightAnswer === true) {
        rightAnswerCount++;
        if (answer.selected === false) {
          // right answer but not selected
          missed = true;
        }
      }
    });

    let less = rightAnswerCount > selectedAnswerCount ? true : false;
    let isMultiply = rightAnswerCount >= 2 ? true : false;

    let res = less && isMultiply && missed;
    return res;
  };

  return (
    <div className="help-container">
      <div className="help-header">
        <div className="title">{t("Almost_There")}</div>
        <div className="description">
          <Trans i18nKey="Help_Description">
            <span>You already got {{ correctCount }} correct answers.</span>
            <span>Below are the questions that you have missed</span>
          </Trans>
        </div>
      </div>

      <div className="help-questions-container">
        {responses?.map((response, index) => {
          return (
            !checkIsCorrect(response) && (
              <div className="help-questions-item">
                <div className="question-container">
                  <div className="order">
                    <span className="order-desc">Q{index + 1}.</span>
                  </div>
                  <div className="question">
                    <span className="question-desc">{response.question}</span>
                  </div>
                </div>
                <div className="answers-container">
                  {response?.answers.map((answer) => {
                    return (
                      <>
                        {answer.rightAnswer === false &&
                          answer.selected === true && (
                            <div className="answer">
                              <span className="close icon-x"></span>
                              <span className="answer-text">
                                {answer.answer}
                              </span>
                            </div>
                          )}
                      </>
                    );
                  })}

                  {isMissed(response) && (
                    <div className="more-correct">
                      <span className="icon-question-mark"></span>
                      <span className="more-correct-text">
                        {t("There_Are_More")}
                      </span>
                    </div>
                  )}

                  {response?.relatedEpisode && (
                    <div className="related-episode">
                      Find the correct answer in{" "}
                      <span
                        className={`${
                          category ? category.categoryShorthand : ""
                        }`}
                      >
                        {response?.relatedEpisode}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            )
          );
        })}
      </div>
      <div className="help-actions-container">
        <Link
          target="_blank"
          className="learn-serie-btn"
          to={`/series/${seriesData?.id}`}
        >
          <span className="icon icon-playbuttonbold"></span>
          {t("Learn_From_Series")}
        </Link>
        <div className="retake-btn" onClick={restartQuiz}>
          {t("RETAKE")}
        </div>
      </div>
    </div>
  );
};
