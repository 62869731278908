import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import routes from './routes';
import Layout from '../components/layout';
import Auth from '../components/pages/auth';
import Modal from '../components/ui/modal';
import Reset from '../components/pages/reset';
import { useGlobalContext } from '../store/global';
import '../styles/index.scss';
import ForgotPassword from '../components/pages/forgot';
import CustomRedirect from './customRedirect';
import queryString from "query-string";
import RecipeModal from '../components/layout/recipeModal';

const ProtectedRoute = ({ isAllowed, ...props }) => {
  return isAllowed ? <Route {...props} /> : <CustomRedirect />;
};

const AppRouter = (props) => {
  const {
    userAuthed,
    modalOpen,
    modalText,
    canCloseModal,
    closeModal,
    ssologin,
    imageModal,
    closeImageModal,
    recipeModal,
    closeRecipeModal,
  } = useGlobalContext();

  if (window.location?.search) {
    const checkToken = async (token) => {
      await ssologin(token);
    };

    const { ssotoken } = queryString.parse(window.location.search);
    if (ssotoken) {
      checkToken(ssotoken);
      if (!userAuthed) {
        window.location.reload();
      }
    }
  }

  return (
    <Router>
      {!!imageModal &&
        <div className={'popup-wrapper mood-popup'}>
          <div className="close icon-x" onClick={closeImageModal} />
          <div className='wrapper'>
            {/* <span className='tag'>{imageModal.tag}</span> */}
            <img className='image' src={imageModal.url} alt="" />
          </div>
        </div>
      }
      {!!recipeModal &&
        <div className={'popup-wrapper recipe-popup'}>
          <div className="close icon-x" onClick={closeRecipeModal} />          
          <RecipeModal  {...recipeModal} />
        </div>
      }
      {modalOpen && <Modal text={modalText} closeModal={canCloseModal ? closeModal : null} />}
      <Layout>
        <Switch>
          <Route path="/auth" exact component={Auth} />
          <Route path="/auth/reset" component={Reset} />
          <Route path="/auth/forgot" component={ForgotPassword} />
          {routes.map((r, i) => (
            <ProtectedRoute
              path={r.path}
              component={r.component}
              exact={r.exact}
              key={r.path || i}
              render={r.render}
              isAllowed={userAuthed}
            />
          ))}
        </Switch>
      </Layout>
    </Router>
  );
  // : <CustomRedirect />
};

export default AppRouter;
