import React, { useState, useEffect } from "react";
import Select from "react-select";
import {
  getQuizzes,
  getSubmittedQuizzes,
  getTags,
  getMoodBoardTags,
} from "../../../utils/data";
import { useTranslation } from "react-i18next";
import "./searchBar.scss";

const link =
  "https://assets.hyatt.com/brandmanager.html?10004_group.propertyvalues.property=jcr%3Acontent%2Fmetadata%2Fhyatt%2FfileType&10004_group.propertyvalues.operation=equals&10004_group.propertyvalues.0_values=images%2Fimage&10005_group.propertyvalues.property=jcr%3Acontent%2Fmetadata%2Fhyatt%2Fbrands&10005_group.propertyvalues.operation=equals&10005_group.propertyvalues.9_values=global&10007_group.propertyvalues.property=jcr%3Acontent%2Fmetadata%2Fhyatt%2Fcategory&10007_group.propertyvalues.operation=equals&10007_group.propertyvalues.0_values=images%2Fimage%2Fbrand%2Cimages%2Flogo%2Fbrand&10008_group.propertyvalues.property=jcr%3Acontent%2Fmetadata%2Fhyatt%2Fsubcategory&10008_group.propertyvalues.operation=equals&10008_group.propertyvalues.0_values=images%2Fimage%2Fbrand%2Ffood-beverage%2Cimages%2Fimage%2Fgeneral%2Ffood-beverage%2Cimages%2Fimage%2Fhotel%2Ffood-beverage&10008_group.propertyvalues.1_values=images%2Fimage%2Fbrand%2Ffood-beverage%2Cimages%2Fimage%2Fgeneral%2Ffood-beverage%2Cimages%2Fimage%2Fhotel%2Ffood-beverage&orderby=%40jcr%3Acontent%2Fmetadata%2Fhyatt%2FuploadedDate&orderby.sort=desc&layout=card&p.offset=0&p.limit=24";

const SearchBar = ({
  searchValue,
  onSearchChange,

  searchQ,
  onQChange,
  searchTags,
  onTagChange,
  searchFilter,
  onFilterChange,

  filterCategory,
  onCategoryChange,
  sortKey,
  onSortChange,

  selectedMoodTags,
  onSelectedMoonTagChange,

  isCategory,
  color,
  isSustainability,

  hideEpisodesTag = false,
  resultLength,
}) => {
  const [tagList, setTagList] = useState([]);
  const [category, setCategory] = useState("");
  const { t } = useTranslation();
  const [quizUntakenNum, setQuizUntakenNum] = useState(0);
  const [moodTags, setMoodTags] = useState([]);

  const onMoonTagChange = (tag) => {
    const newSelected = new Set(selectedMoodTags);

    if (newSelected.has(tag)) {
      newSelected.delete(tag);
    } else {
      newSelected.add(tag);
    }
    onSelectedMoonTagChange(newSelected);
  };

  useEffect(() => {
    async function fetchData() {
      const t = await getTags();
      setTagList(t.data);
      if (isCategory) {
        const catId = filterCategory;

        let moodTags = await getMoodBoardTags();
        setMoodTags(moodTags.data);

        let submittedQuizzes = await getSubmittedQuizzes();
        submittedQuizzes = submittedQuizzes?.data;
        (submittedQuizzes || []).filter(
          (v, i, a) => a.findIndex((v2) => v2.quiz == v.quiz) === i
        );

        let filters = {};
        if (catId) {
          filters.category = catId;
        }
        if (isSustainability) {
          filters.isSustainability = true;
        }
        let quizzes = await getQuizzes(filters);

        var filterQuizzesResults = submittedQuizzes?.reduce((unique, o) => {
          if (
            !unique.some(
              (obj) => obj.quiz === o.quiz || obj.quiz?.id === o.quiz?.id
            )
          ) {
            unique.push(o);
          }
          return unique;
        }, []);

        if (isSustainability) {
          filterQuizzesResults = filterQuizzesResults.filter(
            (quizRes) => quizRes.quiz?.isSustainability === true
          );
        }

        let takenNum = 0;
        for (const i in filterQuizzesResults) {
          if (!isSustainability) {
            if (filterQuizzesResults[i].quiz?.category == catId) {
              takenNum++;
            }
          } else {
            takenNum++;
          }
        }
        setQuizUntakenNum(quizzes.data.length - takenNum);
      }
    }
    fetchData();

    const search = window.location.search.replace("?", "");
    const splitSearch = search.split("&");
    let vars = {};
    for (var i = 0; i < splitSearch.length; i++)
      vars[splitSearch[i].substr(0, splitSearch[i].indexOf("="))] = decodeURI(
        splitSearch[i].substr(splitSearch[i].indexOf("=") + 1)
      );

    setCategory(searchFilter ? searchFilter : "series");
  }, [searchFilter, filterCategory]);

  const valueAll = { value: "", label: t("All") };
  const valueDefault = { value: "", label: t("Default") };
  const values = {
    series: { value: "series", label: t("Series") },
    episodes: { value: "episodes", label: t("Episodes") },
    quiz: { value: "quiz", label: t("Quizzes") },
    speaker: { value: "speaker", label: t("Speakers") },
  };
  const valuesCategories = {
    fnb: { value: process.env.REACT_APP_FB, label: t("FNB") },
    ope: { value: process.env.REACT_APP_OPERATIONS, label: t("OPSHR") },
    snm: { value: process.env.REACT_APP_SM, label: t("SNM") },
  };

  const valuesEpisodesSort = {
    latest: { value: '["releaseDate","DESC"]', label: t("Latest") },
    earliest: { value: '["releaseDate","ASC"]', label: t("Earliest") },
    mostViewed: {
      value: '["morningViewCount","DESC"]',
      label: t("Most_Viewed"),
    },
  };

  return (
    <section
      className={`search ${searchValue ? "active" : ""} ${
        isSustainability ? "sustainability-search" : ""
      }`}
      style={{ "--border-color": color }}
    >
      <div className="viewport">
        <div className="inner">
          {isCategory === true && (
            <div
              className={
                "categories_container" +
                (filterCategory === process.env.REACT_APP_FB ? " is-fb" : "")
              }
            >
              <div
                className={"category" + (category == "series" ? " active" : "")}
                onClick={() => {
                  onFilterChange("series");
                  setCategory("series");
                }}
              >
                {t("Series")}
              </div>
              <div
                className={
                  "category" + (category == "episodes" ? " active" : "")
                }
                onClick={() => {
                  onFilterChange("episodes");
                  setCategory("episodes");
                }}
              >
                {t("Episodes")}
              </div>
              <div
                className={"category" + (category == "quiz" ? " active" : "")}
                onClick={() => {
                  onFilterChange("quiz");
                  setCategory("quiz");
                }}
              >
                {t("Quizzes")}
                {quizUntakenNum > 0 && (
                  <span className="untaken-num">{quizUntakenNum}</span>
                )}
              </div>
              {filterCategory === process.env.REACT_APP_FB && (
                <div
                  className={"category" + (category == "mood" ? " active" : "")}
                  onClick={() => {
                    onFilterChange("mood");
                    setCategory("mood");
                  }}
                >
                  {t("MoodBoard")}
                </div>
              )}
            </div>
          )}
          {isCategory === false && (
            <h2 className="center-title">{t("EXPLORE_THE_LIBRARY")}</h2>
          )}
          {category != "mood" ? (
            <div className="search-bar-wrapper">
              <div className="form-item form-search">
                <div
                  className="search-button"
                  onTab={() => {
                    onQChange(searchValue);
                  }}
                  onClick={() => {
                    onQChange(searchValue)
                  }}
                />
                <div className={`background-light ${searchQ ? "hidden" : ""}`}></div>
                <div className={`border-light ${searchQ ? "hidden" : ""}`}></div>
                <input
                  id="searchbar"
                  type="text"
                  autoComplete="off"
                  placeholder={category !== "quiz" ? t("Search_By_Theme_Topi_Or_Speaker") : t("Search_For_Quizzes")}
                  value={searchValue}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") onQChange(e.target.value);
                  }}
                  onChange={(e) => onSearchChange(e.target.value)}
                />
              </div>
            </div>
          ) : (
            <div className="mood-tags-wrapper">
              <span className="title">{t("GLOBAL_HYATT_F&B_PHOTOGRAPHY")}</span>
              <div className="text">
                {t("Our_mission_is_to...")}
                <br />
                <br />
                {t("All_of_the_images...")}
                <a target="__blank" href={link}>{t("here")}</a>
                {t("...platform_or_by_clicking")}
              </div>
              <div className="tags-wrapper">
                {moodTags.map((e, index) => (
                  <span
                    key={index}
                    onClick={() => onMoonTagChange(e.id)}
                    className={`tag${
                      selectedMoodTags.has(e.id) ? " active" : ""
                    }`}
                  >
                    {e.name}
                  </span>
                ))}
              </div>
            </div>
          )}
          {isCategory === true && category == "episodes" && (
            <>
              {/* <div className="tags">
                {tagList.length > 0 && (
                  <span
                    className={`tag ${!searchTags ? "tag-ur-it" : ""}`}
                    onClick={() => onTagChange("")}
                  >
                    All
                  </span>
                )}
                {!isSustainability && (
                  <span
                    className={`tag sustainability-tag-wrapper ${
                      searchTags === "sustainability" ? "tag-ur-it" : ""
                    }`}
                    onClick={() => onTagChange("sustainability")}
                  >
                    <span className="icon-sustainability"></span>
                    <span className="text-sustainability">sustainability</span>
                  </span>
                )}
                {tagList.map(
                  (t) =>
                    !t.disabled && (
                      <span
                        key={t.id}
                        className={`tag ${
                          searchTags === t.id ? "tag-ur-it" : ""
                        }`}
                        onClick={() => onTagChange(t.id)}
                      >
                        {t.name}
                      </span>
                    )
                )}
              </div> */}
              <div className="filter-wrapper">
                <div className="title">{t("All_Episodes")}</div>
                <div className="filter">
                  <div className="text">
                    <span className="icon-sorting"></span>
                    {t("Filter_By")}
                  </div>

                  <div className="form-item form-filter">
                    <Select
                      className="search-categories_container"
                      classNamePrefix="search-categories"
                      isSearchable={false}
                      value={valuesEpisodesSort[sortKey] || valueDefault}
                      onChange={(e) => onSortChange(e, sortKey)}
                      options={[
                        valuesEpisodesSort["latest"],
                        valuesEpisodesSort["earliest"],
                        valuesEpisodesSort["mostViewed"],
                      ]}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
          {isCategory === false && searchQ && (
            <>
              <div className="categories_container">
                <div
                  className={
                    "category search-category" + (category == "series" ? " active" : "")
                  }
                  onClick={() => {
                    onFilterChange("series");
                    setCategory("series");
                  }}
                >
                  {t("Series")}
                  {Boolean(resultLength?.series) && (
                    <div className="category-count">
                      <div className="count-number">{resultLength?.series}</div>
                    </div>
                  )}
                </div>
                <div
                  className={
                    "category search-category" + (category == "episodes" ? " active" : "")
                  }
                  onClick={() => {
                    onFilterChange("episodes");
                    setCategory("episodes");
                  }}
                >
                  {t("Episodes")}{" "}
                  {Boolean(resultLength?.episodes) && (
                    <div className="category-count">
                      <div className="count-number">
                        {resultLength?.episodes}
                      </div>
                    </div>
                  )}
                </div>
                <div
                  className={"category search-category" + (category == "quiz" ? " active" : "")}
                  onClick={() => {
                    onFilterChange("quiz");
                    setCategory("quiz");
                  }}
                >
                  {t("Quizzes")}{" "}
                  {Boolean(resultLength?.quizzes) && (
                    <div className="category-count">
                      <div className="count-number">
                        {resultLength?.quizzes}
                      </div>
                    </div>
                  )}
                </div>
              </div>

              {category == "series" && (
                <div className="filter-wrapper">
                  <div className="filter">
                    <div className="text">
                      <span className="icon-sorting"></span>
                      {t("Filter_By")}
                    </div>

                    <div className="form-item form-filter">
                      <Select
                        className={`search-categories_container category ${filterCategory}`}
                        classNamePrefix="search-categories"
                        isSearchable={false}
                        value={valuesCategories[filterCategory] || valueAll}
                        onChange={(e) => onCategoryChange(e)}
                        options={[
                          valuesCategories["fnb"],
                          valuesCategories["ope"],
                          valuesCategories["snm"],
                        ]}
                      />
                    </div>
                  </div>
                </div>
              )}

              {searchFilter === "episodes" && !hideEpisodesTag ? (
                <div className="tags">
                  {tagList.length > 0 && (
                    <span
                      className={`tag ${!searchTags ? "tag-ur-it" : ""}`}
                      onClick={() => onTagChange("")}
                    >
                      All
                    </span>
                  )}
                  {!isSustainability && (
                    <span
                      className={`tag sustainability-tag-wrapper ${
                        searchTags === "sustainability" ? "tag-ur-it" : ""
                      }`}
                      onClick={() => onTagChange("sustainability")}
                    >
                      <span className="icon-sustainability"></span>
                      <span className="text-sustainability">
                        sustainability
                      </span>
                    </span>
                  )}
                  {tagList.map(
                    (t) =>
                      !t.disabled && (
                        <span
                          key={t.id}
                          className={`tag ${
                            searchTags === t.id ? "tag-ur-it" : ""
                          }`}
                          onClick={() => onTagChange(t.id)}
                        >
                          {t.name}
                        </span>
                      )
                  )}
                </div>
              ) : null}

              {category != "series" && (
                <div className="filter-wrapper">
                  <div className="filter">
                    <div className="text">
                      <span className="icon-sorting"></span>
                      {t("Sort_By")}
                    </div>

                    <div className="form-item form-filter">
                      {category == "episodes" && (
                        <Select
                          className="search-categories_container"
                          classNamePrefix="search-categories"
                          isSearchable={false}
                          value={valuesEpisodesSort[sortKey] || valueDefault}
                          onChange={(e) => onSortChange(e, sortKey)}
                          options={[
                            valuesEpisodesSort["latest"],
                            valuesEpisodesSort["earliest"],
                            valuesEpisodesSort["mostViewed"],
                          ]}
                        />
                      )}
                      {category == "quiz" && (
                        <Select
                          className={`search-categories_container category ${filterCategory}`}
                          classNamePrefix="search-categories"
                          isSearchable={false}
                          value={valuesCategories[filterCategory] || valueAll}
                          onChange={(e) => onCategoryChange(e)}
                          options={[
                            valuesCategories["fnb"],
                            valuesCategories["ope"],
                            valuesCategories["snm"],
                          ]}
                        />
                      )}
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </section>
  );
};

export default SearchBar;
