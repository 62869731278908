import React, { useCallback, useEffect, useState } from "react";
import "./index.scss";
import { searchSeries } from "../../../../utils/data";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const HomeCategoriesBlock = ({ redirectTo }) => {
  const [categories, setCategories] = useState();
  // const [mobileCurrentKey, setMobileCurrentKey] = useState("fb");
  const [totalSeries, setTotalSeries] = useState();

  const { t } = useTranslation();

  const getCategories = useCallback(async () => {
    const [fb, ops, sm] = await Promise.all([
      getSeriesByCatId(process.env.REACT_APP_FB),
      getSeriesByCatId(process.env.REACT_APP_OPERATIONS),
      getSeriesByCatId(process.env.REACT_APP_SM),
    ]);
    setTotalSeries({
      fb: parseInt(
        fb?.headers?.["content-range"]?.split(" ")[1]?.split("/")[1]
      ),
      ops: parseInt(
        ops?.headers?.["content-range"]?.split(" ")[1]?.split("/")[1]
      ),
      sm: parseInt(
        sm?.headers?.["content-range"]?.split(" ")[1]?.split("/")[1]
      ),
    });
    setCategories({ fb, ops, sm });
  }, []);

  const getSeriesByCatId = async (catId) => {
    const filter = {
      category: catId,
      range: "[0, 4]",
      sort: '["createdAt", "DESC"]',
    };
    return await searchSeries(filter);
  };

  useEffect(() => {
    getCategories();
  }, [getCategories]);

  return (
    <div className="home-categories-block">
      <div className="block-list desktop one">
        {/* fb */}
        <div className="block-list-item fb">
          <Link to={"/category/fb"}>
            <div className="block-list-item-header-image">
              <img src="/assets/images/logo-fnb.png" alt="fb" />
            </div>
          </Link>
          <div className="block-list-item__title">{t("FNB_Blank")}</div>
          <div className="block-list-item__series">
            {categories?.fb?.data?.map((series, index) => (
              <Link key={series?.id} to={"/series/" + series?.id}>
                <div className="block-list-item__series__item" title={series?.title}>
                  <div className="block-list-item__series__item__title">
                    {totalSeries["fb"] - index}. {series?.title}
                  </div>
                  <div className="block-list-item__series__item__count">
                    {series?.videos?.length}{" "}
                    {series?.videos?.length > 1
                      ? t("XEpisodes")
                      : t("XEpisode")}
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
        {/* ops */}
        <div className="block-list-item ops">
          <Link to={"/category/operations"}>
            <div className="block-list-item-header-image">
              <img src="/assets/images/logo-ope.png" alt="ops" />
            </div>
          </Link>
          <div className="block-list-item__title">{t("OPSHR_Blank")}</div>
          <div className="block-list-item__series">
            {categories?.ops?.data?.map((series, index) => (
              <Link key={series?.id} to={"/series/" + series?.id}>
                <div className="block-list-item__series__item" title={series?.title}>
                  <div className="block-list-item__series__item__title">
                    {totalSeries["ops"] - index}. {series?.title}
                  </div>
                  <div className="block-list-item__series__item__count">
                    {series?.videos?.length}{" "}
                    {series?.videos?.length > 1
                      ? t("XEpisodes")
                      : t("XEpisode")}
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
        {/* s&m */}
        <div className="block-list-item sm">
          <Link to={"/category/sm"}>
            <div className="block-list-item-header-image">
              <img src="/assets/images/logo-snm.png" alt="s&m" />
            </div>
          </Link>
          <div className="block-list-item__title">{t("SNM_Blank")}</div>
          <div className="block-list-item__series">
            {categories?.sm?.data?.map((series, index) => (
              <Link key={series?.id} to={"/series/" + series?.id}>
                <div className="block-list-item__series__item" title={series?.title}>
                  <div className="block-list-item__series__item__title">
                    {totalSeries["sm"] - index}. {series?.title}
                  </div>
                  <div className="block-list-item__series__item__count">
                    {series?.videos?.length}{" "}
                    {series?.videos?.length > 1
                      ? t("XEpisodes")
                      : t("XEpisode")}
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
      {/* <div className="block-list mobile">
        <div className="tabs">
          <div className="tabs__head">
            <div
              className={`tabs__head__tab-item fb ${
                mobileCurrentKey === "fb" ? "active" : ""
              }`}
              onClick={() => {
                if (mobileCurrentKey === "fb") {
                  redirectTo("/category/fb");
                  return;
                }
                setMobileCurrentKey("fb");
              }}
            >
              <img
                className="tabs__head__tab-item__image"
                src="/assets/images/logo-fnb.png"
                alt="fb"
              />
              <div className="tabs__head__tab-item__title">
                {t("FNB_FULL_NAME")}
              </div>
            </div>
            <div
              className={`tabs__head__tab-item ops ${
                mobileCurrentKey === "ops" ? "active" : ""
              }`}
              onClick={() => {
                if (mobileCurrentKey === "ops") {
                  redirectTo("/category/operations");
                  return;
                }
                setMobileCurrentKey("ops");
              }}
            >
              <img
                className="tabs__head__tab-item__image"
                src="/assets/images/logo-ope.png"
                alt="ope"
              />
              <div className="tabs__head__tab-item__title">
                {t("OPSHR_FULL_NAME")}
              </div>
            </div>
            <div
              className={`tabs__head__tab-item sm ${
                mobileCurrentKey === "sm" ? "active" : ""
              }`}
              onClick={() => {
                if (mobileCurrentKey === "sm") {
                  redirectTo("/category/sm");
                  return;
                }
                setMobileCurrentKey("sm");
              }}
            >
              <img
                className="tabs__head__tab-item__image"
                src="/assets/images/logo-snm.png"
                alt="s&m"
              />
              <div className="tabs__head__tab-item__title">
                {t("SNM_FULL_NAME")}
              </div>
            </div>
          </div>
          <div className="tabs__content">
            {categories?.[mobileCurrentKey]?.data?.map((series, index) => (
              <Link key={series?.id} to={"/series/" + series?.id}>
                <div
                  className={`tabs__content__item ${mobileCurrentKey || ""}`}
                >
                  <div className="tabs__content__item__title">
                    {totalSeries[mobileCurrentKey] - index}. {series?.title}
                  </div>
                  <div className="tabs__content__item__count">
                    {series?.videos?.length}{" "}
                    {series?.videos?.length > 1
                      ? t("XEpisodes")
                      : t("XEpisode")}
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div> */}
      <div className="button-group">
        <Link to="/directory">
          <div className="button redirection" onClick={() => {}}>
            {t("VIEW_ALL_IN_DIRECTORY")}
          </div>
        </Link>
      </div>
    </div>
  );
};

export default HomeCategoriesBlock;
