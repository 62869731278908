import React, { useEffect, useState, Fragment } from "react";
import { getSubmittedQuizzes } from "../../../utils/data";
import { useGlobalContext } from "../../../store/global";
import get from "lodash.get";
import { set } from "lodash";
import { Link } from "react-router-dom";
import moment from "moment";
import { parseCategoryID } from "../../../utils/index";
import { useTranslation } from 'react-i18next'

const Likes = () => {
  const { t } = useTranslation();
  const { closeModal, userAuthed, user } = useGlobalContext();
  const [quizzes, setQuizzes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getSubmittedQuizzes()
      .then((res) => {
        setQuizzes(res.data);
        setIsLoading(false);
      })
      .catch(() => setIsLoading(false));
  }, []);

  return (
    <div className="popup-inner thanks" style={{ maxWidth: "650px" }}>
      <div className="popup-title">
        <h2>{t('MY_QUIZ_HISTORY')}</h2>
        <div className="close icon-x" onClick={closeModal}></div>
      </div>

      <div className="popup-content thanks-content quizzes-modal">
        {isLoading && <span>{t('Loading')}</span>}
        {!isLoading && !quizzes.length ? (
          <span>No quiz history yet</span>
        ) : null}
        {quizzes
          ?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
          .map((q, i) =>
            !q.quiz ? null : (
              <Fragment key={i}>
                {/*{i !== 0 && <div class="separator" />}*/}
                <div className="separator" />
                <div className="quizz-item">
                  {q.passed === true && (
                    <div className="quiz-passed">
                      <img className="icon" src="/assets/images/passed.png" />
                      {t('Passed')}
                    </div>
                  )}
                  {q.passed === false && (
                    <div className="quiz-failed">{t('Failed')}</div>
                  )}
                  <div className="info-wrap">
                    <h3
                      className={parseCategoryID(q?.category?.id).categorySlug}
                    >
                      {q?.quiz?.title}
                    </h3>
                    <span className="score">{q.score}/100</span>
                    <span className="date">
                      {moment(q.createdAt).format("DD/MM/YYYY")}
                    </span>
                  </div>
                  {!!q?.quiz?.id && (
                    <Link onClick={closeModal} to={`/quiz?id=${q?.quiz?.id}`}>
                      {t('Retake')}
                    </Link>
                  )}
                </div>
              </Fragment>
            )
          )}
      </div>
    </div>
  );
};

export default Likes;
