import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import SeriesHero from "./seriesHero";
import SeriesVideos from "./seriesVideos";
import SeriesSlider from "../../ui/seriesSlider";
import {
  getNthItemFromPathname,
  parseCategoryID,
  seriesFilter,
} from "../../../utils";
import {
  getSeriesById,
  getSeriesByCategory,
  searchSeries,
} from "../../../utils/data";
import Banner1 from "../../layout/banner1";
import get from "lodash.get";
import QuizItem from "../../ui/QuizItem";
import { useGlobalContext } from "../../../store/global";

import "./index.scss";
import { useTranslation } from "react-i18next";

const Series = (props) => {
  const { setLoading } = useGlobalContext();
  const { t } = useTranslation();

  // series category
  const [pageData, setPageData] = useState({
    seriesData: {},
    seriesVideos: [],
    seriesBySpeaker: [],
    seriesByCategory: [],
    seriesFromSearch: [],
  });
  useEffect(() => {
    const seriesId = getNthItemFromPathname(props.location.pathname, 2);

    async function getPageData() {
      setLoading(true);
      const seriesData = await getSeriesById(seriesId);
      let speakerId;
      let seriesBySpeaker;
      if (get(seriesData, "data.speakers[0].id", "") !== "") {
        speakerId = seriesData.data.speakers[0].id;
        seriesBySpeaker = await getSeriesByCategory("speakers", speakerId);
        seriesBySpeaker = seriesBySpeaker?.data?.filter(serie => {
          return serie?.id !== seriesId;
        })
      }
      const seriesSearchData = await searchSeries({ id: seriesId });
      const seriesByCategory = await getSeriesByCategory(
        "category",
        seriesData.data.category
      );
      setPageData((prevState) => ({
        ...prevState,
        seriesData: seriesData.data,
        seriesVideos: seriesData.data.videos,
        seriesQuiz: seriesData.data.quiz,
        seriesBySpeaker: seriesBySpeaker || [],
        seriesByCategory: seriesByCategory.data,
        seriesFromSearch: seriesSearchData.data,
      }));
      setLoading(false);
    }
    try {
      getPageData();
    } catch {
      props.history.replace("/notfound");
    }
  }, [props.location.pathname]);

  return (
    <>
      <div id="content">
        <div className="back-button" onClick={() => props.history.goBack()}>
          <span className="icon-arrowup2"></span>{t("Back")}
        </div>
        <SeriesHero
          data={pageData.seriesData}
          seriesFromSearch={pageData.seriesFromSearch}
        />

        {pageData?.seriesQuiz && (
          <section className="series">
            <div className="viewport">
              <div className="inner">
                <div className="quiz-block">
                  <div className="quiz-time-logo">
                    <img
                      src="/assets/images/quiz_time_logo.svg"
                      alt="quiz-time-logo"
                    />
                  </div>
                  <div className="quiz-block-item">
                    <QuizItem quiz={pageData.seriesQuiz} />
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
        <SeriesVideos
          videos={pageData.seriesVideos.filter(seriesFilter)}
          ascOrder
        />
        {Boolean(pageData?.seriesBySpeaker?.length) && 
        <section className="series">
          <div className="viewport">
            <div className="inner">
              <h2 className="title">{t("Other_series_by_this_speaker")}</h2>
              <SeriesSlider
                series={pageData.seriesBySpeaker}
                sliderId={Math.random()}
                sorted
              />
            </div>
          </div>
        </section>}
        {Boolean(pageData?.seriesByCategory?.length) &&
        <section className="series">
          <div className="viewport">
            <div className="inner">
              <h2 className="title">{`${
                t(parseCategoryID(pageData.seriesData.category).categoryTranslationKey)
              } ${(pageData?.seriesByCategory?.length || 0) > 1 ? t("XSeries") : t("XSerie")}`}</h2>
              <SeriesSlider
                series={pageData.seriesByCategory}
                sliderId={Math.random()}
                sorted
              />
            </div>
          </div>
        </section>}
      </div>
      <Banner1 />
    </>
  );
};

export default withRouter(Series);
