import React, { useEffect } from 'react';
import VideoItem from '../../ui/VideoItem';
import QuizItem from '../../ui/QuizItem';
import { useTranslation } from 'react-i18next'

export default function SearchResults(props) {
  const { videos, limit, isInSearch } = props;

  const { t } = useTranslation();
  
  useEffect(() => {
    videos.map(video => {
      if (!video.id && video._id) {
        video.id = video._id;
      }
      if (!video.category.id && video.category._id) {
        video.category.id = video.category._id;
      }
    })
  })

  return (
    <section className="series-videos in-search-page">
      <div className="viewport">
        <div className="inner">
          <div className="video-list">
            {videos.map((v, i) => {
              return i < limit ? <VideoItem key={v.id} video={v} isInSearch={isInSearch ? true : false} /> : null;
            })}
          </div>
        </div>
      </div>
    </section>
  );
}

SearchResults.Quiz = (props) => {
  const { videos, limit, t, isSustainability } = props;

  return (
    <section className="series-videos in-search-page">
      <div className="viewport">
        <div className="inner">
          <h2 className="title">{t('All_Quizzes')}</h2>
          <div className="video-list">
            {videos.map((v, i) => i < limit ? <QuizItem key={v.id} quiz={v} isSustainability={isSustainability ? true: false} /> : null)}
          </div>
        </div>
      </div>
    </section>
  );
}