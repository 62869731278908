import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactTypeMap } from "./../../../../utils/index";

import "./index.scss";

const ReactButton = ({ id, likeWithType, userLike, userLikeType }) => {
  const [open, setOpen] = useState(false);
  const [timer, setTimer] = useState();
  const [currentType, setCurrentType] = useState();
  const { t } = useTranslation();

  useEffect(() => {
    setOpen(false);
  },[id])

  return (
    <div className="react-btn">
      <div
        className="react-btn-trigger"
        onClick={() => {
          if(userLike){
            likeWithType(false);
          }else{
            setOpen(true);
          }
        }}
        onMouseEnter={() => {
          if(open || userLike) return;
          setOpen(true);
        }}
        onMouseLeave={() => {
          if(open) {
            if(timer) clearTimeout(timer);
            const newTimer = setTimeout(() => {
              setOpen(false);
            },1500);
            setTimer(newTimer);
          }
        }}
      >
        <div className="react-icon">
          {userLike && userLikeType !== undefined && userLikeType !== null ? (
            <img src={ReactTypeMap?.[userLikeType]?.icon} alt="react" />
          ) : (
            <img src="/assets/icons-svg/react-icon.svg" alt="react" />
          )}
        </div>
        <div className="react-text">{userLike ? t(ReactTypeMap?.[userLikeType]?.activatedText) : t("React")}</div>
      </div>
      {open && (
        <div 
        onMouseEnter={() => {
          if(timer) clearTimeout(timer);
        }}
        onMouseLeave={() => {
          if(open) {
            if(timer) clearTimeout(timer);
            const newTimer = setTimeout(() => {
              setOpen(false);
            },1500);
            setTimer(newTimer);
          }
        }} className="react-popup">
          <div className={`react-popup-inner ${currentType !== undefined ? "active" : ""}`}>
            {ReactTypeMap.map((item, index) => (
              <div
                onMouseEnter={() => {
                  setCurrentType(index);
                }}
                onMouseLeave={() => {
                  setCurrentType(undefined);
                }}
                onClick={() => {
                  if (userLikeType === index) {
                    likeWithType(false);
                  }else{
                    likeWithType(true, index);
                  }
                  setCurrentType(undefined);
                  setOpen(false);
                }}
                className={`react-popup-item ${currentType === index ? "active" : currentType === undefined ? "" : "inactive"}`}
                key={index}
              >
                <div className="react-popup-item__icon">
                  <img src={item.icon} alt="star" />
                </div>
                <div className="react-popup-item__text">{t(item.text)}</div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default ReactButton;
