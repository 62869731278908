import React, { useState } from "react";
import { Link } from "react-router-dom";
import get from "lodash.get";
import SwiperCore, { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import moment from "moment";
import { orderByOrder } from "../../../../utils";
import { parseCategoryID } from "../../../../utils";
import PlayButton from "../../../ui/PlayButton";
import { useTranslation } from "react-i18next";

import "./index.scss";

export default function VideoSeries(props) {
  const { series, quiz, selectedIndex } = props;
  series.sort(orderByOrder);
  console.log("SERIES", series);
  console.log("QUIZ", quiz);
  const [swiper, setSwiper] = useState(null);
  const initSwiper = (s) => {
    setSwiper(s);
    SwiperCore.use([Navigation]);
    const prev = document.getElementById("prevbutton");
    const next = document.getElementById("nextbutton");
  };
  return (
    <Swiper
      onSwiper={(swiper) => initSwiper(swiper)}
      className="o-video"
      slidesPerView={1}
      spaceBetween={10}
      navigation={{
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      }}
    >
      <div className="swiper-wrapper">
        {series &&
          series.map((v, i) =>
            moment(v.video.releaseDate).isBefore(moment()) ||
            moment(v.video.releaseDate).isSame(moment(), "day") ? (
              <Element episode={v} isSelected={selectedIndex === i} />
            ) : null
          )}
        {quiz && (
          <Element
            isSelected={selectedIndex === quiz.id}
            type="quiz"
            episode={{
              video: {
                id: quiz.id,
                title: quiz.title,
                videoThumbnail: {
                  uri: get(quiz, "thumbnail.uri", ""),
                },
              },
            }}
          />
        )}
      </div>
      <div
        id="prevbutton"
        className="swiper-button-prev"
        onClick={() => swiper.slidePrev()}
      ></div>
      <div
        id="nextbutton"
        className="swiper-button-next"
        onClick={() => swiper.slideNext()}
      ></div>
    </Swiper>
  );
}

const Element = ({ episode, isSelected, type = "video" }) => (
  <SwiperSlide
    key={episode.video.id}
    className="swiper-slide"
    style={{ background: `${isSelected ? "#363636" : "#1F1F1F"}` }}
  >
    <Link
      to={`/${type}?id=${episode.video.id}`}
      style={{ color: "white", textDecoration: "none" }}
    >
      <div className="video-wrapper list-wrapper">
        {type === "video" && (
          <div className="play">
            <span className="icon-playbuttonsmall"></span>
          </div>
        )}
        <div className="cover"></div>
        <div className="medias">
          {get(episode, "video.videoThumbnail.uri", "") !== "" && (
            <span
              style={{
                backgroundImage: `url(${
                  process.env.REACT_APP_IMG_URL
                }/storage/${type}-thumbnail/${get(
                  episode,
                  "video.videoThumbnail.uri",
                  ""
                )}`,
              }}
            ></span>
          )}
          {type === "video" &&
          get(episode, "video.videoThumbnail.uri", "") === "" ? (
            <iframe
              src={`//fast.wistia.net/embed/iframe/${episode.video.wistiaHashId}?fitStrategy=cover&controls=false&endVideoBehavior=loop&playbar=false&playButton=false&settingsControl=false&volumeControl=false&silentAutoplay=allow&smallPlayButton=false&fullscreenButton=false`}
              frameBorder="0"
              scrolling="no"
              name="hero_embed"
              title="hero_embed"
            ></iframe>
          ) : null}
        </div>
      </div>
      <div className="video-infos">
        <div className="video-title">{episode.video.title}</div>
      </div>
    </Link>
  </SwiperSlide>
);
//
export function VideoSeries2(props) {
  const { series, selectedIndex } = props;
  const [swiper, setSwiper] = useState(null);
  const { t } = useTranslation();

  const initSwiper = (s) => {
    setSwiper(s);
    SwiperCore.use([Navigation]);
  };

  const parseTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.round(time - minutes * 60, 2);
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  return (
    <div className="series-video-container">
      <div className="desktop">
        <div className="series-video-list">
          {series.length &&
            series
              .filter(
                (v) =>
                  moment(v.video.releaseDate).isBefore(moment()) ||
                  moment(v.video.releaseDate).isSame(moment(), "day")
              )
              .map((s, i) => {
                const current = selectedIndex === i;
                const completed = s.video.userComplete;
                return (
                  <Link to={`/video?id=${s.video.id}`} key={s._id}>
                    <div
                      className={`series-video-list__item ${
                        parseCategoryID(s.video.category).categoryShorthand
                      }`}
                    >
                      <div className="series-video-list__item__thumbnail">
                        <div className="series-video-list__item__thumbnail__video">
                          <div className="series-video-list__item__thumbnail__video__cover">
                            <div className="series-video-list__item__thumbnail__video__cover__play">
                              <PlayButton />
                            </div>
                          </div>
                          <iframe
                            src={`//fast.wistia.net/embed/iframe/${s.video.wistiaHashId}?autoplay=false&fitStrategy=cover&controls=false&endVideoBehavior=loop&playbar=false&playButton=false&settingsControl=false&volumeControl=false&silentAutoplay=allow&smallPlayButton=false&fullscreenButton=false`}
                            frameBorder="0"
                            scrolling="no"
                            name="hero_embed"
                            title="hero_embed"
                          ></iframe>
                        </div>
                      </div>
                      <div className={`
                        series-video-list__item__info 
                        ${completed ? "completed" : ""}
                        ${current ? parseCategoryID(s.video.category).categoryShorthand : ""}
                        `}>
                        {current && 
                        <div className="series-video-list__item__info__play">
                          <span className="icon-playbutton-noborder"></span>
                        </div>}
                        <div className="series-video-list__item__info__block">
                          <div className="series-video-list__item__info__block__title" title={`${i + 1}-${s?.video?.description}`}>
                            {i + 1}-{s?.video?.description}
                          </div>
                          <div className="series-video-list__item__info__block__rest">
                            <div className="series-video-list__item__info__block__rest__duration">{parseTime(s?.video?.duration)}</div>
                            {completed && !current && 
                            <div className={`series-video-list__item__info__block__rest__viewed ${parseCategoryID(s.video.category).categoryShorthand}`}>
                              <span className="icon-check"></span>
                              <span>{t("Viewed")}</span>
                            </div>}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                );
              })}
        </div>
      </div>
      <div className="mobile">
        <Swiper
          onSwiper={(swiper) => initSwiper(swiper)}
          className="r-video"
          slidesPerView={1}
          spaceBetween={10}
          navigation={{
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          }}
        >
          <div className="swiper-wrapper">
            {series.length &&
              series
                .filter(
                  (v) =>
                    moment(v.video.releaseDate).isBefore(moment()) ||
                    moment(v.video.releaseDate).isSame(moment(), "day")
                )
                .map((s, i) => {
                  const current = selectedIndex === i;
                  const completed = s.video.userComplete;
                  return (
                    <SwiperSlide
                      key={s._id}
                      className={[
                        "swiper-slide",
                        selectedIndex === i
                          ? parseCategoryID(s.video.category).categoryShorthand
                          : "",
                      ].join(" ")}
                    >
                      <Link
                        to={`/video?id=${s.video.id}`}
                        style={{ color: "white", textDecoration: "none" }}
                      >
                        <div className="video-wrapper video-list-item-under-video-page">
                          {selectedIndex !== i && (
                            <div className="play">
                              <span className="icon-playbuttonsmall"></span>
                            </div>
                          )}
                          <div
                            className="medias"
                            style={{
                              position: "absolute",
                              top: 0,
                              left: 0,
                              width: "100%",
                              height: "100%",
                            }}
                          >
                            <div
                              style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "100%",
                                height: "100%",
                                zIndex: 1,
                              }}
                            />
                            <iframe
                              style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "100%",
                                height: "100%",
                                zIndex: 0,
                              }}
                              src={`//fast.wistia.net/embed/iframe/${s.video.wistiaHashId}?autoplay=false&fitStrategy=cover&controls=false&endVideoBehavior=loop&playbar=false&playButton=false&settingsControl=false&volumeControl=false&silentAutoplay=allow&smallPlayButton=false&fullscreenButton=false`}
                              frameBorder="0"
                              scrolling="no"
                              name="hero_embed"
                              title="hero_embed"
                            ></iframe>
                          </div>
                        </div>
                        <div
                          className={`video-infos ${
                            current
                              ? "current"
                              : completed
                              ? "video-user-viewed"
                              : ""
                          }`}
                        >
                          <div className="video-title" title={s.video?.title}>
                            {selectedIndex === i && (
                              <span className="icon-playbutton-noborder"></span>
                            )}
                            <span>{i + 1}-{s.video?.description}</span>
                          </div>
                          <div className="video-detail">
                            <span className="time">
                              {parseTime(s.video.duration)}
                            </span>
                            <div className="left-info">
                              {s.video.userComplete && (
                                <span
                                  className={`viewed ${
                                    parseCategoryID(s.video.category)
                                      .categoryShorthand
                                  }`}
                                >
                                  <span className="icon-check"></span>
                                  <span className="viewd-text">
                                    {t("Viewed")}
                                  </span>
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      </Link>
                    </SwiperSlide>
                  );
                })}
          </div>
          <div
            id="prevbutton"
            className="swiper-button-prev"
            onClick={() => swiper.slidePrev()}
          ></div>
          <div
            id="nextbutton"
            className="swiper-button-next"
            onClick={() => swiper.slideNext()}
          ></div>
        </Swiper>
      </div>
    </div>
  );
}
