import React, { useEffect, useState } from "react";
import "./index.scss";

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";

import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import QuizItem from "../../../ui/QuizItem";

const HomeQuizzesSwiper = (props) => {
  const { quizzes } = props;
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleResize = () => {
    setInnerWidth(window.innerWidth);
  };

  const [swiper, setSwiper] = useState(null);
  SwiperCore.use([Navigation]);
  const initSwiper = (s) => {
    setSwiper(s);
  };

  useEffect(() => {
    if (activeIndex) {
      // eslint-disable-next-line no-unused-expressions
      document.getElementById("indicator-" + activeIndex)?.scrollIntoView({
        behavior: "smooth",
        block: "nearest", // 垂直对齐方式
        inline: "center", // 水平对齐方式
      });
    }
  }, [activeIndex]);

  return (
    <div className="home-quizzes-block">
      <div className="home-quizzes-title">
        <img src="/assets/images/quiz_time_logo.svg" alt="" />
      </div>
      <Swiper
        className="home-quizzes-swiper"
        spaceBetween={innerWidth >= 768 ? 0 : 20}
        slidesPerView={"auto"}
        navigation={{
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        }}
        onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)}
        onSwiper={(swiper) => initSwiper(swiper)}
      >
        {quizzes?.map((quiz) => (
          <SwiperSlide className="home-quizzes-swiper-item" key={quiz?.id}>
            <QuizItem quiz={quiz} />
          </SwiperSlide>
        ))}
        <div className="swiper-button-prev swiper-button-disabled"></div>
        <div className="swiper-button-next"></div>
      </Swiper>
      <div className="indicator-holder">
        <div id="indicators" className="indicator-wrapper">
          {quizzes?.map((quiz, index) => (
            <div
              id={`indicator-${index}`}
              onClick={() => {
                if (swiper) {
                  swiper.slideTo(index);
                }
              }}
              key={quiz?.id}
              className={`indicator ${activeIndex === index ? "active" : ""}`}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default HomeQuizzesSwiper;
