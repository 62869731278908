const mandarin = {
  // Menu
  Home: "主页",
  FNB: "餐饮",
  FNB_Blank: "餐饮",
  OPSHR: "运营｜人力资源",
  OPSHR_Blank: "运营｜人力资源",
  SNM: "销售&市场",
  SNM_Blank: "销售&市场",
  Search: "搜索",
  Search_Place_Holder: "系列，测验，主讲人",
  Series: "系列",
  Quizzes: "测验",
  Speakers: "主讲人",
  Speaker: "主讲人",
  Leaderboard: "榜单",
  My_Profile: "我的资料",
  My_Lists: "我的列表",
  My_Quizzes: "我的测验",
  Log_Out: "退出账号",
  VIEW_ALL_IN_DIRECTORY: "在目录中查看全部",

  // Home page
  WELCOME_BACK: "欢迎回来！",
  RECOMMENDED_FOR_YOU: "为您推荐",
  Episodes: "视频",
  My_Episodes: "我的视频",
  My_Quizzes_Title: "我的测验",
  //Quizzes(duplicated)
  INSPIRATION: "启发",
  DIRECTION: "引导",
  EXCELLENCE: "卓越",
  APPLICATION: "运用",
  SOLUTION: "解决",
  Home_Page_Content:
    "I.D.E.A.S 2023 是什么？ 通过每周一发布的 5 分钟易懂易学小视频，让你可以从 50 多位管理者的分享中汲取知识并获得灵感，随时随地自主学习。这款寓教于乐型学习工具，致力于帮助大家成为更好的管理者并更多地关爱彼此。学员通过互动测验可以学习如何更好地管理酒店客房、餐厅和酒吧，了解更多的销售技巧和数字通信的最佳实践，掌握更多宴会销售、企划和运营的解决方案。特此献给所有亚太地区凯悦集团酒店行政委员会及所有相关团队。",
  Countries: "国家",
  Markets: "市场",
  Hotels: "酒店",
  //Series(duplicated),
  //Episodes(duplicated),
  //Quizzes(duplicated),
  Users: "用户",
  We_Love_Mondays: "我们❤️周一",
  Viewed: "已观看",
  Views: "观看",
  Load_More: "加载更多",
  Explore: "探索",
  //F&B(duplicated)
  //OPS | HR(duplicated)
  //S&M(duplicated)
  Trending_Series: "热门系列",
  Dig_Use_Share_Anywhere_Anytime: "Dig, use & share\nanywhere, anytime",
  With: "和 ",
  Videos: " 视频",
  Trending_Speakers: "热门主讲人",
  Discover_Series: "发现系列",
  //return by backend,
  //return by backend,
  //return by backend,
  Have_Suggestions_For_Improvements: "您有帮助我们改进的建议？",
  Give_Us_Your_Ideas: "请把您的想法告诉我们",
  Terms_And_Conditions: "条款和条件",
  Focus: "关注",

  // EXPLORE ALL
  EXPLORE_THE_LIBRARY: "探索图书馆",
  Search_By_Theme_Topi_Or_Speaker: "按主题、话题或主讲人搜索",
  SERIES: "系列",
  EPISODES: "视频",
  QUIZZES: "测验",
  QUIZ: "测验",
  Watch_The_Series: "观看该系列",
  START: "开始",
  RETAKE: "再次尝试",
  REVIEW: "回顾",
  CONTINUE: "继续",
  CERTIFICATE: "证书",
  passed: "通过",
  failed: "未通过",
  inProgress: "进行中",
  notStarted: "暂未开始",
  Discover_More: "发现更多",
  All: "所有",
  Loading: "加载中...",

  // CATEGORY
  //Series(duplicated),
  Search_For_Series: "按系列搜索",
  Latest_Series: "最新系列",
  All_Series: "所有系列",
  View_Details: "查看详情",
  Watch: "观看",
  XEpisodes: " 集",
  XEpisode: " 集",
  Ready_For_A_Quiz: "准备好进行测验获取更多得分了吗？",
  Start: "现在开始",
  //Episodes(duplicated),
  //Search by theme, topic or speaker(duplicated),
  Sort_By: "排序方式",
  Default: "默认",
  Latest: "最新",
  Earliest: "最早",
  Most_Viewed: "最多观看",
  All_Episodes: "所有视频",
  //Quizzes(duplicated),
  Search_For_Quizzes: "按测验搜索",
  Recommended_For_You: "为您推荐",
  All_Quizzes: "所有测验",
  //Watch_The_Series(duplicated),
  Re_take: "再次尝试",
  //Watch_The_Series(duplicated),
  //Start: '',
  Passed: "通过",
  Failed: "未通过",
  //Discover more(duplicated),

  // EPISODE PAGE
  //[X] Episodes(duplicated),
  Related_Series: "相关系列",
  Share_Video: "分享视频",
  Watch_Later: "稍后观看",
  XView: " 次观看",
  XViews: " 次观看",
  //Speakers(duplicated),
  Released_On: "发布于{{date}}",
  Discussion: "讨论",
  XComments: " 条评论",
  XComment: " 条评论",
  Reply: "回复",
  Enter_Your_Comment_Here: "在此添加您的评论",
  Cancel: "取消",
  Submit: "发布",
  Back_To_Top: "回到顶端",

  // LEADERBOARD
  Weekly_Leaderboard: "每周榜单",
  Expert_Viewer: "专业观众",
  Biggest_Viewed: "过去一周观看视频集数最多的观众",
  Fastest_Viewer: "早鸟观众",
  Woke_Up_Earilest: "本国观看视频的首位观众",
  Star_Contributor: "人气评论员",
  Posted_Greatest: "发表评论并获得最多点赞的观众",

  // PROFILE PAGE
  EDIT_PROFILE: "编辑资料",
  SHOW_HISTORY: "显示记录",
  MY_QUIZ_HISTORY: "我的测验记录",
  RE_TAKE: "再次尝试",
  BADGES: "奖章",
  //Expert_Viewer(duplicated)
  //Fatest_Viewer(duplicated)
  //Star_Contributor(duplicated)
  //QUIZZES(duplicated)
  PERFORMANCE: "表现",
  //F&B(duplicated)
  //OPS | HR(duplicated)
  //S&M(duplicated)
  Pts: "得分",
  PROGRESS: "进度",
  MY_ACTIVITY: "我的活动",
  MonthAndYear: "月，年",
  VIDEOS: "视频",
  QUIZZES_TAKEN: "参加测验",
  QUIZZES_PASSED: "通过测验",
  COMMENTS: "评论",
  // ???
  Videos_Watched: "观看视频",
  Quizzes_Taken: "参加测验",
  Comments: "评论",
  Week_1: "第2周",
  Week_2: "第2周",
  Week_3: "第3周",
  Week_4: "第4周",
  Week_5: "第5周",

  // NOTIFICATION
  Notification: "通知",
  Discover: "发现",
  Take_The_Quiz: "开始测验",

  // MY LISTS
  WATCH_LATER: "稍后观看",
  XVideos: " 个视频",
  XVideo: " 个视频",
  VIEWED: "已观看",
  Yesterday: "昨天",
  // ???
  Clear: "清除",
  Watc_Again: "再次观看",
  LIKED: "已赞",
  //Xvideos(duplicated)
  // OTHER USER PROFILE
  Back: "返回",
  Badges: "奖章",
  //Expert viewer(duplicated)
  //Fatest viewer(duplicated)
  //Star contributor(duplicated)
  //Quizzes(duplicated)
  //F&B(duplicated)
  //OPS | HR(duplicated)
  //S&M(duplicated)
  //Pts(duplicated)
  LIKED_VIDEOS: "赞过的视频",
  No_Result_For_Your_Search: "没有与您的搜索匹配的结果",

  // QUIZ INTRODUCTION
  Do_You_Remember: "",
  XQuestions: " 问题",
  //START(duplicated)
  NEXT: "下一个",
  PREVIOUS: "上一个",

  // QUIZ RESULT
  You_Pass_GIF: "",
  Your_Score_Is: "您的得分是...",
  GIF_PASSED: "",
  Retake: "再次尝试",
  GIF_FAILED: "",
  XPoints: " 得分",
  Xpoints: " 得分",
  Dont_Worry: "不要担心。让我们看看哪里出了问题，理解问题，找到正确答案。",
  Help_Me_Pass: "助我通过",
  //Retake(duplicated)
  Almost_There: "快成功了！",
  Help_Description: `<1>您已经答对了 {{correctCount}} 个问题。</1> <2>以下是您错过的问题：</2>`,
  Find_The_Correct_Answer: `在<1> {{relatedEpisode}} </1>中找到正确答案`,
  Learn_From_Series: "从该系列中学习",
  Quizz_Welcome:
    "<p>您还记得关于 {{title}} 最重要的内容吗？</p> <p>通过这个互动测验测试你的知识，得分达到80分或以上...</p> <p>如果第一次没有通过请不用担心，你可以继续尝试，直到通过为止。</p>",
  Xquestions: " 问题",
  //Retake(duplicated)

  // Lost

  // Menu
  Explore_All: "探索更多",
  No_Result_Found: "没有与您的搜索匹配的结果",
  Take_The_Quizz: "",

  // Home page
  Play_Episode: "播放视频",
  Start_Quiz: "开始测验",
  Visit_Now: "立即访问",
  Certificates: "证书",

  // Explore All
  Filter_By: "筛选",

  // Video / Episode
  In_Watch_List: "在观看列表中",
  Remove_From_Watchlist: "从观看列表中移除",
  Link_Copied: "视频链接已复制成功，去和你的同事们分享吧!",
  Xmins: "分钟",

  // Profile page
  Comments_Posted: "发布评论",
  January: "一月",
  February: "二月",
  March: "三月",
  April: "四月",
  May: "五月",
  June: "六月",
  July: "七月",
  August: "八月",
  September: "九月",
  October: "十月",
  November: "十一月",
  December: "十二月",
  Jan: "一月",
  Feb: "二月",
  Mar: "三月",
  Apr: "四月",
  Jun: "六月",
  Jul: "七月",
  Aug: "八月",
  Sep: "九月",
  Oct: "十月",
  Nov: "十一月",
  Dec: "十二月",
  DOWNLOAD_ALL_CERTIFICATES: "DOWNLOAD ALL CERTIFICATES",

  // Profile Edit page

  // My List page
  Today: "今天",
  A_Week_Ago: "一周前",
  A_Month_Ago: "一个月前",

  // Quizz page
  There_Are_More: "这个问题还有更多正确答案。再看看吧！",

  // 404 page
  Not_Be_Found: "无法找到所请求的资源。",
  Check_URL: "请检查网址并重试。",

  // Sustainability page
  sustainability: "可持续发展",
  sustainability_solgan: "减少食物浪费，消除塑料废物，开展环保行动",
  sustainability_description: "一个健康的环境对于每个人的尽善尽美至关重要",

  //Update
  Hotel_Statistics: "酒店数据",
  Directory: "目录",
  "Welcome_to_I.D.E.A.S":
    "欢迎来到I.D.E.A.S 2024！通过每周一发布的5分钟易懂易学的大师分享视频，您可以从60多位管理者那里汲取知识、增强技能。按照自己的节奏，随时随地灵活学习。我们的平台发布互动式测验，并附有经过认证的证书，以提高您对酒店客房、餐厅、酒吧和活动的管理水平。探索有效的酒店销售技巧、数字战略和最佳实践。学习创新的销售、活动策划和运营方法，成为领导者，并培养自己的团队掌握更多宴会销售、企划和运营的解决方案。专为所有凯悦酒店集团亚太地区的领导委员会及其团队量身定制。",
  HYATT: "餐饮摄影集",
  PHOTOGRAPHY: "影集",
  "Dig_Use_&_Share": "挖掘，利用，分享",
  Anywhere_Anytime: "任何地点，任何时间",
  MoodBoard: "情绪版",
  "GLOBAL_HYATT_F&B_PHOTOGRAPHY": "凯悦全球\n活动+餐饮照片集",
  Leaderboard_Badges: "排行榜徽章",
  Show_Full_History: "查看全部历史",
  "Average_of_users_have_watched...": "平均每个用户收看",
  "...videos": "集",
  "Average_of_users_have_taken...": " 平均每个用户完成",
  "...number_of_quizzes": "个测验",
  "Average_of_users_have_passed...": " 平均每个用户通过",
  "...quizzes": "个测验",
  Image_upload: "上传图片",
  Enter_your_comment_here: "在此输入您的评论",
  Series_summary: "系列摘要",
  Download: "下载",
  Completions: "完成",
  view: "（次）观看",
  views: "（次）观看",
  point: "得分",
  points: "得分",
  Recipe: "食谱",
  Recipes: "食谱",
  Search_for_recipes: "搜索食谱",
  "Our_mission_is_to...":
    "我们的使命是策划一系列引人入胜的图片，阐明每家酒店的烹饪叙事。在这里，您将找到灵感和鼓励，以帮助您创作自己的摄影杰作，同时与凯悦酒店的餐饮创新精神共鸣，长久地展示您在餐饮上的卓越。",
  "All_of_the_images...":
    "本系列中的所有图片均由凯悦酒店制作，版权归凯悦酒店集团所有。这些图片仅供全球所有凯悦酒店使用。如要访问高分辨率版本图片，请登录品牌经理平台或点击",
  here: "此处",
  "...platform_or_by_clicking": "。",
  HYATT_GLOBAL: "凯悦全球",
  "EVENTS+F&B": "活动+餐饮",
  LIBRARY: "照片集",
  No_result_for_your_search: "没有与您的搜索匹配的结果",

  "like.star": "哇",
  "like.like": "点赞",
  "like.heart": "喜欢",
  "like.idea": "富有洞察力",

  Well_done: "做得好",
  quiz_invite:
    "你已经完成了观看{{name}}的所有视频，您现在想做测验来测试您的知识吗？",

  Taken_on: "测试于",
  Top_10: "前十名",
  See_all: "查看全部",

  FNB_FULL_NAME: "餐饮",
  OPSHR_FULL_NAME: "运营｜人力资源",
  SNM_FULL_NAME: "销售&市场",

  Empty_series_result: "未找到系列",
  New_tag: "最新",

  Other_series_by_this_speaker: "该讲者的系列",

  REACTED: "已赞",
  You_Reacted: "你已点赞",
  You_plus_x_others: "你和{{count}}其他人点赞了",

  quiz_description_line1: "通过这个互动测验测试你的知识，得分达到80分或以上...",
  quiz_description_line2:
    "如果第一次没有通过请不用担心，你可以继续尝试，直到通过为止。",

  XSeries: "系列",
  XSerie: "系列",

  total_points: "总积分",
  download_all_certificates: "下载所有证书",

  comment_liked: "新点赞",
  comment_replied: "新回复",
  award_obtained: "恭喜",
  new_quiz: "新测验上线！",
  new_leaderboard: "排行榜",

  image_comment_hint: "图片评论应小于30MB",

  Edit: "编辑",
  Save: "保存",
  Delete: "删除",

  Started_at: "开始于",
  React: "点赞",

  Thank_you_for_your_comment: "感谢你的评论",
  Your_comment_has_been_added: "你的评论已添加",
  Back_to_IDEAS: "返回 I.D.E.A.S.",

  Please_confirm: "请确认",
  Are_you_sure_you_want_to_delete_your_comment: "确定要删除你的评论吗？",

  Comment_removed: "评论已删除",
  Your_comment_has_been_removed: "你的评论已删除",

  Thank_you: "谢谢",
  Your_comment_changes_have_been_saved: "你的评论更改已保存",

  Our_champions: "我们的冠军",
  Total_users: "总用户数",

  Quiz: "测验",
  Video_views: "观看视频",

  Edit_your_profile: "编辑个人资料",
  Upload_image: "上传图片",
  Upload_image_hint: "(限制 20MB)",
  Name: "名字",
  Surname: "姓氏",
  Country_Region: "国家/地区",
  City: "城市",
  Brand: "品牌",
  Hotel: "酒店",

  Search_a_series: "搜索系列",
};

export default mandarin;
