const english = {
  // Menu
  Home: "Home",
  All_Categories: "All Categories",
  FNB: "F&B",
  FNB_Blank: "F & B",
  OPSHR: "OPS | HR",
  OPSHR_Blank: "OPS | HR",
  SNM: "S&M",
  SNM_Blank: "S & M",
  Search: "Search",
  Search_Place_Holder: "Series, quizzes, speakers",
  Series: "Series",
  Quizzes: "Quizzes",
  Speakers: "Speakers",
  Speaker: "Speaker",
  Leaderboard: "Leaderboard",
  My_Profile: "My profile",
  My_Lists: "My lists",
  My_Quizzes: "My quizzes",
  Log_Out: "Log out",
  VIEW_ALL_IN_DIRECTORY: "VIEW ALL IN DIRECTORY",

  // Home page
  WELCOME_BACK: "WELCOME BACK!",
  RECOMMENDED_FOR_YOU: "RECOMMENDED FOR YOU",
  Episodes: "Episodes",
  My_Episodes: "My Episodes",
  My_Quizzes_Title: "My Quizzes",
  //Quizzes(duplicated)
  INSPIRATION: "INSPIRATION",
  DIRECTION: "DIRECTION",
  EXCELLENCE: "EXCELLENCE",
  APPLICATION: "APPLICATION",
  SOLUTION: "SOLUTION",
  Home_Page_Content:
    "What is I.D.E.A.S 2023? Learn & get inspired from over 50 leaders with multiple easy-to-watch weekly 5-minute videos released on Mondays. Stream anytime, anywhere, at your own pace. Edutainment (Educational Entertainment) tool with interactive quizzes to help you better manage Rooms, Restaurants, and Bars. Selling technics, digital & communications best practice. Solutions to better sell, plan & operate Events. Become a better leader & care for our People. Dedicated to all Hyatt Asia Pacific Hotels’ leadership committees & their respective teams.",
  Countries: "Countries",
  Markets: "Market",
  Hotels: "Hotels",
  //Series(duplicated),
  //Episodes(duplicated),
  //Quizzes(duplicated),
  Users: "Users",
  We_Love_Mondays: "We Love Mondays",
  Viewed: "Viewed",
  Views: "Views",
  Load_More: "Load more",
  Explore: "Explore",
  //F&B(duplicated)
  //OPS | HR(duplicated)
  //S&M(duplicated)
  Trending_Series: "Trending series",
  Dig_Use_Share_Anywhere_Anytime: "Dig, use & share\nanywhere, anytime",
  With: "With ",
  Videos: " Videos",
  Trending_Speakers: "Trending speakers",
  Discover_Series: "Discover series",
  //return by backend,
  //return by backend,
  //return by backend,
  Have_Suggestions_For_Improvements: "Have suggestions for improvements?",
  Give_Us_Your_Ideas: "Give us your ideas",
  Terms_And_Conditions: "Terms and conditions",
  Focus: "focus",

  // EXPLORE ALL
  EXPLORE_THE_LIBRARY: "EXPLORE THE LIBRARY",
  Search_By_Theme_Topi_Or_Speaker: "Search by theme, topic or speaker",
  SERIES: "SERIES",
  EPISODES: "EPISODES",
  QUIZZES: "QUIZZES",
  QUIZ: "QUIZ",
  Watch_The_Series: "Watch the series",
  START: "START",
  RETAKE: "RE-TAKE",
  REVIEW: "REVIEW",
  CONTINUE: "CONTINUE",
  CERTIFICATE: "CERTIFICATE",
  passed: "Passed",
  failed: "Failed",
  inProgress: "In progress",
  notStarted: "Not started",
  Discover_More: "Discover more",
  All: "All",
  Loading: "Loading...",

  // CATEGORY
  //Series(duplicated),
  Search_For_Series: "Search for series",
  Latest_Series: "Latest Series",
  All_Series: "All Series",
  View_Details: "View details",
  Watch: "Watch",
  XEpisodes: " Episodes",
  XEpisode: " Episode",
  Ready_For_A_Quiz: "Ready for a quiz to gain more points?",
  Start: "Start",
  //Episodes(duplicated),
  //Search by theme, topic or speaker(duplicated),
  Sort_By: "Sort by",
  Default: "Default",
  Latest: "Latest",
  Earliest: "Earliest",
  Most_Viewed: "Most viewed",
  All_Episodes: "All Episodes",
  //Quizzes(duplicated),
  Search_For_Quizzes: "Search for quizzes",
  Recommended_For_You: "Recommended for you",
  All_Quizzes: "QUIZ  HISTORY",
  //Watch_The_Series(duplicated),
  Re_take: "Re-take",
  //Watch_The_Series(duplicated),
  //Start: '',
  Passed: "Passed",
  Failed: "Failed",
  //Discover more(duplicated),

  // EPISODE PAGE
  //[X] Episodes(duplicated),
  Related_Series: "Related Series",
  Share_Video: "Share video",
  Watch_Later: "Watch later",
  XView: " view",
  XViews: " views",
  //Speakers(duplicated),
  Released_On: "Released on {{date}}",
  Discussion: "Discussion",
  XComments: " comments",
  XComment: " comment",
  Reply: "Reply",
  Enter_Your_Comment_Here: "Enter your comment here",
  Cancel: "Cancel",
  Submit: "Submit",
  Back_To_Top: "Back to top",

  // LEADERBOARD
  Weekly_Leaderboard: "Weekly leaderboard",
  Expert_Viewer: "EXPERT VIEWER ",
  Biggest_Viewed: "Viewed the greatest number of episodes during the past week",
  Fastest_Viewer: "FASTEST VIEWER",
  Woke_Up_Earilest: "Woke-up early to be the 1st ones to watch in your country",
  Star_Contributor: "STAR CONTRIBUTOR",
  Posted_Greatest:
    "Posted a comment and received the greatest amount of love & care",

  // PROFILE PAGE
  EDIT_PROFILE: "EDIT PROFILE",
  SHOW_HISTORY: "SHOW FULL HISTORY",
  MY_QUIZ_HISTORY: "MY QUIZ HISTORY",
  RE_TAKE: "RE-TAKE",
  BADGES: "BADGES",
  //Expert_Viewer(duplicated)
  //Fatest_Viewer(duplicated)
  //Star_Contributor(duplicated)
  //QUIZZES(duplicated)
  PERFORMANCE: "PERFORMANCE",
  //F&B(duplicated)
  //OPS | HR(duplicated)
  //S&M(duplicated)
  Pts: " pts",
  PROGRESS: "PROGRESS",
  MY_ACTIVITY: "MY ACTIVITY",
  MonthAndYear: "month, year",
  VIDEOS: "VIDEOS",
  QUIZZES_TAKEN: "QUIZZES TAKEN",
  QUIZZES_PASSED: "QUIZZES PASSED",
  COMMENTS: "COMMENTS",
  // ???
  Videos_Watched: "Videos watched",
  Quizzes_Taken: "Quizzes taken",
  Comments: "Comments",
  Week_1: "Week 1",
  Week_2: "Week 2",
  Week_3: "Week 3",
  Week_4: "Week 4",
  Week_5: "Week 5",

  // NOTIFICATION
  Notification: "Notification",
  Discover: "Discover",
  Take_The_Quiz: "Take the quiz",

  // MY LISTS
  WATCH_LATER: "WATCH LATER",
  XVideos: " videos",
  XVideo: " video",
  VIEWED: "VIEWED",
  Yesterday: "Yesterday",
  // ???
  Clear: "Clear",
  Watc_Again: "Watch again",
  LIKED: "LIKED",
  //Xvideos(duplicated)
  // OTHER USER PROFILE
  Back: "Back",
  Badges: "Badges",
  //Expert viewer(duplicated)
  //Fatest viewer(duplicated)
  //Star contributor(duplicated)
  //Quizzes(duplicated)
  //F&B(duplicated)
  //OPS | HR(duplicated)
  //S&M(duplicated)
  //Pts(duplicated)
  LIKED_VIDEOS: "LIKED VIDEOS",
  No_Result_For_Your_Search: "No result for your search",

  // QUIZ INTRODUCTION
  Do_You_Remember: "",
  XQuestions: " Questions",
  //START(duplicated)
  NEXT: "NEXT",
  PREVIOUS: "PREVIOUS",

  // QUIZ RESULT
  You_Pass_GIF: "",
  Your_Score_Is: "Your score is...",
  GIF_PASSED: "",
  Retake: "Re-take",
  GIF_FAILED: "",
  XPoints: " Points",
  Xpoints: " points",
  Dont_Worry:
    "Don’t worry. Let’s see what went wrong and understand the question to find the correct answer.",
  Help_Me_Pass: "Help me pass",
  //Retake(duplicated)
  Almost_There: "Almost there !",
  Help_Description: `<1>You already got {{correctCount}} correct answers.</1> <2>Below are the questions that you have missed</2>`,
  Find_The_Correct_Answer:
    "Find the correct answer in <1>{{relatedEpisode}}</1>",
  Learn_From_Series: "Learn from the series",
  Quizz_Welcome: `<p>Do you remember the most important things about {{title}} series?</p> <p>Test your knowledge with this interactive quiz and get a score of 80/100 points or above.</p><p>Don't worry if you fail the first time, you can do it again until you pass.</p>`,
  Xquestions: " questions",
  //Retake(duplicated)

  // Lost

  // Menu
  Explore_All: "Explore all",
  No_Result_Found: "No result found.",
  Take_The_Quizz: "",

  // Home page
  Play_Episode: "Play Episode",
  Start_Quiz: "Start Quiz",
  Visit_Now: "Visit Now",
  Certificates: "Certificates",

  // Explore All
  Filter_By: "Filter by",

  // Video / Episode
  In_Watch_List: "In watchlist",
  Remove_From_Watchlist: "Remove from watchlist",
  Link_Copied: "The video link is copied, go share with your associates!",
  Xmins: "mins",

  // Profile page
  Comments_Posted: "Comments posted",
  January: "January",
  February: "February",
  March: "March",
  April: "April",
  May: "May",
  June: "June",
  July: "July",
  August: "August",
  September: "September",
  October: "October",
  November: "November",
  December: "December",
  Jan: "Jan",
  Feb: "Feb",
  Mar: "Mar",
  Apr: "Apr",
  Jun: "Jun",
  Jul: "Jul",
  Aug: "Aug",
  Sep: "Sep",
  Oct: "Oct",
  Nov: "Nov",
  Dec: "Dec",
  DOWNLOAD_ALL_CERTIFICATES: "DOWNLOAD ALL CERTIFICATES",
  // My List page
  Today: "Today",
  A_Week_Ago: "A week ago",
  A_Month_Ago: "A month ago",

  // Quizz page
  There_Are_More:
    "There are more correct answers to this question. Look again.",

  // 404 page
  Not_Be_Found: "The requested resource cannot be found.",
  Check_URL: "Please check the URL and try again.",

  // Sustainability page
  sustainability: "sustainability",
  sustainability_solgan:
    "FOOD WASTE REDUCTION, PLASTIC ELIMINATION, ENVIRONMENTAL ACTION",
  sustainability_description: `A HEALTHY ENVIRONMENT IS IMPORTANT <br/> FOR ALL PEOPLE TO BE THEIR BEST`,

  //Update
  Hotel_Statistics: "Hotel Statistics",
  Directory: "Directory",
  "Welcome_to_I.D.E.A.S":
    "Welcome to I.D.E.A.S 2024! Boost your skills with bite-sized, 5-minute masterclass videos from over 60 hotelier leaders, released every Monday. Enjoy flexible learning on-the-go, at your pace. Our engaging platform offers interactive quizzes with accredited certifications and insights to enhance your management of Rooms, Restaurants, Bars and Events. Discover effective hotel sales techniques, digital strategies, and communication best practices. Learn innovative ways to sell, plan and operate events, grow as a leader, and nurture your team. Tailored for the leadership committees and teams of all Hyatt Asia Pacific Hotels.",
  HYATT: "HYATT",
  PHOTOGRAPHY: "PHOTOGRAPHY",
  "Dig_Use_&_Share": "Dig, Use & Share",
  Anywhere_Anytime: "Anywhere, Anytime",
  MoodBoard: "MOODBOARD",
  "GLOBAL_HYATT_F&B_PHOTOGRAPHY": "HYATT GLOBAL EVENTS + F&B LIBRARY",
  Leaderboard_Badges: "Leaderboard Badges",
  Show_Full_History: "Show Full History",
  "Average_of_users_have_watched...": "Average of users have watched",
  "...videos": "videos",
  "Average_of_users_have_taken...": "Average of users have taken",
  "...number_of_quizzes": "quizzes",
  "Average_of_users_have_passed...": "Average of users have passed",
  "...quizzes": "quizzes",
  Image_upload: "Image upload",
  Enter_your_comment_here: "Enter your comment here",
  Series_summary: "Series summary",
  Download: "Download",
  Completions: "Completions",
  view: "view",
  views: "views",
  point: "point",
  points: "points",
  Recipe: "Recipe",
  Recipes: "Recipes",
  Search_for_recipes: "Search for recipes",
  "Our_mission_is_to...":
    "Our mission is to curate an extensive collection of captivating imagery, illuminating the culinary narrative within each hotel.\nHere, you will find inspiration and encouragement to craft your own masterpieces, while resonating with the innovative spirit of Hyatt's food and beverage offerings and showcasing your F&B Excellence along the way.",
  "All_of_the_images...":
    "All of the images in our collection are made by Hyatt and copyrighted by Hyatt. They are exclusively available for use by all Hyatt hotels worldwide. To access these high-resolution versions, please kindly log into the Brand Manager Platform or by clicking ",
  here: "HERE",
  "...platform_or_by_clicking": "",
  HYATT_GLOBAL: "HYATT GLOBAL",
  "EVENTS+F&B": "EVENTS + F&B",
  LIBRARY: "LIBRARY",
  No_result_for_your_search: "No result for your search",

  "like.star": "Wow",
  "like.like": "Thumb-up",
  "like.heart": "Love",
  "like.idea": "Insightful",

  Well_done: "Well done",
  quiz_invite:
    "You have finished watching all the episodes for the {{name}}, would you like to do the quiz now to test your knowledge?",

  Taken_on: "Taken on",
  Top_10: "Top 10",
  See_all: "See all",

  FNB_FULL_NAME: "Food & Beverage",
  OPSHR_FULL_NAME: "Operations &\n Human Resources",
  SNM_FULL_NAME: "Sales & Marketing",

  Empty_series_result: "No series found",
  New_tag: "New",
  Other_series_by_this_speaker: "Other series by this speaker",

  REACTED: "Reacted",
  You_Reacted: "You Reacted",
  You_plus_x_others: "You + {{count}} others",

  quiz_description_line1:
    "Test your knowledge with this interactive quiz, and get a score of 80/100 points or above...",
  quiz_description_line2:
    "Don’t worry if you fail the first time, you can do it again until you pass.",

  XSeries: "Series",
  XSerie: "Serie",

  total_points: "Total Points",
  download_all_certificates: "Download All Certificates",

  comment_liked: "new like",
  comment_replied: "new reply",
  award_obtained: "congratulations",
  new_quiz: "New quiz online!",
  new_leaderboard: "leaderboard",

  image_comment_hint: "Image comment should be less than 30MB",

  Edit: "Edit",
  Save: "Save",
  Delete: "Delete",

  Started_at: "Started at",
  React: "React",

  Thank_you_for_your_comment: "Thank you for your comment",
  Your_comment_has_been_added: "Your comment has been added.",
  Back_to_IDEAS: "Back to I.D.E.A.S.",

  Please_confirm: "Please confirm",
  Are_you_sure_you_want_to_delete_your_comment: "Are you sure you want to delete your comment?",

  Comment_removed: "Comment removed",
  Your_comment_has_been_removed: "Your comment has been removed.",

  Thank_you: "Thank you",
  Your_comment_changes_have_been_saved: "Your comment changes have been saved.",

  Our_champions: "Our champions",

  Total_users: "Total users",

  Quiz: "QUIZ",
  Video_views: "VIDEO VIEWS",

  // Profile page
  Edit_your_profile: "Edit your profile",
  Upload_image: "Upload image",
  Upload_image_hint: "(Limit 20mb)",
  Name: "Name",
  Surname: "Surname",
  Country_Region: "Country/Region",
  City: "City",
  Brand: "Brand",
  Hotel: "Hotel",

  Search_a_series: "Search a series",

};

export default english;
