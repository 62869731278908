import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { likeVideo } from "../../../utils/data";
import { useTranslation } from "react-i18next";
import ReactButton from "./ReactButton";

export default function VideoInfo(props) {
  const {
    id,
    views,
    likes,
    userLike,
    userLikeType,
    tags,
    speakers,
    releaseDate,
    description,
    category,
    refreshVideo,
    shortLink1,
    shortLink2,
    anonViews,
    hideTop,
    userWatchlist,
    handleWatchlist,
    isSustainability,
    selectedIndex,
    seriesId,
    seriesTitle,
    likeTypes,
  } = props;

  // we don't want the view count to increase when we refesh another part of the page
  const viewCountRef = useRef(views + anonViews);
  const [showTooltip, setShowTooltip] = useState(false);
  const { t } = useTranslation();

  const [localUserLike, setLocalUserLike] = useState(userLike);
  const [localUserLikeType, setLocalUserLikeType] = useState(userLikeType);
  const [localLikeTypes, setLocalLikeTypes] = useState(likeTypes);
  const [localLikes, setLocalLikes] = useState(likes);

  useEffect(() => {
    if (userLike !== localUserLike) {
      setLocalUserLike(userLike);
    }
    if (userLikeType !== localUserLikeType) {
      setLocalUserLikeType(userLikeType);
    }
    if (JSON.stringify(likeTypes) !== JSON.stringify(localLikeTypes)) {
      setLocalLikeTypes(likeTypes);
    }
    if (likes !== localLikes) {
      setLocalLikes(likes);
    }
  }, [userLike, userLikeType, likeTypes, likes]);

  useEffect(() => {
    if (!viewCountRef.current) {
      viewCountRef.current = views + anonViews;
    }
    return () => {
      viewCountRef.current = null;
    };
  }, [views]);

  const sendLike = (like, type) => {
    setLocalUserLike(like);
    setLocalUserLikeType(type);
    if (like) {
      setLocalLikeTypes({
        ...localLikeTypes,
        [type]: (localLikeTypes?.[type] || 0) + 1,
      });
    } else {
      setLocalLikeTypes({
        ...localLikeTypes,
        [type]:
          (localLikeTypes?.[type] || 0) - 1 >= 0
            ? localLikeTypes?.[type] - 1
            : 0,
      });
    }
    if (like) {
      setLocalLikes(localLikes + 1);
    } else {
      setLocalLikes(localLikes - 1 >= 0 ? localLikes - 1 : 0);
    }
    likeVideo(id, like, type).then(() => {
      refreshVideo(id, null, true);
    });
  };

  const handleShare = () => {
    navigator.clipboard.writeText(window.location.href);
    setShowTooltip(true);
    setTimeout(() => {
      setShowTooltip(false);
    }, 3000);
  };

  return (
    <>
      {!hideTop && (
        <div className="action-infos">
          <div className="right">
            <div className="views">
              <div className="view-buttons">
                <div onClick={handleShare} className="share-video">
                  {t("Share_Video")}
                  <span className={`${showTooltip ? "visible" : ""}`}>
                    {t("Link_Copied")}
                  </span>
                </div>
                <div onClick={handleWatchlist} className="share-video">
                  <i
                    className={
                      userWatchlist
                        ? "icon icon-check"
                        : "icon icon-clock-outline-1"
                    }
                  ></i>
                  {userWatchlist ? t("In_Watch_List") : t("Watch_Later")}
                </div>
              </div>
              <div className="view-reactions">
                <div className="view-count-wrapper">
                  <div className="view-count">{`${viewCountRef.current}${
                    viewCountRef.current > 1 ? t("XViews") : t("XView")
                  }`}</div>
                </div>
                <div className="view-like-wrapper">
                  <div className="like-count">
                    {Boolean(localLikes) && (
                      <div className="like-count-icon-group">
                        {localLikeTypes?.[0] && (
                          <img
                            src="/assets/icons-svg/star-icon.svg"
                            alt="star"
                          />
                        )}
                        {localLikeTypes?.[1] && (
                          <img
                            src="/assets/icons-svg/like-icon.svg"
                            alt="like"
                          />
                        )}
                        {localLikeTypes?.[2] && (
                          <img
                            src="/assets/icons-svg/heart-icon.svg"
                            alt="heart"
                          />
                        )}
                        {localLikeTypes?.[3] && (
                          <img
                            src="/assets/icons-svg/idea-icon.svg"
                            alt="idea"
                          />
                        )}
                      </div>
                    )}
                    {localUserLike
                      ? localLikes >= 2
                        ? t("You_plus_x_others", { count: localLikes - 1 })
                        : t("You_Reacted")
                      : localLikes > 0
                      ? `${localLikes} ${t("REACTED")}`
                      : ""}
                  </div>
                  <div className="view-like-react-wrapper">
                    <ReactButton
                      id={id}
                      userLike={localUserLike}
                      userLikeType={localUserLikeType}
                      likeWithType={(like, type) => sendLike(like, type)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="video-infos">
        <div className="left">
          <div className="video-category">
            <Link
              to={`/category/${category ? category.categorySlug : ""}`}
              style={{
                color: `${category ? category.categoryColor : ""}`,
                textDecoration: "none",
              }}
            >
              <span
                className={`cat ${category ? category.categoryShorthand : ""}`}
              >
                {category ? t(category?.categoryTranslationKey) : ""}
              </span>
            </Link>
            <Link
              target="_blank"
              rel="noopener noreferrer"
              to={`/series/${seriesId}`}
              style={{
                color: "#fff",
                textDecoration: "none",
              }}
            >
              <span className="tagline">{seriesTitle}</span>
            </Link>
          </div>
          <div className="video-title">{`${
            typeof selectedIndex === "number" ? `${selectedIndex + 1} - ` : ""
          }${description}`}</div>
          <div className="video-tags">
            {isSustainability && (
              <Link
                key={t.id}
                to={`/search?q=sustainability`}
                style={{ color: "white", textDecoration: "none" }}
              >
                <span className="sustainability-tag-wrapper tag">
                  <span className="icon-sustainability"></span>
                  <span className="text-sustainability">sustainability</span>
                </span>
              </Link>
            )}
            {tags.map(
              (t) =>
                !t.disabled && (
                  <Link
                    key={t.id}
                    to={`/search?q=${t.name}`}
                    style={{ color: "white", textDecoration: "none" }}
                  >
                    <span className="tag">{t.name}</span>
                  </Link>
                )
            )}
          </div>
          {speakers.length && speakers.filter((s) => s !== null).length > 0 ? (
            <div className="speaker">
              <span>{t("Speaker")}: </span>
              {speakers
                .filter((s) => s !== null)
                .map((s, i) => (
                  <Link key={s.id} to={`/speaker/${s.id}`}>
                    {s.name} {s.surname}
                    {i + 1 >= speakers.length ? "" : ", "}
                  </Link>
                ))}
            </div>
          ) : null}
          {releaseDate && (
            <div className="realease-date">
              {t("Released_On", {
                date: moment(releaseDate).format("MMM DD, YYYY"),
              })}
            </div>
          )}
          {shortLink1 && (
            <a
              className="realease-date discover-style"
              href={shortLink1}
              target="_blank"
              rel="noopener noreferrer"
              style={{ display: "block" }}
            >
              {t("Related Link")}
            </a>
          )}
          {shortLink2 && (
            <a
              className="realease-date discover-style"
              href={shortLink2}
              rel="noopener noreferrer"
              target="_blank"
              style={{ display: "block" }}
            >
              {t("Related Link")}
            </a>
          )}
        </div>
      </div>
    </>
  );
}
