import React from 'react';
import BannerVideo from './bannerVideo';
import get from 'lodash.get';
import tracking from '../../../utils/tracking';
import { useTranslation } from 'react-i18next'

export default function HdeBanner(props) {
  const { image, video, title, desc } = props;
  const { t } = useTranslation();

  return (
    <section className="hde section-block">
      <div className="viewport">
        <div className="inner">
          <div className="left">
            <h2 dangerouslySetInnerHTML={{ __html: `${title}` }} />
            <p>{desc}</p>
            <div className="button-wrapper">
              <a
                href="https://www.hyattdigitalexhibition.com"
                onClick={() =>
                    tracking.event("home", "banner", 'https://www.hyattdigitalexhibition.com')
                }
                target="_blank"
                className="hde-button"
              >
                {t('Visit_Now')}
              </a>
            </div>
          </div>

          <div className="right">
            <div className="img-wrapper">
              {get(image, 'uri', '') &&
                <img
                  src={`${process.env.REACT_APP_IMG_URL}/storage/hde-picture/${get(image, 'uri', '')}`}
                  alt=""
                />
              }
            </div>
            <div className="video-player">
              <div className="video-wrapper">
                <BannerVideo id={video} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
