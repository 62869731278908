import React from "react";
import photo1 from "./compressed/photography-2-1.jpg";
import photo2 from "./compressed/photography-2-2.jpg";
import photo3 from "./compressed/photography-2-3.jpg";
import photo4 from "./compressed/photography-2-4.jpg";
import { useTranslation } from "react-i18next";

const IMAGE_LIST = [
  { tag: "Buffets", src: photo1 },
  { tag: "Dinner", src: photo2 },
  { tag: "Buffets", src: photo3 },
  { tag: "Afternoon tea", src: photo4 },
];

const HyattPhotography = () => {
  const { t } = useTranslation();

  return (
    <section className="hyattfbphoto section-block">
      <div className="viewport">
        <div
          onClick={() => {
            window.location.href = "/category/fb?filter=mood";
          }}
          className="inner pointer"
        >
          <div className="window-wrapper">
            <div className="info-wrapper">
              <div className="title-wrapper">
                <span className="title">{t("HYATT_GLOBAL")}</span>
                <div>
                  <span className="title">{t("EVENTS+F&B")}</span>
                  <span className="title orange">{` ${t("LIBRARY")}`}</span>
                </div>
              </div>
            </div>

            <div className="items-wrapper">
              {IMAGE_LIST.map((e, i) => (
                <div key={i} className="item" style={{ position: "relative" }}>
                  <img
                    src={e.src}
                    alt={e.tag}
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      height: "100%",
                      width: "100%",
                      objectFit: "cover",
                      objectPosition: "center",
                      borderRadius: 16,
                    }}
                  />
                  {/* <div className='tag-wrapper' style={{ position: 'absolute' }}>
                                        <span className='tag-text'>
                                            {e.tag}
                                        </span>
                                    </div> */}
                </div>
              ))}
              <div className="layer-mobile" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HyattPhotography;
