import http from "../utils/axios";

export const getVideoById = (id) => {
  const token = localStorage.getItem("token");
  try {
    return http
      .get(`/videos/${id}/info`, {
        headers: {
          Authorization: `JWT ${token}`,
        },
      })
      .then((res) => {
        // delete res?.data?.userLike;
        // delete res?.data?.userLikeType;
        return res;
      });
  } catch (err) {
    console.error(err);
  }
};

export const getQuizById = (id) => {
  const token = localStorage.getItem("token");
  try {
    return http.get(`/quizzes/${id}/answer`, {
      headers: {
        Authorization: `JWT ${token}`,
      },
    });
  } catch (err) {
    console.error(err);
  }
};

export const downloadCertificate = (id) => {
  const token = localStorage.getItem("token");
  try {
    return http
      .get(`/certifications/${id}/download`, {
        responseType: "arraybuffer",
        headers: {
          Authorization: `JWT ${token}`,
        },
      })
      .then(async (response) => {
        const { data, headers } = response;
        if (data) {
          const contentDisposition = headers?.["content-disposition"];
          let fileName = "certification.pdf"; // 默认文件名
          if (contentDisposition) {
            const matches = contentDisposition.match(/filename="?([^"]+)"?/);
            if (matches && matches[1]) {
              fileName = matches[1];
            }
          }
          const blob = new Blob([data], { type: "application/pdf" });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.setAttribute("target", "_blank");
          link.setAttribute("download", fileName);
          link.click();
        }
      });
  } catch (err) {
    console.error(err);
  }
};

export const downloadAllCertificates = () => {
  const token = localStorage.getItem("token");
  try {
    return http
      .get("/certifications/downloadAll", {
        responseType: "arraybuffer",
        headers: {
          Authorization: `JWT ${token}`,
        },
      })
      .then((res) => {
        console.log(res?.data);
        return res?.data;
      })
      .then((data) => {
        const blob = new Blob([data], { type: "application/zip" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.setAttribute("download", "certifications.zip");
        link.click();
      });
  } catch (err) {
    console.error(err);
  }
};

export const getMoodBoardTags = () => {
  const token = localStorage.getItem("token");
  try {
    return http.get("/tag-photographies", {
      headers: {
        Authorization: `JWT ${token}`,
      },
      params: {
        disabled: false,
      },
    });
  } catch (err) {
    console.error(err);
  }
};

export const getSeriesTags = () => {
  const token = localStorage.getItem("token");
  try {
    return http.get("/tag-series", {
      headers: {
        Authorization: `JWT ${token}`,
      },
      params: {
        disabled: false,
      },
    });
  } catch (err) {
    console.error(err);
  }
};

export const getMoodBoard = (page, tags, randomSeed) => {
  const token = localStorage.getItem("token");
  const params = {
    range: `[${page === 0 ? 0 : page * 15 + 1},${page * 15 + 15}]`,
    filter: !!tags && !!tags.length ? { tag: { $in: tags } } : undefined,
  };
  if (randomSeed) {
    params.randomKey = randomSeed;
  }
  try {
    return http.get("/photographies/detail", {
      headers: {
        Authorization: `JWT ${token}`,
      },
      params,
    });
  } catch (err) {
    console.error(err);
  }
};

export const getQuizzes = (params) => {
  const token = localStorage.getItem("token");
  try {
    return http.get(`/quizzes`, {
      headers: {
        Authorization: `JWT ${token}`,
      },
      params: {
        ...params,
      },
    });
  } catch (err) {
    console.error(err);
  }
};

export const searchQuizzes = (params) => {
  const token = localStorage.getItem("token");
  try {
    return http.get(`/quizzes/search`, {
      headers: {
        Authorization: `JWT ${token}`,
      },
      params: {
        ...params,
        // disabled: false
      },
    });
  } catch (err) {
    console.error(err);
  }
};

export const getSubmittedQuizzes = (params) => {
  const token = localStorage.getItem("token");
  try {
    return http.get(`/quiz-results/mine`, {
      headers: {
        Authorization: `JWT ${token}`,
      },
      params: {
        ...params,
      },
    });
  } catch (err) {
    console.error(err);
  }
};

export const postQuizOneAnswer = (data) => {
  const token = localStorage.getItem("token");
  try {
    return http.post(`/submissions`, data, {
      headers: {
        Authorization: `JWT ${token}`,
      },
    });
  } catch (err) {
    console.error(err);
  }
};

export const getAllQuizzesResults = (params) => {
  const token = localStorage.getItem("token");
  try {
    return http.get(`/quiz-results`, {
      headers: {
        Authorization: `JWT ${token}`,
      },
      params: {
        ...params,
      },
    });
  } catch (err) {
    console.error(err);
  }
};

export const postQuizAnswer = (id, answer) => {
  const token = localStorage.getItem("token");
  try {
    return http.post(
      `/quizzes/${id}/answer`,
      {
        questions: answer,
      },
      {
        headers: {
          Authorization: `JWT ${token}`,
        },
      }
    );
  } catch (err) {
    console.error(err);
  }
};

export const postQuizAnswerAutoSave = (id, answer) => {
  const token = localStorage.getItem("token");
  try {
    return http.post(
      `/quizzes/${id}/answer?autoSave=true`,
      {
        questions: answer,
      },
      {
        headers: {
          Authorization: `JWT ${token}`,
        },
      }
    );
  } catch (err) {
    console.error(err);
  }
};

export const sendCertification = () => {
  const token = localStorage.getItem("token");
  try {
    return http.get(`/quizzes/sendCertification`, {
      headers: {
        Authorization: `JWT ${token}`,
      },
    });
  } catch (err) {
    console.error(err);
  }
};

export const postComment = (id, message, parent, image) => {
  const token = localStorage.getItem("token");

  try {
    return http.post(
      `/videos/${id}/comment`,
      {
        parent: parent || null,
        message,
        commentPicture: image,
      },
      {
        headers: {
          Authorization: `JWT ${token}`,
        },
      }
    );
  } catch (err) {
    console.error(err);
  }
};

export const postImageComment = (image) => {
  const token = localStorage.getItem("token");

  const fb = new FormData();
  fb.append("file", image);

  try {
    return http.post("/files/comment-image", fb, {
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "multipart/form-data",
      },
    });
  } catch (err) {
    console.error(err);
  }
};

export const likeVideo = (id, bool, type) => {
  const token = localStorage.getItem("token");
  try {
    return http.post(
      `/videos/${id}/like`,
      {
        like: bool,
        type,
      },
      {
        headers: {
          Authorization: `JWT ${token}`,
        },
      }
    );
  } catch (err) {
    console.error(err);
  }
};

export const likeComment = (id, bool) => {
  const token = localStorage.getItem("token");
  try {
    return http.post(
      `/comments/${id}/like`,
      {
        like: bool,
      },
      {
        headers: {
          Authorization: `JWT ${token}`,
        },
      }
    );
  } catch (err) {
    console.error(err);
  }
};

export const getCommentLikes = (id) => {
  const token = localStorage.getItem("token");
  try {
    return http.get(`/comments/${id}/like`, {
      headers: {
        Authorization: `JWT ${token}`,
      },
    });
  } catch (err) {
    console.error(err);
  }
};

export const editComment = (id, message, image) => {
  const token = localStorage.getItem("token");
  try {
    return http.put(
      `/comments/${id}/update`,
      {
        message,
        commentPicture: image,
      },
      {
        headers: {
          Authorization: `JWT ${token}`,
        },
      }
    );
  } catch (err) {
    console.error(err);
  }
};

export const deleteComment = (id) => {
  const token = localStorage.getItem("token");
  try {
    return http.delete(`/comments/${id}/delete`, {
      headers: {
        Authorization: `JWT ${token}`,
      },
    });
  } catch (err) {
    console.error(err);
  }
};

// HOME

export const getHomePage = (id) => {
  const token = localStorage.getItem("token");
  try {
    return http.get(`/homepages/detail`, {
      headers: {
        Authorization: `JWT ${token}`,
      },
    });
  } catch (err) {
    console.error(err);
  }
};

export const getRecommendedQuizzes = (params) => {
  const token = localStorage.getItem("token");
  try {
    return http.get(`/quizzes/recommended`, {
      headers: {
        Authorization: `JWT ${token}`,
      },
      params: {
        ...params,
      },
    });
  } catch (err) {
    console.error(err);
  }
};

export const getRecommendedVideos = () => {
  const token = localStorage.getItem("token");
  try {
    return http.get(`/videos/recommended`, {
      headers: {
        Authorization: `JWT ${token}`,
      },
    });
  } catch (err) {
    console.error(err);
  }
};

export const getLeaderboard = () => {
  const token = localStorage.getItem("token");
  try {
    return http.get(`/leaderboards/latest`, {
      headers: {
        Authorization: `JWT ${token}`,
      },
    });
  } catch (err) {
    console.error(err);
  }
};

export const sendVideoView = (id) => {
  const token = localStorage.getItem("token");
  try {
    return http.post(
      `/videos/${id}/view`,
      {},
      {
        headers: {
          Authorization: `JWT ${token}`,
        },
      }
    );
  } catch (err) {
    console.error(err);
  }
};

export const sendVideoHalfViewed = (id) => {
  const token = localStorage.getItem("token");
  try {
    return http.post(
      `/videos/${id}/complete`,
      {},
      {
        headers: {
          Authorization: `JWT ${token}`,
        },
      }
    );
  } catch (err) {
    console.error(err);
  }
};

export const getTags = () => {
  const token = localStorage.getItem("token");
  try {
    return http.get(`/tags`, {
      headers: {
        Authorization: `JWT ${token}`,
      },
    });
  } catch (err) {
    console.error(err);
  }
};

export const getCategories = () => {
  const token = localStorage.getItem("token");
  try {
    return http.get(`/categories`, {
      headers: {
        Authorization: `JWT ${token}`,
      },
    });
  } catch (err) {
    console.error(err);
  }
};

// VIDEOS
export const searchVideos = (params, speakers) => {
  const token = localStorage.getItem("token");
  try {
    return http.get(`/videos/search${speakers ? "/speakers" : ""}`, {
      headers: {
        Authorization: `JWT ${token}`,
      },
      params: {
        ...params,
        disabled: false,
      },
    });
  } catch (err) {
    console.error(err);
  }
};

export const getVideosBySeries = (id) => {
  const token = localStorage.getItem("token");
  try {
    return http.get(`/series/${id}/detail`, {
      headers: {
        Authorization: `JWT ${token}`,
      },
    });
  } catch (err) {
    console.error(err);
  }
};

// SERIES
export const getSeries = (params) => {
  const token = localStorage.getItem("token");
  try {
    return http.get(`/series`, {
      headers: {
        Authorization: `JWT ${token}`,
      },
      params: {
        ...params,
        disabled: false,
      },
    });
  } catch (err) {
    console.error(err);
  }
};

export const searchSeries = (params) => {
  const token = localStorage.getItem("token");
  try {
    return http.get(`/series/search`, {
      headers: {
        Authorization: `JWT ${token}`,
      },
      params: {
        ...params,
        disabled: false,
      },
    });
  } catch (err) {
    console.error(err);
  }
};

export const getSeriesById = (id) => {
  const token = localStorage.getItem("token");
  try {
    return http.get(`/series/${id}/detail`, {
      headers: {
        Authorization: `JWT ${token}`,
      },
      params: {
        disabled: false,
      },
    });
  } catch (err) {
    console.error(err);
  }
};

export const getSeriesByCategory = (categoryName, id) => {
  const token = localStorage.getItem("token");
  try {
    return http.get(`/series/detail`, {
      headers: {
        Authorization: `JWT ${token}`,
      },
      params: {
        [categoryName]: id,
        disabled: false,
      },
    });
  } catch (err) {
    console.error(err);
  }
};

export const getRelatedSeries = (id) => {
  const token = localStorage.getItem("token");
  try {
    return http.get(`/videos/${id}/recommended-series`, {
      headers: {
        Authorization: `JWT ${token}`,
      },
    });
  } catch (err) {
    console.log(err);
  }
};

export const getSeriesBySpeaker = (speakers) => {
  const token = localStorage.getItem("token");
  try {
    return http.get(`/series/detail`, {
      headers: {
        Authorization: `JWT ${token}`,
      },
      params: {
        speakers,
        disabled: false,
      },
    });
  } catch (err) {
    console.error(err);
  }
};

// SPEAKER
export const getSpeakers = (params) => {
  const token = localStorage.getItem("token");
  try {
    return http.get(`/speakers/details`, {
      headers: {
        Authorization: `JWT ${token}`,
      },
      params: {
        ...params,
      },
    });
  } catch (err) {
    console.error(err);
  }
};

export const getSpeaker = (id) => {
  const token = localStorage.getItem("token");
  try {
    return http.get(`/speakers/${id}/detail`, {
      headers: {
        Authorization: `JWT ${token}`,
      },
    });
  } catch (err) {
    console.error(err);
  }
};

// FORM SUBMITS

export const sendSuggestion = (message) => {
  const token = localStorage.getItem("token");
  try {
    return http.post(
      `/suggestions`,
      {
        message,
      },
      {
        headers: {
          Authorization: `JWT ${token}`,
        },
      }
    );
  } catch (err) {
    console.error(err);
  }
};

// PROFILE

export const addToWatchlist = (id, bool) => {
  const token = localStorage.getItem("token");
  try {
    return http.post(
      `/videos/${id}/watchlist`,
      {
        watchlist: bool,
      },
      {
        headers: {
          Authorization: `JWT ${token}`,
        },
      }
    );
  } catch (err) {
    console.error(err);
  }
};

export const getWatchlistVideo = (token) => {
  if (!token) token = localStorage.getItem("token");
  try {
    return http.get(`/users/video/watchlisted`, {
      headers: {
        Authorization: `JWT ${token}`,
      },
    });
  } catch (err) {
    console.error(err);
  }
};

export const getProfile = (token) => {
  if (!token) token = localStorage.getItem("token");
  try {
    return http.get(`/users/info`, {
      headers: {
        Authorization: `JWT ${token}`,
      },
    });
  } catch (err) {
    console.error(err);
  }
};

export const updateUserLanguage = (info, lang) => {
  const token = localStorage.getItem("token");
  try {
    return http.post(
      `/users/${info.id}/language`,
      {
        preferredLanguage: lang,
      },
      {
        headers: {
          Authorization: `JWT ${token}`,
        },
      }
    );
  } catch (err) {
    console.error(err);
  }
};

export const getLikedVideo = (token) => {
  if (!token) token = localStorage.getItem("token");
  try {
    return http.get(`/users/video/liked`, {
      headers: {
        Authorization: `JWT ${token}`,
      },
    });
  } catch (err) {
    console.error(err);
  }
};

//
export const getUserProfile = (id, token) => {
  if (!token) token = localStorage.getItem("token");
  try {
    return http.get(`/users/${id}/info`, {
      headers: {
        Authorization: `JWT ${token}`,
      },
    });
  } catch (err) {
    console.error(err);
  }
};

export const getUserStatistic = (id, params, token) => {
  if (!token) token = localStorage.getItem("token");
  try {
    return http.get(`/users/${id}/stats`, {
      headers: {
        Authorization: `JWT ${token}`,
      },
      params: {
        ...params,
      },
    });
  } catch (err) {
    console.error(err);
  }
};

export const getHotelStatistic = (params, token) => {
  if (!token) token = localStorage.getItem("token");
  try {
    return http.get("/hotels/mine/stats", {
      headers: {
        Authorization: `JWT ${token}`,
      },
      params: {
        ...params,
      },
    });
  } catch (err) {
    console.error(err);
  }
};

export const getStatisticCSV = (start, end, hotelId, series) => {
  const token = localStorage.getItem("token");
  try {
    return http
      .post(
        `/hotels/export`,
        {
          dateRange: {
            start,
            end,
          },
          currentHotel: hotelId,
          pageData: { series },
        },
        {
          headers: {
            Authorization: `JWT ${token}`,
          },
        }
      )
      .then((response) => response.data)
      .then((data) => {
        console.log("blob", data);
        var blob = new Blob([data], { type: "text/csv;charset=utf-8;" });
        if (navigator.msSaveBlob) {
          // IE 10+
          navigator.msSaveBlob(blob, `hotel_statistics.csv`);
        } else {
          var link = document.createElement("a");
          if (link.download !== undefined) {
            // feature detection
            // Browsers that support HTML5 download attribute
            var url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", `hotel_statistics.csv`);
            link.style.visibility = "hidden";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        }
      });
  } catch (err) {
    console.error(err);
  }
};

export const getUserLikedVideo = (id, token) => {
  if (!token) token = localStorage.getItem("token");
  try {
    return http.get(`/users/${id}/video/liked`, {
      headers: {
        Authorization: `JWT ${token}`,
      },
    });
  } catch (err) {
    console.error(err);
  }
};
//

export const register = (data) => {
  const token = localStorage.getItem("token");
  try {
    return http.post(
      `/users/info`,
      {
        name: data.name,
        surname: data.surname,
        country: data.country,
        city: data.city,
        brand: data.brand,
        hotel: data.hotel,
      },
      {
        headers: {
          Authorization: `JWT ${token}`,
        },
      }
    );
  } catch (err) {
    console.error(err);
  }
};

export const getCountries = () => {
  const token = localStorage.getItem("token");
  try {
    return http.get(`/countries`, {
      headers: {
        Authorization: `JWT ${token}`,
      },
    });
  } catch (err) {
    console.error(err);
  }
};

export const getCities = (country) => {
  const token = localStorage.getItem("token");
  try {
    return http.get(`/cities?country=${country}`, {
      headers: {
        Authorization: `JWT ${token}`,
      },
    });
  } catch (err) {
    console.error(err);
  }
};

export const getBrands = () => {
  const token = localStorage.getItem("token");
  try {
    return http.get(`/brands`, {
      headers: {
        Authorization: `JWT ${token}`,
      },
    });
  } catch (err) {
    console.error(err);
  }
};

export const getHotels = (city, brand) => {
  const token = localStorage.getItem("token");
  try {
    return http.get(`/hotels?city=${city}&brand=${brand}`, {
      headers: {
        Authorization: `JWT ${token}`,
      },
    });
  } catch (err) {
    console.error(err);
  }
};

export const uploadPhoto = (fd) => {
  const token = localStorage.getItem("token");
  try {
    return http.post(`/users/picture`, fd, {
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "multipart/form-data",
      },
    });
  } catch (err) {
    console.error(err);
  }
};

export const getAnnouncement = () => {
  const token = localStorage.getItem("token");
  try {
    return http.get(`/announcements/current`, {
      headers: {
        Authorization: `JWT ${token}`,
      },
    });
  } catch (err) {
    console.error(err);
  }
};

export const seeAnnouncement = () => {
  const token = localStorage.getItem("token");
  try {
    return http.post(
      `/announcements/seen`,
      {},
      {
        headers: {
          Authorization: `JWT ${token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
  } catch (err) {
    console.error(err);
  }
};

export const requestResetPassword = (email) => {
  return http.post(`auth/password`, { email }, {});
};

export const setNewPassword = async (password, token) => {
  try {
    return await http.post(`auth/password/${token}`, { password });
  } catch (e) {
    console.error(e);
  }
};

export const getMyNotifications = () => {
  const token = localStorage.getItem("token");
  try {
    return http.get("/notifications/mine", {
      headers: {
        Authorization: `JWT ${token}`,
      },
    });
  } catch (err) {
    console.error(err);
  }
};

export const readMyNotifications = (id) => {
  const token = localStorage.getItem("token");
  try {
    return http.post(
      `/notifications/${id}/mine`,
      {},
      {
        headers: {
          Authorization: `JWT ${token}`,
        },
      }
    );
  } catch (err) {
    console.error(err);
  }
};

export const getViewedVideo = (params) => {
  const token = localStorage.getItem("token");
  try {
    return http.get(`/video-views/mine`, {
      headers: {
        Authorization: `JWT ${token}`,
      },
      params: params,
    });
  } catch (err) {
    console.error(err);
  }
};

export const clearViewedVideo = (filters) => {
  const token = localStorage.getItem("token");
  try {
    return http.post(
      `/video-views/hide`,
      {
        dateStart: filters.dateStart,
        dateEnd: filters.dateEnd,
      },
      {
        headers: {
          Authorization: `JWT ${token}`,
        },
      }
    );
  } catch (err) {
    console.error(err);
  }
};

export const getRecipes = (params) => {
  const token = localStorage.getItem("token");
  try {
    return http.get(`/recipes/search`, {
      headers: {
        Authorization: `JWT ${token}`,
      },
      params: {
        ...params,
        disabled: false,
      },
    });
  } catch (err) {
    console.error(err);
  }
};

export const getRecipeCategories = () => {
  const token = localStorage.getItem("token");
  try {
    return http.get(`/recipe-categories`, {
      headers: {
        Authorization: `JWT ${token}`,
      },
      params: {
        disabled: false,
      },
    });
  } catch (err) {
    console.error(err);
  }
};

export const sendRecipeView = (id) => {
  const token = localStorage.getItem("token");
  try {
    return http.post(
      `/recipes/${id}/view`,
      {},
      {
        headers: {
          Authorization: `JWT ${token}`,
        },
      }
    );
  } catch (err) {
    console.error(err);
  }
};

export const sendRecipeHalfViewed = (id) => {
  const token = localStorage.getItem("token");
  try {
    return http.post(
      `/recipes/${id}/complete`,
      {},
      {
        headers: {
          Authorization: `JWT ${token}`,
        },
      }
    );
  } catch (err) {
    console.error(err);
  }
};
