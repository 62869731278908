import React, { useState, useEffect, useRef } from "react";

const languages = {
  english: "English",
  japanese: "日本語",
  mandarin: "中文",
  thai: "ไทย",
  korean: "한국어",
};

const NavLanguageSwitcher = ({
  currentLang,
  handleSwitchLang,
  isMobile,
  parentModalClick,
}) => {
  const ref = useRef();
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    setShowModal((prev) => {
      if (prev) {
        if (!ref.current.contains(event.target)) {
          handleClick(event);
        }
      }

      return prev;
    });
  };

  const handleClick = (e) => {
    setShowModal((prev) => {
      if (prev) {
        document
          .getElementById("languages-content" + (isMobile ? "-mobile" : ""))
          .classList.remove("tab-opened");
        document
          .getElementById("languages-tab" + (isMobile ? "-mobile" : ""))
          .classList.remove("active");
      } else {
        document
          .getElementById("languages-content" + (isMobile ? "-mobile" : ""))
          .classList.add("tab-opened");
        document
          .getElementById("languages-tab" + (isMobile ? "-mobile" : ""))
          .classList.add("active");
        return true;
      }
      return false;
    });
  };

  return (
    <div
      id={"languages-tab" + (isMobile ? "-mobile" : "")}
      className={
        "tab-select-wrapper languages-select burger " +
        (isMobile ? "" : "hidden-md ")
      }
    >
      <div className="tab-name language-tab" onClick={handleClick}>
        {languages?.[currentLang]}
        <span className="icon-chevronright2"></span>
      </div>

      <div
        id={"languages-content" + (isMobile ? "-mobile" : "")}
        className="tab-select"
        ref={ref}
      >
        {currentLang != "english" && (
          <div
            className="tab"
            onClick={() => {
              if (isMobile) {
                parentModalClick();
              }
              setShowModal(false);
              handleSwitchLang("english");
            }}
          >
            <span>{languages?.["english"]}</span>
          </div>
        )}
        {currentLang != "japanese" && (
          <div
            className="tab"
            onClick={() => {
              if (isMobile) {
                parentModalClick();
              }
              setShowModal(false);
              handleSwitchLang("japanese");
            }}
          >
            <span>{languages?.["japanese"]}</span>
          </div>
        )}
        {currentLang != "mandarin" && (
          <div
            className="tab"
            onClick={() => {
              if (isMobile) {
                parentModalClick();
              }
              setShowModal(false);
              handleSwitchLang("mandarin");
            }}
          >
            <span>{languages?.["mandarin"]}</span>
          </div>
        )}
        {currentLang != "thai" && (
          <div
            className="tab"
            onClick={() => {
              if (isMobile) {
                parentModalClick();
              }
              setShowModal(false);
              handleSwitchLang("thai");
            }}
          >
            <span>{languages?.["thai"]}</span>
          </div>
        )}
        {currentLang != "korean" && (
          <div
            className="tab"
            onClick={() => {
              if (isMobile) {
                parentModalClick();
              }
              setShowModal(false);
              handleSwitchLang("korean");
            }}
          >
            <span>{languages?.["korean"]}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default NavLanguageSwitcher;
