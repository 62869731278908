import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import SearchResults from './searchResults';
import SearchBar from './searchBar';
import queryString from 'query-string';
import SeriesSlider from '../../ui/seriesSlider';
import useSearch from '../../../store/hooks/useSearch';
import { getCategories } from "../../../utils/data";
import { parseCategoryID } from "../../../utils";
import { useTranslation } from 'react-i18next'

import "./index.scss";

const Search = (props) => {
  const [searchValue, setSearchValue] = useState(queryString.parse(window.location.search).q || '');

  const [searchQ, setSearchQ] = useState(queryString.parse(window.location.search).q || '');
  const [searchTags, setSearchTags] = useState(queryString.parse(window.location.search).tags || '');
  const [searchFilter, setSearchFilter] = useState(queryString.parse(window.location.search).filter || '');
  const [searchCategory, setSearchCategory] = useState(queryString.parse(window.location.search).category || null);
  const [filterCategory, setFilterCategory] = useState(parseCategoryID(queryString.parse(window.location.search).category).categoryShorthand || null);

  const [searchSort, setSearchSort] = useState(queryString.parse(window.location.search).sort || '');
  const [sortKey, setSortKey] = useState(null);

  const { t } = useTranslation();

  const {
    // tagList,
    episodes,
    series,
    quizzes,
  } = useSearch(
    searchQ,
    searchTags,
    searchFilter,
    searchCategory,
    true,
    searchFilter === 'speaker',
    searchSort,
    false, // isSustainability = false
    false
  );

  useEffect(() => {
    let sort = queryString.parse(window.location.search).sort;
    if (sort == '["releaseDate","DESC"]' || sort == '["createdAt","DESC"]' || sort == '') {
      setSortKey('latest');
    } else if (sort == '["releaseDate","ASC"]' || sort == '["createdAt","ASC"]') {
      setSortKey('earliest');
    } else if (sort == '["morningViewCount","DESC"]') {
      setSortKey('mostViewed');
    }
  })

  const handleFilter = (q, tags, filter, category = null, sort = '') => {
    setSearchQ(q);
    setSearchTags(tags);
    setSearchFilter(filter);
    setSearchCategory(category);
    setSearchSort(sort);

    if (sort == '["releaseDate","DESC"]' || sort == '["createdAt","DESC"]' || sort == '') {
      setSortKey('latest');
    } else if (sort == '["releaseDate","ASC"]' || sort == '["createdAt","ASC"]') {
      setSortKey('earliest');
    } else if (sort == '["morningViewCount","DESC"]') {
      setSortKey('mostViewed');
    }
  }

  const handleCategory = async (id) => {
    const categories = await getCategories();
    const catId = categories.data.filter(
      (c) => c.id === id
    );
    if (catId.length === 0) {
      props.history.replace('/notfound');
      return;
    }

    setFilterCategory(parseCategoryID(catId[0].id).categoryShorthand);
    handleFilter(searchQ, searchTags, searchFilter, id);
  }

  return (
    <div style={{ flex: 1 }}>
      <div id="content">
        <div className="back-button" onClick={() => props.history.goBack()}>
          <span className="icon-arrowup2"></span> {t('Back')}
        </div>
        <SearchBar
          searchValue={searchValue}
          onSearchChange={(e) => setSearchValue(e)}
          searchQ={searchQ}
          onQChange={(e) => handleFilter(e, searchTags, searchFilter)}
          searchTags={searchTags}
          onTagChange={(e) => handleFilter(searchQ, e, searchFilter)}
          searchFilter={searchFilter}
          onFilterChange={(e) => {
            setFilterCategory(null);
            setSortKey(null);
            handleFilter(searchValue, '', e)
          }}
          filterCategory={filterCategory}
          onCategoryChange={(e) => handleCategory(e.value)}
          sortKey={sortKey}
          onSortChange={(e) => handleFilter(searchQ, searchTags, searchFilter, filterCategory, e.value)}
          isCategory={false}
          color="#2672EC"
          hideEpisodesTag={true}
          resultLength={{
            quizzes: quizzes.total,
            episodes: episodes.total,
            series: series.total,
          }}
        />

          {(quizzes.isLoading && !quizzes.list.length)
          || (episodes.isLoading && !episodes.list.length)
          || (series.isLoading && !series.list.length) || !searchQ ? null : (
            <>
              {(searchFilter === 'quiz') && (quizzes.list.length !== 0) ? (
                <>
                  <SearchResults.Quiz
                    videos={quizzes.list}
                    limit={quizzes.list.length}
                    t={t}
                  />
                  {!quizzes.endReach ? (
                    <div className="load-more">
                      <button className="tertiary small" onClick={quizzes.fetchMore}>
                        <span>
                          {quizzes.isLoading ? t('Loading') : t('Discover_More')}
                        </span>
                      </button>
                    </div>
                  ) : null}              
                </>
              ) : (
                null
              )}

              {(searchFilter === 'episodes' ||
              searchFilter === 'speaker') && (episodes.list.length !== 0) ? (
                <>
                  <SearchResults
                    videos={episodes.list}
                    limit={episodes.list.length}
                    isInSearch={true}
                  />
                  {!episodes.endReach ? (
                    <div className="load-more">
                      <button className="tertiary small" onClick={episodes.fetchMore}>
                        <span>
                          {episodes.isLoading ? t('Loading') : t('Discover_More')}
                        </span>
                      </button>
                    </div>
                  ) : null}              
                </>
              ) : (
                null
              )}

              {(searchFilter === '' || searchFilter === 'series') && (series.list.length !== 0) ? (
                <section className="series in-search-page">
                  <div className="viewport">
                    <div className="inner">
                      {/*<h2 className="title">Series</h2>*/}
                      <SeriesSlider.List series={series.list} t={t} />
                      {!series.endReach ? (
                        <div className="load-more">
                          <button className="tertiary small" onClick={series.fetchMore}>
                            <span>
                              {series.isLoading ? t('Loading') : t('Discover_More')}
                            </span>
                          </button>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </section>
              ) : (
                null
              )}

              {quizzes.list.length === 0 && !quizzes.isLoading
              && episodes.list.length === 0 && !episodes.isLoading
              && series.list.length === 0 && !series.isLoading ? (
                <div className="empty">
                  <h2 className="title">
                    {t('No_Result_For_Your_Search')}
                  </h2>
                </div>
              ) : null}
            </>
          )}
      </div>
    </div>
  );
};

export default withRouter(Search);
