import React from 'react';
import VideoItem from '../../ui/VideoItem';
import { filterUnreleased } from '../../../utils';

export default function videoList(props) {
  const { videos, limit, onVideoClicked, onWatchlistDelete, hideWatchlist, self = false } = props;

  return (
    <div className="video-list">
      {videos.filter(filterUnreleased).map((v, i) => {
        v.totalViews = (v.anonymousViewCount || 0) + (v.views || 0);
        return i < limit ?
          <VideoItem
            key={v.id}
            video={v}
            self={self}
            hideWatchlist={hideWatchlist}
            onVideoClicked={onVideoClicked}
            onWatchlistDelete={onWatchlistDelete}
          /> : null;
      })}
    </div>
  );
}
